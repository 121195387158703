import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from '../../router/ProtectedRoute';
import {routes} from '../../routes';
import EventBulkClosing from './pages/EventBulkClosing';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyEvent = lazy(async () => await import('./pages/Event'));

export const eventRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.event.bulkClosing)},
  {
    path: routes.getRelativePath(routes.event.bulkClosing, routes.event.base),
    element: (
      <ProtectedRoute permissions={['criticalEvent BulkAction:edit']}>
        <EventBulkClosing />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Event bulk closing';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event bulk closing'}],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.event.eventId, routes.event.base),
    element: (
      <ProtectedRoute
        permissions={['criticalEvent:list', 'operationalEvent:list']}
      >
        <LazyEvent />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'View Event';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'View Event'}],
      });
      return null;
    },
  },
];
