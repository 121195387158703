import {object, type InferType, string} from 'yup';

import {listing} from '../../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../../common/components/form/VantageTextField';
import {useAddPurchaseOrder} from '../../hooks/useAddPurchaseOrder';

const purchaseOrderAddDetailsValidationSchema = object({
  contract: listing,
  customer: listing,
  purchaseOrderNumber: string().required('Required'),
  referenceNumber: string(),
});
export type PurchaseOrderAddDetailsValues = InferType<
  typeof purchaseOrderAddDetailsValidationSchema
>;

export function PurchaseOrderAddDetails() {
  const {onSubmit} = useAddPurchaseOrder();

  return (
    <VantageForm<PurchaseOrderAddDetailsValues>
      permission="trip:add"
      initialValues={{
        customer: {value: 0},
        contract: {value: 0},
        purchaseOrderNumber: '',
        referenceNumber: '',
      }}
      onSubmit={onSubmit}
      validationSchema={purchaseOrderAddDetailsValidationSchema}
    >
      <VantageFormContent>
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            name="contract"
            models="Contract"
            label="Contract"
            placeholder="Search and select the contract"
            formik
          />
          <VantageAutocompleteFieldWithListing
            name="customer"
            models="Customer"
            label="Customer"
            placeholder="Enter and select the trip contract"
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageTextField
            name="purchaseOrderNumber"
            label="Purchase Order Number"
            formik
          />
          <VantageTextField
            name="referenceNumber"
            label="Reference Number"
            formik
          />
        </VantageFormFieldsRow>
      </VantageFormContent>
      <VantageFormActions>
        <VantageFormSubmit />
      </VantageFormActions>
    </VantageForm>
  );
}
