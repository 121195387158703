import {
  type ContractUser,
  type CustomerUser,
  type PermissionAuth,
  type RoleAuth,
  type Smartphone,
  type User,
  type UserDriverDump,
  type UserMe,
} from '@onroadvantage/onroadvantage-api';
import {create} from 'zustand';

import {type Permission, permissions} from '../../../permissions';

interface UserStoreState {
  contracts: ContractUser[];
  customers: CustomerUser[];
  driver: UserDriverDump | null;
  email: string | null;
  id: number;
  permissions: PermissionAuth[];
  roles: RoleAuth[];
  smartPhone: Smartphone | null;
}

interface UserStoreActions {
  reset: () => void;
  setUser: (user: Partial<UserMe>) => void;
  updateUserDetails: (user: User) => void;
  isSuperAdmin: () => boolean;
  hasRoles: (...args: string[]) => boolean;
  hasOneOfRoles: (...args: string[]) => boolean;
  hasPermissions: (...args: Permission[]) => boolean;
  hasBlockedPermissions: (...args: Permission[]) => boolean;
  hasOneOfPermissions: (...args: Permission[]) => boolean;
}

const userStoreInitialState: UserStoreState = {
  contracts: [],
  customers: [],
  driver: null,
  email: null,
  id: 0,
  permissions: [],
  roles: [],
  smartPhone: null,
};

export const useUserStore = create<UserStoreState & UserStoreActions>(
  (setState, getState) => ({
    ...userStoreInitialState,
    reset: () => {
      setState(userStoreInitialState);
    },
    setUser: (user) => {
      setState({
        ...user,
        permissions: user.roles?.flatMap((role) => role.permissions),
      });
    },
    updateUserDetails: (user) => {
      setState({
        contracts: user.contracts,
        customers: user.customers,
        roles: user.roles,
      });
    },
    isSuperAdmin: () => {
      const {roles} = getState();
      return roles.some((role) => role.name === 'superadmin');
    },
    hasRoles: (...args) => {
      return args.every((arg) =>
        getState().roles.some(
          (role) => role.name.toLowerCase() === arg.toLowerCase(),
        ),
      );
    },
    hasOneOfRoles: (...args) => {
      return args.some((arg) =>
        getState().roles.some(
          (role) => role.name.toLowerCase() === arg.toLowerCase(),
        ),
      );
    },
    hasPermissions: (...args) => {
      if (getState().isSuperAdmin()) return true;
      if (
        args.some((arg) => permissions[arg].name === 'superadmin') &&
        !getState().isSuperAdmin()
      ) {
        return false;
      }

      return args.every((arg) =>
        getState().permissions.some(
          (permission) =>
            permission.name.toLowerCase() ===
              permissions[arg].name.toLowerCase() &&
            permission.type.toLowerCase() ===
              permissions[arg].type.toLowerCase(),
        ),
      );
    },
    hasBlockedPermissions: (...args) => {
      return args.every((arg) =>
        getState().permissions.some(
          (permission) =>
            permission.name.toLowerCase() ===
              permissions[arg].name.toLowerCase() &&
            permission.type.toLowerCase() ===
              permissions[arg].type.toLowerCase(),
        ),
      );
    },
    hasOneOfPermissions: (...args) => {
      if (getState().isSuperAdmin()) return true;
      if (
        args.some((arg) => permissions[arg].name === 'superadmin') &&
        !getState().isSuperAdmin()
      ) {
        return false;
      }
      return args.some((arg) =>
        getState().permissions.some(
          (permission) =>
            permission.name.toLowerCase() ===
              permissions[arg].name.toLowerCase() &&
            permission.type.toLowerCase() ===
              permissions[arg].type.toLowerCase(),
        ),
      );
    },
  }),
);
