import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import Drivers from './pages/Drivers';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyDriverAdd = lazy(async () => await import('./pages/DriverAdd'));
const LazyDriver = lazy(async () => await import('./pages/Driver'));

export const driverRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.driver.list)},
  {
    path: routes.getRelativePath(routes.driver.list, routes.driver.base),
    element: <Drivers />,
    loader: () => {
      document.title = 'Drivers';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Drivers'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.driver.add, routes.driver.base),
    element: <LazyDriverAdd />,
    loader: () => {
      document.title = 'Add Driver';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Drivers', path: routes.driver.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.driver.driverId, routes.driver.base),
    element: <LazyDriver />,
    loader: () => {
      document.title = 'View Driver';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Drivers', path: routes.driver.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
