import {useMemo} from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNewRounded';
import {Link} from 'react-router-dom';

import {TableActionButtonBase} from './TableActionButtonBase';

interface TableActionNavigateProps<TableData extends object> {
  onNavigate: (row: TableData) => string | null;
  row: TableData;
}

export function TableActionNavigate<TableData extends object>({
  onNavigate,
  row,
}: TableActionNavigateProps<TableData>) {
  const to = useMemo(() => onNavigate(row), [onNavigate, row]);

  if (to == null) {
    return (
      <TableActionButtonBase data-cy="TableActionsNavigate" disabled>
        <OpenInNewIcon fontSize="small" />
      </TableActionButtonBase>
    );
  }

  return (
    <Link to={to}>
      <TableActionButtonBase data-cy="TableActionsNavigate">
        <OpenInNewIcon fontSize="small" />
      </TableActionButtonBase>
    </Link>
  );
}
