import {contactApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadAudiences = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async (signal) => {
      return await contactApi.apiWebContactGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          name: getFilter('name'),
          description: getFilter('description'),
          email: getFilter('email'),
          cellphoneNumber: getFilter('cellphoneNumber'),
          contactGroup: getFilter('contactGroup'),
          type: getFilter('type'),
          externalId: getFilter('externalId'),
          emailBan: getFilter('emailBan') as boolean | undefined,
          smsBan: getFilter('smsBan') as boolean | undefined,
          callBan: getFilter('callBan') as boolean | undefined,
          whatsappBan: getFilter('whatsappBan') as boolean | undefined,
        },
        {signal},
      );
    },
    [getFilter, loadRequestSorting, loadRequestPagination],
    {updatePagination},
  );
};
