import {type ApiDriverGetRequest} from '@onroadvantage/onroadvantage-api';

import {driverApi} from '../../../api';
import {useFiltering} from '../../common/hooks/useFiltering.ts';
import {useLoadList} from '../../common/hooks/useLoadList';
import {usePagination} from '../../common/hooks/usePagination.ts';
import {useSorting} from '../../common/hooks/useSorting.ts';

export const useLoadDrivers = (apiDriverRequest?: ApiDriverGetRequest) => {
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});

  const loadList = useLoadList(
    async (signal) =>
      await driverApi.apiDriverGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          firstname: getFilter('firstname'),
          lastname: getFilter('lastname'),
          contactNumber: getFilter('contactNumber'),
          contractCode: getFilter('contractCode'),
          externalEmployeeNumber: getFilter('externalEmployeeNumber'),
          externalExtendedId: getFilter('externalExtendedId'),
          externalName: getFilter('externalName'),
          externalSiteId: getFilter('externalSiteId') as number | undefined,
          licenseExpiry: getFilter('licenseExpiry') as Date | undefined,
          licenseType: getFilter('licenseType'),
          telematicsConfigId: getFilter('telematicsConfigId') as
            | number
            | undefined,
          externalGroupId: getFilter('externalGroupId') as number | undefined,
          externalId: getFilter('externalId') as number | undefined,
          hasSmartphone: getFilter('hasSmartphone') as boolean | undefined,
          ...apiDriverRequest,
        },
        {signal},
      ),
    [getFilter, loadRequestPagination, loadRequestSorting],
    {updatePagination},
  );

  return {...loadList, tablePagination, tableFiltering, tableSorting};
};
