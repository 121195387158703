import {memo} from 'react';

import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Today from '@mui/icons-material/Today';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import {Calendar} from './Calendar';
import {DateButton} from './DateButton';
import {useDateRange} from './useDateRange';
import {useEscapeKey} from '../../hooks/useEscapeKey';

export interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
  onChangeToToday?: () => void;
  model?: boolean;
}

export const DateRangePicker = memo(function DateRangePicker({
  model = false,
  ...props
}: DateRangePickerProps) {
  const {
    optimisticRange,
    tempRange,
    isOpen,
    handleDateClick,
    handleApply,
    handleCancel,
    handleSetToToday,
    currentMonth,
    handleMonthChange,
    isInRange,
    openPicker,
    selectionMode,
    onHoverChange,
  } = useDateRange(props);
  useEscapeKey(handleCancel, model && isOpen);

  if (!model) {
    return (
      <Calendar
        currentMonth={currentMonth}
        selectionMode={selectionMode}
        handleDateClick={handleDateClick}
        handleMonthChange={handleMonthChange}
        isInRange={isInRange}
        tempRange={tempRange}
        onHoverChange={onHoverChange}
        handleSetToToday={handleSetToToday}
        handleApply={handleApply}
        handleCancel={handleCancel}
        enableToday={props.onChangeToToday != null}
      />
    );
  }

  return (
    <Box position="relative">
      <Stack direction="row" alignItems="center" spacing={1}>
        {props.onChangeToToday != null && (
          <IconButton size="small" onClick={handleSetToToday}>
            <Today />
          </IconButton>
        )}
        <DateButton
          type="start"
          optimisticRange={optimisticRange}
          isOpen={isOpen}
          selectionMode={selectionMode}
          openPicker={openPicker}
        />
        <ArrowForwardIos fontSize="small" color="action" />
        <DateButton
          type="end"
          optimisticRange={optimisticRange}
          isOpen={isOpen}
          selectionMode={selectionMode}
          openPicker={openPicker}
        />
      </Stack>
      {isOpen && (
        <ClickAwayListener onClickAway={handleCancel}>
          <Grow
            in={isOpen}
            style={{transformOrigin: 'top right'}}
            timeout={200}
          >
            <Calendar
              currentMonth={currentMonth}
              selectionMode={selectionMode}
              handleDateClick={handleDateClick}
              handleMonthChange={handleMonthChange}
              isInRange={isInRange}
              tempRange={tempRange}
              onHoverChange={onHoverChange}
              handleSetToToday={handleSetToToday}
              handleApply={handleApply}
              handleCancel={handleCancel}
              enableToday={props.onChangeToToday != null}
              model
            />
          </Grow>
        </ClickAwayListener>
      )}
    </Box>
  );
});
