import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyDriverGroups = lazy(async () => await import('./pages/DriverGroups'));
const LazyDriverGroupAdd = lazy(
  async () => await import('./pages/DriverGroupAdd'),
);
const LazyDriverGroup = lazy(async () => await import('./pages/DriverGroup'));

export const driverGroupRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.driverGroup.list)},
  {
    path: routes.getRelativePath(
      routes.driverGroup.list,
      routes.driverGroup.base,
    ),
    element: <LazyDriverGroups />,
    loader: () => {
      document.title = 'Driver Groups';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Driver Groups'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.driverGroup.add,
      routes.driverGroup.base,
    ),
    element: <LazyDriverGroupAdd />,
    loader: () => {
      document.title = 'Add Driver Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Driver Groups', path: routes.driverGroup.list},
          {label: 'Add'},
        ],
      });

      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.driverGroup.driverGroupId,
      routes.driverGroup.base,
    ),
    element: <LazyDriverGroup />,
    loader: () => {
      document.title = 'View Driver Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Driver Groups', path: routes.driverGroup.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
