import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTaskTemplates = lazy(
  async () => await import('./pages/TaskTemplates'),
);
const LazyTaskTemplateAdd = lazy(
  async () => await import('./pages/TaskTemplateAdd'),
);
const LazyTaskTemplate = lazy(async () => await import('./pages/TaskTemplate'));

export const taskTemplateRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.taskTemplate.list)},
  {
    path: routes.getRelativePath(
      routes.taskTemplate.list,
      routes.taskTemplate.base,
    ),
    element: <LazyTaskTemplates />,
    loader: () => {
      document.title = 'Task Templates';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Task Templates'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.taskTemplate.add,
      routes.taskTemplate.base,
    ),
    element: <LazyTaskTemplateAdd />,
    loader: () => {
      document.title = 'Add Task Template';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Task Templates', path: routes.taskTemplate.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.taskTemplate.taskTemplateId,
      routes.taskTemplate.base,
    ),
    element: <LazyTaskTemplate />,
    loader: () => {
      document.title = 'View Task Template';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Task Templates', path: routes.taskTemplate.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
