import {useEffect} from 'react';

import {useEventReviewContext} from '../contexts/EventReviewContext';

export const useAutoDetectLateClip = (
  isLeadTimeExceedingThirtyMinutes: boolean,
) => {
  const {handleLateClip, notificationComments} = useEventReviewContext();

  useEffect(() => {
    if (isLeadTimeExceedingThirtyMinutes) {
      const commentContainingLateClip = notificationComments.find(
        (comment) => comment.text === 'Could not notify, Late Clip',
      );

      if (commentContainingLateClip == null) {
        handleLateClip();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLeadTimeExceedingThirtyMinutes]);
};
