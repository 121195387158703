import {useCallback, useMemo} from 'react';

import {useLocation, useParams} from 'react-router-dom';

import {type MenuItem} from '../components/navigation/menuItems/menuItems';
import {type MenuItemProps} from '../components/navigation/NavigationMenuItem';
import {findCommonElements} from '../helpers/findCommonElements';
import {isStringAndNotEmpty} from '../helpers/unknownValueTypeChecks';

const getAllChildNames = (items: MenuItem[]): string[] => {
  let names: string[] = [];
  items.forEach((i) => {
    names.push(i.label);
    if (i.children != null) {
      names = names.concat(getAllChildNames(i.children));
    }
  });
  return names;
};

const getAllItemChildLabels = (item: MenuItem): string[] => {
  let names: string[] = [];
  if (item.children != null) {
    names = names.concat(getAllChildNames(item.children));
  }
  return names;
};

export const useMenuItem = ({
  item,
  expandedGroups,
  filter,
  setExpandedGroups,
  parentItem,
}: MenuItemProps) => {
  const {pathname, search} = useLocation();
  const params = useParams();
  const hasChildren = item.children != null && item.children.length > 0;

  const childNames = useMemo(() => {
    return item.children != null ? getAllChildNames(item.children) : [];
  }, [item.children]);

  const fullPathname = pathname + search;

  const paramValuesRegex = new RegExp(
    Object.values(params)
      .map((param) => `/${param}`)
      .join('|'),
    'g',
  );

  const cleanedFullPathname = fullPathname.replace(paramValuesRegex, '');

  const isActive = cleanedFullPathname === item.pathname;

  const isExpanded =
    isStringAndNotEmpty(filter) ||
    (expandedGroups != null &&
      (expandedGroups.includes(item.label) ||
        findCommonElements(expandedGroups, childNames)));

  const handleClick = useCallback(() => {
    if (hasChildren) {
      setExpandedGroups((prevState) => {
        const exists = prevState?.includes(item.label);
        const parentExists =
          parentItem?.label != null && prevState?.includes(parentItem.label);

        if (exists) {
          const childLabels = getAllItemChildLabels(item);
          return prevState?.filter(
            (label) => label !== item.label && !childLabels.includes(label),
          );
        }

        if (parentItem?.label != null) {
          if (parentExists) {
            return [...prevState, item.label];
          }
          return [parentItem.label, item.label];
        }
        return [item.label];
      });
    }
  }, [hasChildren, item, parentItem?.label, setExpandedGroups]);

  return {
    isExpanded,
    isActive,
    hasChildren,
    onClick: handleClick,
  };
};
