import {useMemo} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type AssociateNode, type Node} from '@onroadvantage/onroadvantage-api';

import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {
  type VantageTableColumn,
  type VantageTableConfig,
} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useEditSitePostSite} from '../hooks/useEditSitePostSite';
import {useLoadSitePostSites} from '../hooks/useLoadSitePostSites';

interface SitePostSitesProps {
  id: number;
  item: Node | null;
}

export function SitePostSites({id, item}: SitePostSitesProps) {
  const hasPermissions = useUserStore((state) => state.hasPermissions);

  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {reloadData, data} = useLoadSitePostSites({
    nodeId: id,
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });

  const listItemsTotal = data.length;
  const {addStatus, updateStatus, onDelete, onInlineAdd, onInlineEdit} =
    useEditSitePostSite({id, reload: reloadData, itemList: listItemsTotal});

  const config: VantageTableConfig<AssociateNode> = useMemo(() => {
    const baseColumns: Array<VantageTableColumn<AssociateNode>> = [
      {
        name: 'node',
        label: 'Site Name',
        type: 'autocomplete',
        models: 'Node',
        enableAdd: true,
        enableEdit: false,
        enableFilter: true,
        enableSort: true,
        getDisplayValue: (row) => row.node?.name,
      },
      {
        name: 'taskTemplateNodeType',
        label: 'Task Template Node Type',
        type: 'autocomplete',
        models: 'NodeType',
        enableAdd: true,
        enableEdit: false,
        enableFilter: true,
        enableSort: true,
      },
      {
        name: 'sequence',
        label: 'Sequence',
        type: 'number',
        enableEdit: true,
        enableFilter: true,
        enableAdd: true,
      },
    ];

    // Conditionally add additional columns for 'Default' site type for future use
    const additionalColumns: Array<VantageTableColumn<AssociateNode>> =
      item?.type?.toLowerCase() === 'default'
        ? [
            {
              name: 'serviceType',
              label: 'Service Type',
              type: 'string', // Change type to match the actual type expected
              enableAdd: true,
              enableEdit: true,
              enableFilter: true,
              enableSort: true,
            },
          ]
        : [];

    return {
      columns: [...baseColumns, ...additionalColumns],

      deleteMessage: (row) => (
        <Typography>
          Remove the Pre Site <strong>{row.node?.name}</strong> from the site
          <strong>{item?.name}</strong>
        </Typography>
      ),
    };
  }, [item]);

  return (
    <Stack position="relative">
      <OverlayLoader
        loading={addStatus === 'adding'}
        label="Adding Post Site..."
      />
      <OverlayLoader
        loading={updateStatus === 'submitting'}
        label="Updating Post Site..."
      />

      <VantageTable
        config={config}
        data={data}
        rowId="id"
        actions={hasPermissions('site:edit') ? {onInlineAdd} : undefined}
        rowActions={
          hasPermissions('site:edit') ? {onInlineEdit, onDelete} : undefined
        }
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
      />
    </Stack>
  );
}
