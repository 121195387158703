import {memo} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';

import {VantageAutocompleteFieldWithListing} from '../../form/VantageAutocompleteFieldWithListing';
import {onDateTypeProviderKeyDown} from '../helpers/onDateTypeProviderKeyDown';
import {type VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorAutocompleteWithListingProps<TableData extends object>
  extends Pick<
    DataTypeProvider.ValueEditorProps,
    | 'value'
    | 'onValueChange'
    | 'onBlur'
    | 'onKeyDown'
    | 'onFocus'
    | 'disabled'
    | 'autoFocus'
  > {
  columnConfig: VantageTableColumn<TableData>;
  multiple?: boolean;
  id: string;
  name: string;
}

function ValueEditorAutocompleteWithListingComponent<TableData extends object>({
  columnConfig,
  value,
  onValueChange,
  onKeyDown,
  ...props
}: ValueEditorAutocompleteWithListingProps<TableData>) {
  if (
    columnConfig == null ||
    (columnConfig?.type !== 'autocompleteMulti' &&
      columnConfig?.type !== 'autocomplete') ||
    !('models' in columnConfig)
  ) {
    throw new Error(
      `ValueEditorAutocompleteWithListing can only be used with columns of type 'autocompleteMulti' and with 'models' provided`,
    );
  }

  return (
    <VantageAutocompleteFieldWithListing
      {...props}
      limitTags={columnConfig.limitTags ?? 4}
      models={columnConfig.models}
      queryOptions={
        'queryOptions' in columnConfig
          ? {
              ...columnConfig.queryOptions,
            }
          : undefined
      }
      ChipProps={{size: 'small'}}
      size="small"
      sx={{my: -0.5}}
      defaultValue={value}
      onChange={(_event, newValue) => {
        onValueChange(newValue);
      }}
      onKeyDown={onDateTypeProviderKeyDown(onKeyDown)}
    />
  );
}

export const ValueEditorAutocompleteWithListing = memo(
  ValueEditorAutocompleteWithListingComponent,
) as typeof ValueEditorAutocompleteWithListingComponent;
