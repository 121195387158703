import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTransporters = lazy(async () => await import('./pages/Transporters'));
const LazyTransporterAdd = lazy(
  async () => await import('./pages/TransporterAdd'),
);
const LazyTransporter = lazy(async () => await import('./pages/Transporter'));

export const transporterRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.transporter.list)},
  {
    path: routes.getRelativePath(
      routes.transporter.list,
      routes.transporter.base,
    ),
    element: <LazyTransporters />,
    loader: () => {
      document.title = 'Transporters';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Transporters'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.transporter.add,
      routes.transporter.base,
    ),
    element: <LazyTransporterAdd />,
    loader: () => {
      document.title = 'Add Transporter';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Transporters', path: routes.transporter.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.transporter.transporterId,
      routes.transporter.base,
    ),
    element: <LazyTransporter />,
    loader: () => {
      document.title = 'View Transporter';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Transporters', path: routes.transporter.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
