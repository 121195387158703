import {useCallback, useState} from 'react';

import {type Filter} from '@devexpress/dx-react-grid';
import {type AutocompleteInputChangeReason} from '@mui/material/Autocomplete';
import {debounce} from '@mui/material/utils';
import {type Listing, OrderListing} from '@onroadvantage/onroadvantage-api';
import {toast, type TypeOptions} from 'react-toastify';

import {orderApi} from '../../../api';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import type {LoadStatus} from '../../common/types/LoadStatus';

export const useLoadUnassignedOrdersListing = (contractCode?: string) => {
  const [initialListing, setInitialListing] = useState<Listing[] | null>(null);
  const [listing, setListing] = useState<Listing[]>([]);
  const [data, setData] = useState<OrderListing[]>([]);
  const [loadOrderListingStatus, setLoadOrderListingStatus] =
    useState<LoadStatus>('idle');

  const loadOrderListing = useCallback(
    async (query?: string, reason?: AutocompleteInputChangeReason) => {
      if (
        (reason === 'reset' || reason === 'clear') &&
        initialListing != null
      ) {
        setListing(initialListing);
        return;
      }
      setLoadOrderListingStatus('loading');
      try {
        const response = await orderApi.apiOrderGet({
          page: 1,
          perPage: 25,
          getUnassignedOrdersOnly: true,
          orderBy: 'orderNumber',
          orderNumber: query,
          contractCode,
        });
        setData(response.items ?? []);
        const mappedListing: Listing[] =
          response.items?.map((item) => ({
            label: item.orderNumber,
            value: item.id,
            metaData: JSON.stringify(item),
          })) ?? [];

        if (initialListing == null) {
          setInitialListing(mappedListing);
        }
        setListing(mappedListing);
        setLoadOrderListingStatus('success');
      } catch (error) {
        let errorMessage = `Failed to load orders`;
        let type: TypeOptions = 'error';

        if (
          isObjectWithKeys(error, 'message') &&
          typeof error.message === 'string'
        ) {
          errorMessage = error.message;
        }

        if (isObjectWithKeys(error, 'cause') && error.cause === 'warning') {
          type = 'warning';
        }

        const toastId = `${errorMessage}|${type}`;

        if (!toast.isActive(toastId)) {
          toast(errorMessage, {type, toastId});
        }

        setLoadOrderListingStatus(type === 'error' ? 'error' : 'warning');
      }
    },
    [contractCode, initialListing],
  );

  return {
    listing,
    loadOrderListing,
    loadOrderListingStatus,
    data,
    onDebounceSearch: debounce(async (filters: Filter[]) => {
      const query = filters?.[0]?.value;

      // Check if the query is a string before passing it to loadOrderListing
      if (typeof query === 'string') {
        await loadOrderListing(query, 'input');
      }
    }, 500),
    onInputChange: debounce(
      async (
        _event: unknown,
        value: string,
        reason: AutocompleteInputChangeReason,
      ) => {
        await loadOrderListing(value, reason);
      },
      500,
    ),
  };
};
