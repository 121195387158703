import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyPlanningSkills = lazy(
  async () => await import('./pages/PlanningSkills'),
);
const LazyPlanningSkillAdd = lazy(
  async () => await import('./pages/PlanningSkillAdd'),
);
const LazyPlanningSkill = lazy(
  async () => await import('./pages/PlanningSkill'),
);

export const planningSkillRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.planningSkill.list)},
  {
    path: routes.getRelativePath(
      routes.planningSkill.list,
      routes.planningSkill.base,
    ),
    element: <LazyPlanningSkills />,
    loader: () => {
      document.title = 'Planning Skills';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Planning Skills'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.planningSkill.add,
      routes.planningSkill.base,
    ),
    element: <LazyPlanningSkillAdd />,
    loader: () => {
      document.title = 'Add Planning Skill';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Planning Skills', path: routes.planningSkill.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.planningSkill.planningSkillId,
      routes.planningSkill.base,
    ),
    element: <LazyPlanningSkill />,
    loader: () => {
      document.title = 'View Planning Skill';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Planning Skills', path: routes.planningSkill.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
