import {type Driver} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import {driverApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteDriver = ({removeItem}: DeleteParams) => {
  return useDelete<Driver>(async ({id}) => {
    if (id == null) {
      toast('Driver ID not found', {type: 'warning'});
      return;
    }
    await driverApi.apiDriverDriverIdDelete({
      driverId: id,
    });
    removeItem(id);
  });
};
