import {createContext, useContext} from 'react';

import {
  type EventReviewContextType,
  useEventReviewContextResponseInitial,
} from './contextInitialValues';

export const EventReviewContext = createContext<EventReviewContextType>(
  useEventReviewContextResponseInitial,
);

export const useEventReviewContext = (): EventReviewContextType =>
  useContext(EventReviewContext);
