import {useEffect, useState} from 'react';

import type {Behaviour} from '@onroadvantage/onroadvantage-api';

import {behaviourApi} from '../../../api';

export const useLoadEventReviewBehaviours = () => {
  const [behaviours, setBehaviours] = useState<Behaviour[]>();

  useEffect(() => {
    const fetchBehaviours = async () => {
      try {
        const {items} = await behaviourApi.apiBehaviourListingGet({});
        if (items != null) {
          setBehaviours(items);
        }
      } catch {
        //
      }
    };
    void fetchBehaviours();
  }, []);

  return {
    behaviours,
  };
};
