import {type ReactNode, useCallback, useMemo, useRef, useState} from 'react';

import type {
  EarlyWarningEventDetailsResponse,
  EarlyWarningEventPostReviewDetailsResponse,
} from '@onroadvantage/onroadvantage-api';
import type {FormikProps} from 'formik';
import {useLocation} from 'react-router-dom';

import type {EventReviewContextType} from './contextInitialValues';
import {EventReviewContext} from './EventReviewContext';
import {routes} from '../../../routes';
import {EventReviewBottomBar} from '../components/EventReviewBottomBar';
import type {EventDetailsData} from '../hooks/useEventFeedback';
import {useGenerateLookupTables} from '../hooks/useGenerateLookupTables';
import {useLoadEventReviewBehaviours} from '../hooks/useLoadEventReviewBehaviours';
import {usePreventNavigation} from '../hooks/usePreventNavigation';
import {useEventReviewWebSocket} from '../hooks/useReviewEventWebSocket';
import {useShortcutSubmissions} from '../hooks/useShortcutSubmissions';

interface EventReviewProviderProps {
  children: ReactNode;
}

export function EventReviewProvider({children}: EventReviewProviderProps) {
  const {pathname} = useLocation();
  const [showOnBreakModal, setShowOnBreakModal] = useState<boolean>(false);
  const [snapshotList, setSnapshotList] = useState<number[]>([]);
  const formRef = useRef<FormikProps<Record<string, boolean>>>(null);
  const [keyBuffer, setKeyBuffer] = useState<string>('');
  const [onEventSelectedShortCuts, setOnEventSelectedShortcuts] = useState<
    string[]
  >([]);
  const [notificationComments, setNotificationComments] = useState<
    Array<{text: string; commentTime: string}>
  >([]);
  const [hindsightBehaviourOutcomeData, setHindsightBehaviourOutcomeData] =
    useState<EarlyWarningEventPostReviewDetailsResponse | null>();
  const [eventDetailsData, setEventDetailsData] =
    useState<EventDetailsData | null>(null);
  const [inlineEventData, setInlineEventData] =
    useState<EarlyWarningEventDetailsResponse | null>(null);
  const webSocket = useEventReviewWebSocket();
  const {behaviours} = useLoadEventReviewBehaviours();
  const lookupTableData = useGenerateLookupTables({behaviours});

  const renderActionsBar = useMemo(
    () => routes.eventReview.feedback === pathname,
    [pathname],
  );

  const memoizedWebSocketHookData = useMemo(
    () => ({
      webSocket,
    }),
    [webSocket],
  );

  const memoizedLookupTableData = useMemo(
    () => ({
      lookupTableData,
    }),
    [lookupTableData],
  );

  const resetContext = useCallback(() => {
    setSnapshotList([]);
    setOnEventSelectedShortcuts([]);
    formRef.current?.resetForm();
    setNotificationComments([]);
    setHindsightBehaviourOutcomeData(null);
    setInlineEventData(null);
    setEventDetailsData(null);
  }, []);

  const {handleNoIssue, handleFaultyClip, handleLateClip} =
    useShortcutSubmissions({
      webSocket,
      lookupTableData,
      formRef,
      onEventSelectedShortCuts,
      setNotificationComments,
      notificationComments,
      resetContext,
    });

  usePreventNavigation();

  const contextValue: EventReviewContextType = {
    webSocket: memoizedWebSocketHookData.webSocket,
    formRef,
    keyBuffer,
    setKeyBuffer,
    snapshotList,
    setSnapshotList,
    onEventSelectedShortCuts,
    setOnEventSelectedShortcuts,
    showOnBreakModal,
    setShowOnBreakModal,
    resetContext,
    handleNoIssue,
    behaviours,
    eventDetailsData,
    setEventDetailsData,
    inlineEventData,
    setInlineEventData,
    notificationComments,
    setNotificationComments,
    handleFaultyClip,
    handleLateClip,
    hindsightBehaviourOutcomeData,
    setHindsightBehaviourOutcomeData,
    lookupTableData: memoizedLookupTableData.lookupTableData,
  };

  return (
    <EventReviewContext.Provider value={contextValue}>
      {children}
      {renderActionsBar && <EventReviewBottomBar />}
    </EventReviewContext.Provider>
  );
}
