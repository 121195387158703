import {type ReactNode, useCallback} from 'react';

import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {useAuth} from 'oidc-react';
import {useNavigate} from 'react-router-dom';

import {getGlassBackground} from '../../../theme/getGlassBackground';
import {useAuthStore} from '../../common/stores/authStore';
import {useUserStore} from '../../common/stores/userStore';
import {type HomeMenuItem} from '../homeMenuItems/homeMenuItems';

interface HomeCardProps {
  title: string;
  icon: ReactNode;
  menuItems: HomeMenuItem[];
}

export function HomeCard({title, icon, menuItems}: HomeCardProps) {
  const navigate = useNavigate();
  const {isLoading} = useAuth();
  const authStatus = useAuthStore((state) => state.authStatus);
  const isSuperAdmin = useUserStore((state) => state.isSuperAdmin);
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const hasOneOfPermissions = useUserStore(
    (state) => state.hasOneOfPermissions,
  );

  const handleMenuItemFilter = useCallback(
    ({permissions, permissionCheck}: HomeMenuItem) => {
      if (permissionCheck === 'superadmin') {
        return isSuperAdmin();
      }
      if (permissions == null || permissions.length === 0) {
        return true;
      }
      if (permissionCheck === 'one-of') {
        return hasOneOfPermissions(...permissions);
      }
      return hasPermissions(...permissions);
    },
    [hasOneOfPermissions, hasPermissions, isSuperAdmin],
  );

  return (
    <Card>
      <Stack direction="row" alignItems="center" p={1.5} pb={1} spacing={1}>
        {icon}
        <Typography variant="h6" fontWeight={600} color="text.secondary">
          {title}
        </Typography>
      </Stack>
      <Stack px={1} pb={1}>
        {authStatus === 'authenticating' || isLoading
          ? Array.from({length: menuItems.length}, (_, index) => (
              <Skeleton key={index} height={30} />
            ))
          : menuItems.filter(handleMenuItemFilter).map((menuItem) => (
              <Button
                key={menuItem.label}
                color="inherit"
                variant="text"
                size="small"
                sx={{justifyContent: 'flex-start', textTransform: 'none'}}
                onClick={() => {
                  navigate(menuItem.path);
                }}
                fullWidth
              >
                <Typography whiteSpace="nowrap">{menuItem.label}</Typography>
              </Button>
            ))}
      </Stack>
    </Card>
  );
}

const Card = styled(MuiCard)(({theme}) => ({
  display: 'flex',
  minWidth: 205,
  width: 'fit-content',
  flexDirection: 'column',
  flex: 1,
  ...getGlassBackground(theme),
}));
