import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Vehicle} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {getTooltipTableColumnValues} from '../../common/helpers/getTooltipTableColumnValues';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {DateUtil} from '../../common/utils/DateUtil';
import {useDownloadVehicles} from '../hooks/useDownloadVehicles';
import {useLoadVehicles} from '../hooks/useLoadVehicles';

const tableConfig: VantageTableConfig<Vehicle> = {
  columns: [
    {
      name: 'registrationNumber',
      label: 'Registration',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'fleetNumber',
      label: 'Fleet Number',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'vehicleType',
      label: 'Vehicle Type',
      type: 'string',
      enableFilter: true,
      getValue: ({type}) => type?.name,
    },
    {
      name: 'contract.code',
      label: 'Contract Code',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      getValue: ({contract}) => contract?.code ?? '-',
    },
    {
      name: 'groups',
      label: 'Groups',
      type: 'string',
      getValue: ({vehicleGroups}) =>
        getTooltipTableColumnValues(
          vehicleGroups?.map(({name}) => name),
          2,
        ),
    },
    {
      name: 'lastOdometer',
      label: 'Last Odometer',
      type: 'string',
      enableSort: true,
      getValue: ({lastOdometer}) => {
        if (lastOdometer != null && !Number.isNaN(parseFloat(lastOdometer))) {
          const number = parseFloat(lastOdometer);
          const roundedNumber = Math.round(number * 100) / 100; // Round to two decimal points
          return `${roundedNumber.toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}km`; // Format with commas
        }
        return '-';
      },
    },
    {
      name: 'lastSeen',
      label: 'Last Seen',
      type: 'string',
      getValue: ({lastSeen}) =>
        DateUtil.format(lastSeen?.eventDate, 'ShortDateTime') ?? '-',
    },
  ],
  identifier: 'vehicles',
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Vehicle registration:</Typography>
        <Typography>{row.registrationNumber}</Typography>
      </Stack>
    </Stack>
  ),
};
export default function Vehicles() {
  const navigate = useNavigate();
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData} = useLoadVehicles({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDownload, downloadStatus} = useDownloadVehicles();

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={downloadStatus === 'downloading'}
        label="Downloading vehicles..."
      />
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        toolbar={{onRefresh: reloadData}}
        actions={{
          onAdd: hasPermissions('vehicle:add')
            ? () => {
                navigate(routes.vehicle.add);
              }
            : undefined,
          onDownload,
        }}
        rowActions={{
          onNavigate: ({id}) => {
            if (id != null) {
              return routes.replaceParams(routes.vehicle.vehicleId, {
                vehicleId: id,
              });
            }
            return null;
          },
        }}
      />
    </VantageTablePaper>
  );
}
