import {DateTime} from 'luxon';
import {create} from 'zustand';
import {persist} from 'zustand/middleware';

interface DateRangeStoreState {
  startDate: string;
  endDate: string;
  isActive: boolean;
}

interface DateRangeStoreActions {
  setStartDate: (startDate: Date | null) => void;
  setEndDate: (endDate: Date | null) => void;
  setToToday: () => void;
  setIsActive: (newState: boolean) => void;
  toggleIsActive: () => void;
}

const dateRangeStoreInitialState: DateRangeStoreState = {
  startDate: DateTime.now().startOf('day').toISO(),
  endDate: DateTime.now().endOf('day').toISO(),
  isActive: true,
};

export const useDateRangeStore = create<
  DateRangeStoreState & DateRangeStoreActions
>()(
  persist(
    (setState) => ({
      ...dateRangeStoreInitialState,
      setStartDate: (startDate) => {
        const startDateTime =
          startDate != null
            ? DateTime.fromJSDate(startDate).startOf('day').toISO()
            : null;

        if (startDateTime != null) {
          setState((state) => {
            if (startDateTime != null && startDateTime > state.endDate) {
              return {
                startDate: startDateTime,
                endDate:
                  DateTime.fromISO(startDateTime).endOf('day').toISO() ??
                  state.endDate,
              };
            }

            return {
              startDate: startDateTime,
            };
          });
        }
      },
      setEndDate: (endDate) => {
        const endDateTime =
          endDate != null
            ? DateTime.fromJSDate(endDate).endOf('day').toISO()
            : null;

        if (endDateTime != null) {
          setState((state) => {
            if (endDateTime != null && endDateTime < state.startDate) {
              return {
                endDate: endDateTime,
                startDate:
                  DateTime.fromISO(endDateTime).startOf('day').toISO() ??
                  state.startDate,
              };
            }

            return {
              endDate: endDateTime,
            };
          });
        }
      },
      setToToday: () => {
        setState({
          startDate: DateTime.now().startOf('day').toISO(),
          endDate: DateTime.now().endOf('day').toISO(),
        });
      },
      setIsActive: (newState) => {
        setState({isActive: newState});
      },
      toggleIsActive: () => {
        setState((currentState) => ({isActive: !currentState.isActive}));
      },
    }),
    {
      name: 'dateRange',
      partialize: (state) => ({
        startDate: state.startDate,
        endDate: state.endDate,
      }),
    },
  ),
);
