import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import Broadcasts from './pages/Broadcasts';
import BroadcastSingleMessage from './pages/BroadcastSingleMessage';
import BroadcastVantageMobile from './pages/BroadcastVantageMobile';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

export const broadcastRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.broadcast.list)},
  {
    path: routes.getRelativePath(
      routes.broadcast.singleMessage,
      routes.broadcast.base,
    ),
    element: <BroadcastSingleMessage />,
    loader: () => {
      document.title = 'Single Message Broadcast';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Single Message Broadcast'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.broadcast.vantageMobile,
      routes.broadcast.base,
    ),
    element: <BroadcastVantageMobile />,
    loader: () => {
      document.title = 'Vantage Mobile Broadcast';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Vantage Mobile Broadcast'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.broadcast.list, routes.broadcast.base),
    element: <Broadcasts />,
    loader: () => {
      document.title = 'Broadcasts';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Broadcasts'}],
      });
      return null;
    },
  },
];
