import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyRoles = lazy(async () => await import('./pages/Roles'));
const LazyRoleAdd = lazy(async () => await import('./pages/RoleAdd'));
const LazyRole = lazy(async () => await import('./pages/Role'));

export const roleRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.role.list)},
  {
    path: routes.getRelativePath(routes.role.list, routes.role.base),
    element: <LazyRoles />,
    loader: () => {
      document.title = 'Roles';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Roles'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.role.add, routes.role.base),
    element: <LazyRoleAdd />,
    loader: () => {
      document.title = 'Add Role';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Roles', path: routes.role.list}, {label: 'Add'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.role.roleId, routes.role.base),
    element: <LazyRole />,
    loader: () => {
      document.title = 'View Role';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Roles', path: routes.role.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
