import {type Behaviour} from '@onroadvantage/onroadvantage-api';
import {DateTime} from 'luxon';

interface Category {
  id?: number;
  name?: string;
}

interface LookupData {
  behaviourCategory?: Category;
  behaviourType?: Category;
  id?: number;
  name?: string;
  shortcut?: string;
}

export interface DatalakeItem {
  name?: string;
  id?: number;
  category?: string;
  type: 'behaviour' | 'observation';
}

interface EnhancedFormData {
  observations?: DatalakeItem[];
  behaviours?: DatalakeItem[];
}

interface LeadTime {
  days?: number | null;
  hours?: number | null;
  minutes?: number | null;
  seconds?: number | null;
}

type FormValues = Record<string, boolean>;

type LookupTable = Record<string, LookupData>;

type CategorizedItems = Record<string, Behaviour[]>;

type LookupObject = Record<string, boolean>;

const SOUND_CATEGORY_ID = 5;
const NO_ISSUE_CATEGORY_ID = 7;

export const shouldSkipEscalation = (
  data: Behaviour[],
  selectedIds: number[],
): boolean => {
  const allBehaviourIds = new Set<number>();

  for (const item of data) {
    if (item.escalate !== true && item?.id != null) {
      allBehaviourIds.add(item.id);
    }
  }

  return selectedIds.every((id) => allBehaviourIds.has(id));
};

export const enhanceFormData = (
  formValues: FormValues,
  behaviorLookupTable: LookupTable,
  observationLookupTable: LookupTable,
): {enhancedData: EnhancedFormData; ids: number[]} => {
  const result = Object.entries(formValues).reduce<{
    enhancedData: EnhancedFormData;
    ids: number[];
  }>(
    (acc, [key, value]) => {
      if (value) {
        const extractedLookupKey = extractKeyWithSplit(key);
        if (extractedLookupKey != null) {
          const behaviorData = behaviorLookupTable[extractedLookupKey];
          const observationData = observationLookupTable[extractedLookupKey];
          const data = {...behaviorData, ...observationData};

          if (data != null) {
            if (observationData != null) {
              if (acc.enhancedData.observations == null) {
                acc.enhancedData.observations = [];
              }
              acc.enhancedData.observations.push({
                name: data.name,
                id: data.id,
                category: data.behaviourCategory?.name ?? '',
                type: 'observation',
              });
            } else if (behaviorData != null) {
              if (acc.enhancedData.behaviours == null) {
                acc.enhancedData.behaviours = [];
              }
              acc.enhancedData.behaviours.push({
                name: data.name,
                id: data.id,
                category: data.behaviourCategory?.name ?? '',
                type: 'behaviour',
              });
            }

            if (data.id != null) {
              acc.ids.push(data.id);
            }
          }
        }
      }
      return acc;
    },
    {enhancedData: {}, ids: []},
  );

  return result;
};

export const categorizeItems = (
  items: Behaviour[] | undefined,
  typeName: string,
): CategorizedItems => {
  if (items == null || items.length === 0) return {};
  return items.reduce<CategorizedItems>((acc, item) => {
    if (
      item?.behaviourCategory?.name == null ||
      item?.behaviourType?.name == null
    ) {
      return {};
    }
    if (item.behaviourType.name.toLocaleLowerCase() === typeName) {
      const category = item.behaviourCategory.name;
      if (acc[category] == null) {
        acc[category] = [];
      }
      acc[category].push(item);
    }
    return acc;
  }, {});
};

export const extractKeyWithSplit = (str: string) => {
  const parts = str.split('lookupKey:');
  if (parts.length > 1) {
    return parts[1].trim().split('__')[0].trim();
  }
  return null;
};

export const processLookupObject = (obj: LookupObject): LookupObject => {
  const result: LookupObject = {};

  for (const [key, value] of Object.entries(obj)) {
    if (!value) continue;
    const cleanKey = key.replace(/__lookupKey: \w+__/g, '');
    result[cleanKey.trim()] = value;
  }

  return result;
};

export const checkBehaviorTypes = (
  cleanedLookup: Record<string, boolean>,
  behaviourList?: Behaviour[],
): boolean => {
  if (behaviourList == null) return false;

  const hasType1 = behaviourList.some((behaviour) => {
    if (behaviour?.name == null) return false;
    if (behaviour?.name?.includes('No Issue')) return false;
    return (
      cleanedLookup[behaviour.name.trim()] && behaviour?.behaviourType?.id === 1
    );
  });

  if (hasType1) return false;

  return behaviourList.some((behaviour) => {
    if (behaviour?.name == null) return false;
    if (behaviour?.name?.includes('No Issue')) return true;
    return (
      cleanedLookup[behaviour.name.trim()] && behaviour?.behaviourType?.id === 2
    );
  });
};

export const hasOnlySoundBehaviours = (
  cleanedLookup: Record<string, boolean>,
  behaviourList?: Behaviour[],
) => {
  if (Object.entries(cleanedLookup).length === 0) {
    return false;
  }
  const categoryTypes: number[] = [];

  behaviourList?.forEach((behaviour) => {
    if (behaviour?.name != null && cleanedLookup[behaviour.name.trim()]) {
      categoryTypes.push(Number(behaviour.behaviourCategory?.id));
    }
  });

  const nonSound = categoryTypes.filter(
    (id) => id !== SOUND_CATEGORY_ID && id !== NO_ISSUE_CATEGORY_ID,
  );

  return nonSound.length < 1;
};

export const calculateLeadTime = (
  recordDate?: string,
  reviewStart?: string,
) => {
  if (recordDate == null) return null;

  const recorded = DateTime.fromISO(recordDate);

  if (!recorded.isValid) return null;

  const now =
    reviewStart != null ? DateTime.fromISO(reviewStart) : DateTime.now();
  const leadTimeDuration = now.diff(recorded, [
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  return leadTimeDuration.toObject();
};

export const formatLeadTime = (leadTime: LeadTime | null): string => {
  if (leadTime == null) {
    return '00d 00h 00m 00s';
  }

  const days =
    leadTime.days != null && leadTime.days > 0
      ? `${String(leadTime.days)}d `
      : '';

  const hours = String(leadTime.hours ?? 0).padStart(2, '0');
  const minutes = String(leadTime.minutes ?? 0).padStart(2, '0');
  const seconds = String(Math.floor(Number(leadTime.seconds ?? 0))).padStart(
    2,
    '0',
  );

  return `${days}${hours}h ${minutes}m ${seconds}s`;
};
