import {lazy, useState} from 'react';

import Stack from '@mui/material/Stack';

import {VantageTab} from '../../common/components/tabs/VantageTab';
import {VantageTabs} from '../../common/components/tabs/VantageTabs';

const LazyDailyDriverWorkflows = lazy(
  async () => await import('../pages/DailyDriverWorkflows'),
);

const LazyAdhocDriverWorkflows = lazy(
  async () => await import('../pages/AdhocDriverWorkflows'),
);

const driverWorkflowTabs = ['Daily', 'Adhoc'] as const;
type DriverWorkflowViewTab = (typeof driverWorkflowTabs)[number];

export function DriverWorkflowTabs() {
  const [activeTab, setActiveTab] = useState<DriverWorkflowViewTab>('Daily');
  return (
    <Stack height="100%">
      <VantageTabs activeTab={activeTab} onTabChange={setActiveTab}>
        {driverWorkflowTabs.map((tab) => (
          <VantageTab key={tab} value={tab} label={tab} />
        ))}
      </VantageTabs>
      {activeTab === 'Daily' && <LazyDailyDriverWorkflows />}
      {activeTab === 'Adhoc' && <LazyAdhocDriverWorkflows />}
    </Stack>
  );
}
