import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type WebContactListItem} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {
  VantageTable,
  type VantageTableActions,
  type VantageTableRowActions,
} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteAudience} from '../hooks/useDeleteAudience';
import {useLoadAudiences} from '../hooks/useLoadAudiences';

const tableConfig: VantageTableConfig<WebContactListItem> = {
  columns: [
    {
      name: 'name',
      label: 'Audience',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
    },
    {
      name: 'email',
      label: 'Email',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
    },
    {
      name: 'cellphoneNumber',
      label: 'Cellphone Number',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
    },
    {
      name: 'type',
      label: 'Notification Type',
      type: 'string',
      getValue: ({type}) => (type === '0' || type == null ? 'Normal' : type),
    },
    {
      name: 'contactGroup',
      label: 'Audience Group',
      type: 'string',
      enableGrouping: true,
    },
    {name: 'smsBan', label: 'Sms Ban', type: 'boolean', enableGrouping: true},
    {name: 'callBan', label: 'Call Ban', type: 'boolean', enableGrouping: true},
    {
      name: 'whatsappBan',
      label: 'Whatsapp Ban',
      type: 'boolean',
      enableGrouping: true,
    },
    {
      name: 'emailBan',
      label: 'Email Ban',
      type: 'boolean',
      enableGrouping: true,
    },
    {
      name: 'notificationBanStart',
      label: 'Notification Ban Start',
      type: 'string',
      enableGrouping: true,
    },
    {
      name: 'notificationBanEnd',
      label: 'Notification Ban End',
      type: 'string',
      enableGrouping: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Contact Name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Email:</Typography>
        <Typography>{row.email}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnResizing: true,
  enableColumnVisibility: true,
  enableColumnGrouping: true,
  identifier: 'audience',
};

export default function Audiences() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData, removeItem} = useLoadAudiences({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete} = useDeleteAudience({
    removeItem,
  });

  const actions: VantageTableActions = {};
  if (hasPermissions('audience:add')) {
    actions.onAdd = () => {
      navigate(routes.audience.add);
    };
  }
  const rowActions: VantageTableRowActions<WebContactListItem> = {
    onNavigate: ({id}) => {
      if (id != null) {
        return routes.replaceParams(routes.audience.contactId, {contactId: id});
      }
      return null;
    },
  };
  if (hasPermissions('audience:delete')) {
    rowActions.onDelete = onDelete;
  }

  return (
    <VantageTablePaper>
      <VantageTable<WebContactListItem>
        rowId="id"
        config={tableConfig}
        data={data}
        toolbar={{onRefresh: reloadData}}
        grouping={[]}
        actions={actions}
        rowActions={rowActions}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
      />
    </VantageTablePaper>
  );
}
