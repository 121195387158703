import {
  useState,
  useCallback,
  useMemo,
  useOptimistic,
  startTransition,
} from 'react';

import {DateTime} from 'luxon';

import {DateRangePickerProps} from './DateRangePicker';

export type SelectionMode = 'start' | 'end';

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

export const useDateRange = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  onChangeToToday,
}: Omit<DateRangePickerProps, 'model'>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectionMode, setSelectionMode] = useState<SelectionMode>('start');

  const [optimisticRange, updateRange] = useOptimistic(
    {startDate, endDate},
    (state, newRange: DateRange) => ({
      startDate: newRange.startDate || state.startDate,
      endDate: newRange.endDate || state.endDate,
    }),
  );

  const [tempRange, setTempRange] = useState<DateRange>(optimisticRange);
  const [hoverDate, setHoverDate] = useState<Date | null>(null);
  const [currentMonth, setCurrentMonth] = useState(
    new Date(startDate.getFullYear(), startDate.getMonth() - 1),
  );

  const getDaysInMonth = useCallback((date: Date) => {
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const days = Array(firstDay.getDay()).fill(null);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    return days.concat(
      Array.from(
        {length: lastDay.getDate()},
        (_, i) => new Date(date.getFullYear(), date.getMonth(), i + 1),
      ),
    );
  }, []);

  const isInRange = useMemo(
    () => (date: Date | null) => {
      if (!tempRange.startDate || !date) return false;
      const start = tempRange.startDate;
      const end = tempRange.endDate || hoverDate;

      const [minDate, maxDate] =
        start <= (end || start) ? [start, end || start] : [end || start, start];

      return date >= minDate && date <= maxDate;
    },
    [tempRange.startDate, tempRange.endDate, hoverDate],
  );

  const handleDateClick = useCallback(
    (date: Date) => {
      setTempRange((currentRange) => {
        const isStartMode =
          selectionMode === 'start' || !currentRange.startDate;

        if (isStartMode) {
          return {
            startDate: date,
            endDate:
              !currentRange.endDate || date > currentRange.endDate
                ? DateTime.fromJSDate(date)
                    .plus({day: 1})
                    .endOf('day')
                    .toJSDate()
                : currentRange.endDate,
          };
        }

        return {
          startDate:
            !currentRange.startDate || date < currentRange.startDate
              ? DateTime.fromJSDate(date)
                  .minus({day: 1})
                  .startOf('day')
                  .toJSDate()
              : currentRange.startDate,
          endDate: date,
        };
      });

      setSelectionMode((isStartMode) =>
        isStartMode === 'start' ? 'end' : 'start',
      );
    },
    [selectionMode],
  );

  const handleApply = useCallback(() => {
    if (tempRange.startDate && tempRange.endDate) {
      updateRange(tempRange);
      startTransition(() => {
        onStartDateChange(tempRange.startDate!);
        onEndDateChange(tempRange.endDate!);
      });
      setIsOpen(false);
    }
  }, [tempRange, updateRange, onStartDateChange, onEndDateChange]);

  const handleCancel = useCallback(() => {
    setTempRange(optimisticRange);
    setIsOpen(false);
    setSelectionMode('start');
  }, [optimisticRange]);

  const handleMonthChange = useCallback((increment: number) => {
    startTransition(() => {
      setCurrentMonth(
        (date) => new Date(date.getFullYear(), date.getMonth() + increment),
      );
    });
  }, []);

  const openPicker = useCallback(
    (mode: SelectionMode) => {
      setSelectionMode(mode);
      setTempRange(optimisticRange);
      setCurrentMonth(
        new Date(startDate.getFullYear(), startDate.getMonth() - 1),
      );
      setIsOpen(true);
    },
    [optimisticRange, startDate],
  );

  const handleSetToToday = useCallback(() => {
    const today = DateTime.now();
    const newRange = {
      startDate: today.startOf('day').toJSDate(),
      endDate: today.endOf('day').toJSDate(),
    };
    startTransition(() => {
      onChangeToToday?.();
      updateRange(newRange);
      setTempRange(newRange);
      setCurrentMonth(
        new Date(
          newRange.startDate.getFullYear(),
          newRange.startDate.getMonth() - 1,
        ),
      );
    });
  }, [updateRange, onChangeToToday]);

  const onHoverChange = useCallback((date: Date | null) => {
    setHoverDate(date);
  }, []);

  return {
    optimisticRange,
    tempRange,
    isOpen,
    handleDateClick,
    handleApply,
    handleCancel,
    handleSetToToday,
    currentMonth,
    getDaysInMonth,
    handleMonthChange,
    isInRange,
    openPicker,
    selectionMode,
    onHoverChange,
  };
};

export type UseDateRangeReturnType = ReturnType<typeof useDateRange>;
