import {useCallback, useEffect} from 'react';

import {type UserMe} from '@onroadvantage/onroadvantage-api';
import {useAuth} from 'oidc-react';
import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {userApi} from '../../../api';
import {config} from '../../../config';
import {routes} from '../../../routes';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useAuthStore} from '../../common/stores/authStore';
import {useEventStore} from '../../common/stores/eventStore';
import {useThemeStore} from '../../common/stores/themeStore';
import {useUserStore} from '../../common/stores/userStore';
import {clearOidcLocalStorage} from '../helpers/clearOidcLocalStorage';

export const useLoadUserMe = () => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const token = useAuthStore((state) => state.token);
  const ssoToken = useAuthStore((state) => state.ssoToken);
  const authStatus = useAuthStore((state) => state.authStatus);
  const resetAuthStore = useAuthStore((state) => state.reset);
  const resetUserStore = useUserStore((state) => state.reset);
  const setAuthStatus = useAuthStore((state) => state.setAuthStatus);
  const setUser = useUserStore((state) => state.setUser);
  const setWhiteLabelledThemeOptions = useThemeStore(
    (state) => state.setWhiteLabelledThemeOptions,
  );
  const eventsCountTimeoutRef = useEventStore(
    (state) => state.eventsCountTimeoutRef,
  );

  const handleUnauthenticated = useCallback(async () => {
    setAuthStatus('unauthenticated');
    if (!pathname.includes(routes.auth.login)) {
      if (pathname.includes(routes.auth.base)) {
        navigate(routes.auth.login, {
          replace: true,
        });
      } else {
        navigate(
          routes.addQueryParams(routes.auth.login, {redirect: pathname}),
          {
            replace: true,
          },
        );
      }
    }
    resetAuthStore();
    resetUserStore();
    if (eventsCountTimeoutRef != null) {
      clearTimeout(eventsCountTimeoutRef);
    }
    if (ssoToken != null && auth.isLoading) {
      clearOidcLocalStorage();
      try {
        await auth.userManager.signoutSilent();
        await auth.userManager.revokeTokens();
        await auth.userManager.clearStaleState();
      } catch {
        // eslint-disable-next-line no-console
        console.error('Failed to sign out');
      }
    }
  }, [
    auth.isLoading,
    auth.userManager,
    eventsCountTimeoutRef,
    navigate,
    pathname,
    resetAuthStore,
    resetUserStore,
    setAuthStatus,
    ssoToken,
  ]);

  const handleLoginResponse = useCallback(
    async (response: UserMe) => {
      if (response.id == null) {
        await handleUnauthenticated();
        return;
      }

      if (response.profile?.theme != null) {
        setWhiteLabelledThemeOptions(response.profile.theme);
      }
      if (pathname.includes(routes.auth.base)) {
        navigate(routes.home, {replace: true});
      }
      setAuthStatus('authenticated');
      setUser(response);
    },
    [
      handleUnauthenticated,
      navigate,
      pathname,
      setAuthStatus,
      setUser,
      setWhiteLabelledThemeOptions,
    ],
  );

  const handleCatch = useCallback(
    async (error: unknown, tokenType: 'sso' | 'vantage') => {
      if (tokenType === 'sso') {
        toast.error('Unable to authenticate. Confirm your sso user is linked', {
          toastId: 'unauthorized',
          type: 'error',
        });
        await handleUnauthenticated();
        return;
      }

      if (
        isObjectWithKeys(error, 'message') &&
        error.message === 'Failed to fetch'
      ) {
        if (!toast.isActive('internet-connection')) {
          toast(`Check your internet connection, unable to reach resource.`, {
            type: 'warning',
            toastId: 'internet-connection',
            delay: config.toastyAutoHideDuration * 2,
          });
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 10000);
      } else {
        if (!toast.isActive('unauthorized')) {
          toast('User unauthorized, please login again.', {
            toastId: 'unauthorized',
            type: 'error',
          });
        }
        await handleUnauthenticated();
      }
    },
    [handleUnauthenticated],
  );

  const loadUserMe = useCallback(
    async (tokenType: 'sso' | 'vantage') => {
      setAuthStatus('authenticating');

      try {
        const response = await userApi.apiUserMeGet();
        await handleLoginResponse(response);
      } catch (error) {
        await handleCatch(error, tokenType);
      }
    },
    [handleCatch, handleLoginResponse, setAuthStatus],
  );

  useEffect(() => {
    if (authStatus === 'idle') {
      if (ssoToken != null && auth.userData != null && !auth.isLoading) {
        void loadUserMe('sso');
      } else if (token != null) {
        void loadUserMe('vantage');
      }
    }
  }, [auth, auth.isLoading, authStatus, loadUserMe, ssoToken, token]);
};
