import {useCallback} from 'react';

import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {useEventStore} from '../../common/stores/eventStore';
import {useNavigationStore} from '../../common/stores/navigationStore';

export const useEventsPaneSearchButtons = () => {
  const toggleNavigationsDrawer = useNavigationStore(
    (state) => state.toggleNavigationsDrawer,
  );
  const closeEventsDrawer = useEventStore((state) => state.closeEventsDrawer);
  const navigate = useNavigate();
  return useCallback(() => {
    toggleNavigationsDrawer(false);
    closeEventsDrawer();
    navigate(routes.eventReview.base);
  }, [toggleNavigationsDrawer, closeEventsDrawer, navigate]);
};
