import {useCallback, useMemo} from 'react';

import {useAuth} from 'oidc-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';

import {useCheckUnselectNoIssue} from './useCheckUnselectNoIssue';
import {useFormValues} from './useFormValues';
import {routes} from '../../../routes';
import {useNavigationStore} from '../../common/stores/navigationStore';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {
  enhanceFormData,
  hasOnlySoundBehaviours,
  processLookupObject,
  shouldSkipEscalation,
} from '../utils/feedbackUtils';

export const useEventReviewBottomBar = () => {
  const {
    formRef,
    webSocket,
    setShowOnBreakModal,
    resetContext,
    behaviours,
    handleLateClip,
    handleFaultyClip,
    handleNoIssue,
    notificationComments,
    hindsightBehaviourOutcomeData,
    lookupTableData,
  } = useEventReviewContext();

  const navigate = useNavigate();
  const auth = useAuth();
  const formValues = useFormValues(formRef);
  const navigationDrawerOpen = useNavigationStore(
    (state) => state.navigationDrawerOpen,
  );
  const [searchParams] = useSearchParams();

  useCheckUnselectNoIssue({
    formRef,
    behaviours,
  });

  const nextEventAvailable = useMemo(
    () => webSocket.events.length > 1,
    [webSocket.events.length],
  );

  const emptyForm = useMemo(
    () => !Object.values(formValues).some(Boolean),
    [formValues],
  );

  const cleanedKeys = useMemo(
    () => processLookupObject(formValues),
    [formValues],
  );
  const onlySoundSelected = hasOnlySoundBehaviours(cleanedKeys, behaviours);

  const noIssueSelected = useMemo(
    () => formValues['__lookupKey: n__No Issue'],
    [formValues],
  );

  const noIssueDisabled = useMemo(
    () => !onlySoundSelected && !noIssueSelected && !emptyForm,
    [noIssueSelected, onlySoundSelected, emptyForm],
  );

  const nextDisabled = useMemo(
    () => emptyForm || (onlySoundSelected && !noIssueSelected),
    [emptyForm, onlySoundSelected, noIssueSelected],
  );

  const faultyClipSelected = useMemo(
    () => formValues['__lookupKey: ce__Clip Error'],
    [formValues],
  );

  const lateClipSelected = useMemo(
    () =>
      notificationComments.length > 0 &&
      notificationComments[0].text.includes('Late Clip'),
    [notificationComments],
  );

  const navigateBackToVideo = useCallback(() => {
    navigate(routes.eventReview.review);
  }, [navigate]);

  const handleNavigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSessionPause = useCallback(async () => {
    try {
      const filteredValues = Object.fromEntries(
        Object.entries(formValues).filter(([, value]) => value),
      );

      const {ids} = enhanceFormData(
        filteredValues,
        lookupTableData.behaviorLookupTable,
        lookupTableData.observationLookupTable,
      );

      const skipEscalation =
        shouldSkipEscalation(behaviours ?? [], ids) || lateClipSelected;

      if (skipEscalation || searchParams.get('notifications') != null) {
        setShowOnBreakModal(true);

        webSocket.queueReview(
          formValues,
          lookupTableData,
          auth,
          notificationComments,
          new Date(),
          true,
        );

        navigate(routes.eventReview.base);
        webSocket.setEvents([]);
        resetContext();
      } else {
        formRef?.current?.submitForm();
      }
    } catch {
      toast('Error pausing session', {type: 'error'});
    }
  }, [
    behaviours,
    formRef,
    searchParams,
    setShowOnBreakModal,
    navigate,
    webSocket,
    resetContext,
    auth,
    formValues,
    lookupTableData,
    notificationComments,
    lateClipSelected,
  ]);

  return {
    handleLateClip,
    navigationDrawerOpen,
    navigateBackToVideo,
    handleNavigateBack,
    handleNoIssue,
    handleSessionPause,
    noIssueDisabled,
    nextEventAvailable,
    feedbackFormRef: formRef,
    handleFaultyClip,
    faultyClipSelected,
    noIssueSelected,
    lateClipSelected,
    emptyForm,
    nextDisabled,
    hindsightBehaviourOutcomeData,
  };
};
