import {memo} from 'react';

import {lighten, styled, Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {UseDateRangeReturnType} from './useDateRange';
import {getDaysInMonth, isCurrentDay, isOnSameDay} from './utils';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface CalendarGridProps
  extends Pick<
    UseDateRangeReturnType,
    | 'tempRange'
    | 'isInRange'
    | 'selectionMode'
    | 'handleDateClick'
    | 'onHoverChange'
  > {
  month: Date;
}
const WEEKDAYS = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'] as const;

export const CalendarGrid = memo(function CalendarGrid({
  month,
  tempRange,
  isInRange,
  selectionMode,
  handleDateClick,
  onHoverChange,
}: CalendarGridProps) {
  return (
    <DaysGrid>
      {WEEKDAYS.map((day) => (
        <Typography
          key={day}
          color="text.secondary"
          textAlign="center"
          fontWeight={500}
          p={1}
        >
          {day}
        </Typography>
      ))}
      {getDaysInMonth(month).map((date, index) => (
        <DayCell
          key={date?.getTime() ?? index}
          isHidden={!date}
          isStart={isOnSameDay(date, tempRange.startDate)}
          isEnd={isOnSameDay(date, tempRange.endDate)}
          isInRange={date && isInRange(date)}
          selectionMode={selectionMode}
          isCurrentDay={date && isCurrentDay(date)}
          onMouseEnter={() => onHoverChange(date)}
          onMouseLeave={() => onHoverChange(null)}
          onClick={() => date && handleDateClick(date)}
        >
          {date?.getDate()}
        </DayCell>
      ))}
    </DaysGrid>
  );
});

const DaysGrid = styled('div')(({theme}) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  rowGap: theme.spacing(0.4),
}));

interface DayCellProps {
  isStart?: boolean | null;
  isEnd?: boolean | null;
  isInRange?: boolean | null;
  isHidden?: boolean | null;
  isCurrentDay?: boolean | null;
  selectionMode?: 'start' | 'end';
}

const getBorderRadius = ({
  isStart,
  isEnd,
  isCurrentDay,
  isInRange,
}: DayCellProps) => {
  if (isStart && isEnd) return '16px';
  if (isStart) return '16px 0 0 16px';
  if (isEnd) return '0 16px 16px 0';
  if (isInRange) return '0';
  if (isCurrentDay) return '8px';
  return '8px';
};

const getBackgroundColor = ({
  isStart,
  isEnd,
  isInRange,
  theme,
}: DayCellProps & {theme: Theme}) => {
  if (isStart || isEnd) return theme.palette.primary.main;
  if (isInRange) return lighten(theme.palette.primary.light, 0.8);
  return 'transparent';
};

const getColor = ({isStart, isEnd, theme}: DayCellProps & {theme: Theme}) => {
  if (isStart || isEnd) {
    return theme.palette.primary.contrastText;
  }
  return theme.palette.text.primary;
};

const getBorderColor = ({
  isStart,
  isEnd,
  isCurrentDay,
  theme,
  selectionMode,
}: DayCellProps & {theme: Theme}) => {
  if (
    (selectionMode === 'start' && isStart) ||
    (selectionMode === 'end' && isEnd)
  ) {
    return theme.palette.text.secondary;
  }
  if (isCurrentDay && !isStart && !isEnd) {
    return theme.palette.text.disabled;
  }
  return 'transparent';
};

const DayCell = styled('div', {
  shouldForwardProp: addShouldNotForwardProps(
    'isStart',
    'isEnd',
    'isInRange',
    'isHidden',
    'isCurrentDay',
    'selectionMode',
  ),
})<DayCellProps>((props) => ({
  padding: props.theme.spacing(0.5, 1),
  textAlign: 'center',
  cursor: 'pointer',
  width: '100%',
  borderRadius: getBorderRadius(props),
  visibility: props.isHidden ? 'hidden' : 'visible',
  backgroundColor: getBackgroundColor(props),
  color: getColor(props),
  border: `2px solid ${getBorderColor(props)}`,
  '&:hover': {
    borderColor: props.theme.palette.primary.main,
  },
}));
