import {useCallback, useEffect, useRef, useState} from 'react';

import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {
  type LytxPreEventData,
  type SurfSightEventData,
  type WebSocketEvents,
} from '../WebsocketTypes';
import {usePreloadVideoEventDetails} from './usePreloadVideoEventDetails';

export const useReviewEvent = ({
  event,
}: {
  event: WebSocketEvents | SurfSightEventData | LytxPreEventData;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const currentTimeRef = useRef<number>(currentTime);
  const [duration, setDuration] = useState<number>(0);
  const [playing, setPlaying] = useState<boolean>(false);
  const [chipListValues, setChipListValues] = useState<
    Array<string | undefined>
  >([]);
  const navigate = useNavigate();
  const {
    setSnapshotList,
    setOnEventSelectedShortcuts,
    setKeyBuffer,
    keyBuffer,
    handleFaultyClip,
    handleNoIssue,
    lookupTableData,
  } = useEventReviewContext();

  const handleLoadedMetadata = useCallback(async () => {
    if (videoRef.current != null) {
      videoRef.current.playbackRate = 1.5;
      await videoRef.current.play();
      setDuration((prev) => Math.max(prev, videoRef?.current?.duration ?? 0));
    }
  }, [setDuration, videoRef]);

  const handleTimeUpdate = useCallback(() => {
    setCurrentTime(videoRef?.current?.currentTime ?? 0);
  }, [setCurrentTime, videoRef]);

  const handleSliderChange = useCallback(
    (_event: Event, newTimeValue: number | number[]) => {
      if (Array.isArray(newTimeValue)) return;
      setCurrentTime(newTimeValue);

      if (videoRef.current != null) {
        videoRef.current.currentTime = newTimeValue;
      }
    },
    [setCurrentTime, videoRef],
  );

  const togglePlay = useCallback(async () => {
    try {
      if (videoRef.current != null) {
        if (playing) {
          videoRef.current.pause();
        } else {
          await videoRef.current.play();
        }
      }
      setPlaying(!playing);
    } catch {
      //
    }
  }, [playing, videoRef, setPlaying]);

  const handleVideoEnd = useCallback(() => {
    setTimeout(() => {
      navigate(routes.eventReview.feedback);
    }, 500);
  }, [navigate]);

  const handleKeyBuffer = useCallback(() => {
    if (keyBuffer !== '') {
      if (keyBuffer === 'ce') {
        handleFaultyClip();
        setKeyBuffer('');
        return;
      }

      if (keyBuffer === 'n') {
        handleNoIssue();
        setKeyBuffer('');
        setChipListValues([]);
        return;
      }

      const checkbox = lookupTableData.combinedLookup[keyBuffer];
      if (checkbox != null) {
        setOnEventSelectedShortcuts((prev) =>
          prev.includes(keyBuffer)
            ? prev.filter((item) => item !== keyBuffer)
            : [...prev, keyBuffer],
        );

        setChipListValues((prev) =>
          prev.includes(checkbox.name)
            ? prev.filter((item) => item !== checkbox.name)
            : [...prev, checkbox.name],
        );
      }
      setKeyBuffer('');
      setTimeout(() => {
        setKeyBuffer('');
      }, 555);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keyBuffer,
    setKeyBuffer,
    setOnEventSelectedShortcuts,
    handleNoIssue,
    handleFaultyClip,
  ]);

  const handleFullscreen = useCallback(async () => {
    await videoRef?.current?.requestFullscreen();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const {signal} = abortController;
    const handleKeyPress = (keyboardEvent: KeyboardEvent) => {
      const key = keyboardEvent.key.toLowerCase();
      setKeyBuffer((prev) => prev + key);
    };

    const bufferTimeout = setTimeout(handleKeyBuffer, 300);

    window.addEventListener('keydown', handleKeyPress, {signal});

    return () => {
      abortController.abort();
      clearTimeout(bufferTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBuffer, handleKeyBuffer]);

  const handleSpacebarPress = useCallback(
    (keyboardEvent: KeyboardEvent) => {
      if (keyboardEvent.code === 'Space') {
        setSnapshotList((prevList) => [...prevList, currentTimeRef.current]);
      }
    },
    [setSnapshotList],
  );

  const handleContextMenu = useCallback((e: MouseEvent) => {
    e.preventDefault();
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const {signal} = abortController;

    window.addEventListener('keydown', handleSpacebarPress, {signal});
    window.addEventListener('contextmenu', handleContextMenu, {signal});

    return () => {
      abortController.abort();
    };
  }, [handleSpacebarPress, handleContextMenu]);

  usePreloadVideoEventDetails({event});

  return {
    videoRef,
    handleLoadedMetadata,
    handleTimeUpdate,
    handleSliderChange,
    togglePlay,
    handleVideoEnd,
    duration,
    currentTime,
    playing,
    setPlaying,
    chipListValues,
    handleFullscreen,
  };
};
