import {webMasterTripApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadTrips = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const isActive = useDateRangeStore((state) => state.isActive);

  return useLoadList(
    async (signal) =>
      await webMasterTripApi.apiWebMasterTripGet(
        {
          ...loadRequestPagination,
          orderBy: loadRequestSorting.orderBy ?? 'contractCode',
          ...(isActive
            ? {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
              }
            : {}),
          tripNumber: getFilter('tripNumber'),
          driverName: getFilter('driverName'),
          contractCode: getFilter('contractCode'),
          orderNumber: getFilter('orderNumber'),
          vehicleRegistrationNumber: getFilter('vehicleRegistrationNumber'),
          upliftNodeName: getFilter('upliftNodeName'),
          offloadNodeName: getFilter('offloadNodeName'),
          fleetNumber: getFilter('fleetNumber'),
          status: getFilter('status'),
          createdDate: getFilter('createdDate') as Date | undefined,
        },
        {signal},
      ),
    [
      getFilter,
      loadRequestPagination,
      loadRequestSorting,
      startDate,
      endDate,
      isActive,
    ],
    {updatePagination},
  );
};
