import {nodeApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export interface LoadListProps extends UseLoadParams {
  nodeId: number;
}

export const useLoadSiteSubSites = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
  nodeId,
}: LoadListProps) => {
  return useLoadList(
    async (signal) =>
      await nodeApi.apiNodeNodeIdSubNodeGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          nodeId,
          name: getFilter('name'),
          description: getFilter('description'),
          externalReference: getFilter('externalReference'),
          taskTemplateName: getFilter('taskTemplateName'),
        },
        {signal},
      ),
    [nodeId, getFilter, loadRequestPagination, loadRequestSorting],
    {updatePagination},
  );
};
