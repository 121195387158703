import {type ReactNode} from 'react';

import {useAuth} from 'oidc-react';

import {AuthStateMessage} from './AuthStateMessage';
import {useAuthStore} from '../../common/stores/authStore';

interface AuthStateHandlerProps {
  children?: ReactNode;
}

export function AuthStateHandler({children}: AuthStateHandlerProps) {
  const auth = useAuth();
  const authStatus = useAuthStore((state) => state.authStatus);

  if (authStatus === 'authenticating' || auth.isLoading) {
    return <AuthStateMessage message="Logging you in" status="loading" />;
  }

  if (authStatus === 'authenticated' && auth.isLoading) {
    return (
      <AuthStateMessage message="Successfully logged in!" status="success" />
    );
  }

  if (
    authStatus === 'unauthenticated' ||
    (auth.userData?.expired === true && !auth.isLoading)
  ) {
    return <AuthStateMessage message="Failed to log you in" status="error" />;
  }

  return (
    children ?? <AuthStateMessage message="Logging you in" status="loading" />
  );
}
