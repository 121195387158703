import {useMemo} from 'react';

import {useParams} from 'react-router-dom';

import {PageLoader} from '../../common/components/loader/PageLoader';
import {VantageTab} from '../../common/components/tabs/VantageTab';
import {VantageTabs} from '../../common/components/tabs/VantageTabs';
import {VantageCard} from '../../common/components/VantageCard';
import {useTabs} from '../../common/hooks/useTabs';
import {SiteDetail} from '../components/SiteDetail';
import {SitePostSites} from '../components/SitePostSites';
import {SitePreSites} from '../components/SitePreSites';
import {SiteSubSites} from '../components/SiteSubSites';
import {useLoadSite} from '../hooks/useLoadSite';

const siteTabs = ['Details', 'Sub sites'] as const;
const defaultSiteTabs = ['Pre sites', 'Post sites'] as const;
type SiteTab = (typeof siteTabs)[number];
type DefaultSiteTab = (typeof defaultSiteTabs)[number];

export default function Site() {
  const params = useParams();

  if (params.nodeId == null) {
    throw new Error('siteId is required');
  }

  const {loadStatus, item, id, updateState} = useLoadSite(
    parseInt(params.nodeId, 10),
  );

  const tabs = useTabs<SiteTab | DefaultSiteTab>('Details');
  const {activeTab} = tabs;

  const isDefaultSite = item?.nodeType?.name?.toLowerCase() === 'default';
  const isOffloadingSite = item?.nodeType?.name?.toLowerCase() === 'offloading';
  const isLoadingSite = item?.nodeType?.name?.toLowerCase() === 'loading';

  const renderActiveTab = useMemo(() => {
    switch (activeTab) {
      case 'Details':
        return <SiteDetail id={id} item={item} updateState={updateState} />;
      case 'Sub sites':
        return <SiteSubSites />;
      case 'Pre sites':
        if (isDefaultSite || isLoadingSite || isOffloadingSite) {
          return <SitePreSites id={id} item={item} />;
        }
        return <SiteSubSites />;
      case 'Post sites':
        if (isDefaultSite || isLoadingSite || isOffloadingSite) {
          return <SitePostSites id={id} item={item} />;
        }
        return <SiteSubSites />;
      default:
        return null;
    }
  }, [
    activeTab,
    isDefaultSite,
    isLoadingSite,
    isOffloadingSite,
    id,
    item,
    updateState,
  ]);

  if (loadStatus === 'idle') {
    return <PageLoader />;
  }

  if (loadStatus === 'loading') {
    return <PageLoader label="Loading site..." />;
  }

  return (
    <VantageCard scrollable>
      <VantageTabs {...tabs}>
        {siteTabs.map((tab) => (
          <VantageTab key={tab} value={tab} label={tab} />
        ))}
        {(isDefaultSite || isLoadingSite || isOffloadingSite) &&
          defaultSiteTabs.map((tab) => (
            <VantageTab key={tab} value={tab} label={tab} />
          ))}
      </VantageTabs>
      {renderActiveTab}
    </VantageCard>
  );
}
