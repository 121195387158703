import type {ComponentType} from 'react';

import type {TableRowDetail as TableRowDetailBase} from '@devexpress/dx-react-grid';

import {
  type VantageTableActions,
  type VantageTableRowActions,
} from '../VantageTable';

export const useVantageTableActionsColumn = <TableData extends object>(
  actions: VantageTableActions<TableData> | null | undefined,
  rowActions: VantageTableRowActions<TableData> | null | undefined,
  rowDetail: ComponentType<TableRowDetailBase.ContentProps> | null | undefined,
) => {
  const actionsLength = Object.keys(actions ?? {}).length;
  const rowActionsLength = Object.keys(rowActions ?? {}).length;
  const amountOfActions =
    Math.max(actionsLength, rowActionsLength) + (rowDetail != null ? 1 : 0);

  if (amountOfActions === 0 && rowDetail != null) {
    return 62;
  }

  const widthMapping: {[key: number]: number; fallback: number} = {
    1:
      rowActions?.onInlineEdit != null || actions?.onInlineAdd != null
        ? 157
        : actions?.onInlineEdit != null
          ? 100 // TEMP SOLUTION FOR AUDIENCE BEHAVIOURS
          : 62,
    2: 94,
    3: 125,
    fallback: 157, // Generic fallback width
  };

  return widthMapping[amountOfActions] ?? widthMapping.fallback;
};
