import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {useSearchParams} from 'react-router-dom';

import {config} from '../../../config';
import {VantageForm} from '../../common/components/form/VantageForm';
import {BehaviourOutcomesChipList} from '../components/BehaviourOutcomesChipList';
import {Behaviours} from '../components/feedback/Behaviours';
import {NotificationContacts} from '../components/feedback/contacts/NotificationContacts';
import {EventDetails} from '../components/feedback/EventDetails';
import {Observations} from '../components/feedback/Observations';
import {VideoSnapshots} from '../components/feedback/VideoSnapshots';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {useCheckboxSelectOnKey} from '../hooks/useCheckboxSelectOnKey';
import {useEventFeedback} from '../hooks/useEventFeedback';

interface Category {
  id?: number;
  name?: string;
}

interface Data {
  behaviourCategory?: Category;
  behaviourType?: Category;
  id?: number;
  name?: string;
  shortcut?: string;
}

export type TableLookup = Record<string, Data>;

export type CategoryWithData<T> = [string, T[]];

export default function EventFeedback() {
  const {
    formRef,
    snapshotList,
    onEventSelectedShortCuts,
    lookupTableData,
    inlineEventData,
  } = useEventReviewContext();
  const {hindsightBehaviourOutcomeData, onSubmit, addFormValueToChecklist} =
    useEventFeedback();
  const [searchParams] = useSearchParams();

  useCheckboxSelectOnKey({
    lookupTable: lookupTableData.combinedLookup,
  });

  const preSelectedFromValues = [
    ...Object.values(lookupTableData.behaviorLookupTable),
  ]
    .concat(Object.values(lookupTableData.observationLookupTable))
    .reduce<Record<string, boolean>>((acc, checkbox) => {
      if (checkbox.shortcut == null || checkbox.name == null) {
        return {};
      }
      if (onEventSelectedShortCuts.includes(checkbox.shortcut)) {
        acc[`__lookupKey: ${checkbox.shortcut}__${checkbox.name}`] = true;
      }
      return acc;
    }, {});

  return (
    <Stack
      m={config.theme.gutterSpacing}
      mt={0}
      sx={{overflowY: 'auto', overflowX: 'hidden'}}
    >
      <EventDetails inlineEventData={inlineEventData} />
      <VantageForm
        FormProps={{
          style: {
            marginBottom: snapshotList.length > 0 ? '20px' : '100px',
          },
        }}
        formInnerRef={formRef}
        initialValues={preSelectedFromValues}
        onSubmit={onSubmit}
      >
        {searchParams.get('notifications') == null ? (
          <Grid container spacing={2} mt="0">
            <Observations
              observations={
                lookupTableData.categorizedObservationsWithShortcuts
              }
              addFormValueToChecklist={addFormValueToChecklist}
            />
            <Behaviours
              behaviours={lookupTableData.categorizedBehaviorsWithShortcuts}
              addFormValueToChecklist={addFormValueToChecklist}
            />
          </Grid>
        ) : (
          <>
            <BehaviourOutcomesChipList
              outcomes={hindsightBehaviourOutcomeData?.behaviourOutcomes}
            />
            <NotificationContacts
              contacts={hindsightBehaviourOutcomeData?.notifications}
            />
          </>
        )}
      </VantageForm>
      {searchParams.get('notifications') == null ? <VideoSnapshots /> : null}
    </Stack>
  );
}
