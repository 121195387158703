import {lazy, Suspense} from 'react';

import {ErrorBoundary, wrapCreateBrowserRouterV7} from '@sentry/react';
import {
  createBrowserRouter,
  LoaderFunctionArgs,
  redirect,
} from 'react-router-dom';

import {authRoutes} from '../modules/auth/authRoutes';
import {AppShell} from '../modules/common/components/appShell/AppShell';
import {PageLoader} from '../modules/common/components/loader/PageLoader';
import {useAuthStore} from '../modules/common/stores/authStore';
import {useBreadcrumbStore} from '../modules/common/stores/breadcrumbsStore';
import {routes} from '../routes';
import {authedRoutes} from './authedRoutes';
import {ErrorBoundaryFallback} from './ErrorBoundaryFallback';

const LazyMasterTripRecipientController = lazy(
  async () =>
    await import(
      '../modules/recipient/recipientMasterTrip/MasterTripRecipientController'
    ),
);
const LazyNotFound = lazy(async () => await import('./NotFound'));
const LazyService = lazy(
  async () => await import('../modules/selfService/pages/SelfServiceExternal'),
);
const LazyOrderRecipientController = lazy(
  async () =>
    await import(
      '../modules/recipient/recipientOrder/OrderRecipientController'
    ),
);
const LazyDashboardDataStudio = lazy(
  async () => await import('../modules/dashboard/pages/DashboardDataStudio'),
);
const LazyDashboardNative = lazy(
  async () => await import('../modules/dashboard/pages/DashboardNative'),
);

const sentryCreateBrowserRouter =
  wrapCreateBrowserRouterV7(createBrowserRouter);
export const router = sentryCreateBrowserRouter([
  {path: routes.login, loader: () => redirect(routes.auth.login)},
  {
    path: routes.auth.base,
    ErrorBoundary,
    children: authRoutes,
  },
  {
    path: routes.trip.recipient,
    ErrorBoundary,
    element: <LazyMasterTripRecipientController />,
  },
  {
    path: routes.order.recipient,
    ErrorBoundary,
    element: <LazyOrderRecipientController />,
  },
  {
    path: routes.dashboardDataStudio,
    element: <LazyDashboardDataStudio />,
    ErrorBoundary,
    loader: async ({request}: LoaderFunctionArgs) => {
      document.title = 'Onroad Vantage Dashboard';
      useBreadcrumbStore.setState({
        breadcrumbs: [],
      });

      if (useAuthStore.getState().token != null) {
        return null;
      }

      const searchParams = new URLSearchParams(request.url);
      const accessToken = searchParams.get('accessToken');

      if (accessToken != null) {
        useAuthStore.setState({
          token: accessToken,
        });
        return redirect(request.url.replace(`&accessToken=${accessToken}`, ''));
      }
      return redirect(
        `${routes.auth.base}?redirect=${window.location.pathname}`,
      );
    },
  },
  {
    path: routes.dashboardStandalone,
    ErrorBoundary,
    element: <LazyDashboardNative />,
    loader: async ({request}: LoaderFunctionArgs) => {
      document.title = 'Onroad Vantage Dashboard';
      useBreadcrumbStore.setState({
        breadcrumbs: [],
      });

      if (useAuthStore.getState().token != null) {
        return null;
      }

      const searchParams = new URLSearchParams(request.url);
      const accessToken = searchParams.get('accessToken');

      if (accessToken != null) {
        useAuthStore.setState({
          token: accessToken,
        });
        return redirect(request.url.replace(`&accessToken=${accessToken}`, ''));
      }
      return redirect(
        `${routes.auth.base}?redirect=${window.location.pathname}`,
      );
    },
  },
  {
    path: routes.selfService.external,
    element: (
      <Suspense fallback={<PageLoader />}>
        <LazyService />
      </Suspense>
    ),
  },
  {
    path: '/',
    element: (
      <ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
        <AppShell />
      </ErrorBoundary>
    ),
    loader: async () => {
      const oldAuthStore = localStorage.getItem('AuthStore');
      if (oldAuthStore != null) {
        localStorage.clear();
        // New vantage
        return redirect(
          routes.addQueryParams(routes.auth.base, {
            redirect: window.location.pathname,
            reason: 'newVantage',
          }),
        );
      }

      useBreadcrumbStore.setState({
        breadcrumbs: [],
      });
      const {token, ssoToken, authStatus, reset} = useAuthStore.getState();
      if (
        (token == null && ssoToken == null) ||
        authStatus === 'unauthenticated'
      ) {
        if (token != null) {
          reset();
        }
        return redirect(
          routes.addQueryParams(routes.auth.login, {
            redirect: window.location.pathname,
            reason: 'unauthorized',
          }),
        );
      }
      return null;
    },
    children: authedRoutes,
  },
  {
    path: '*',
    ErrorBoundary,
    element: (
      <ErrorBoundary fallback={ErrorBoundaryFallback} showDialog>
        <AppShell>
          <LazyNotFound />
        </AppShell>
      </ErrorBoundary>
    ),
  },
]);
