import MailIcon from '@mui/icons-material/Mail';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import {useTheme} from '@mui/material/styles';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {PostReviewDetailsNotificationsBehaviourOutcomesResponse} from '@onroadvantage/onroadvantage-api';

export interface NotificationFlags {
  sms: boolean;
  email: boolean;
  phone: boolean;
}

interface NotificationRowProps
  extends Partial<
    Pick<
      PostReviewDetailsNotificationsBehaviourOutcomesResponse,
      'name' | 'phone' | 'email'
    >
  > {
  onCallClicked: (contactName: string) => void;
  notificationType?: string[];
}

export function NotificationContactRow({
  name,
  phone,
  email,
  notificationType,
  onCallClicked,
}: NotificationRowProps) {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle1">{name}</Typography>
      </TableCell>
      <TableCell>{phone}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell align="right">
        <Link
          href={`tel:${phone}`}
          onClick={() => onCallClicked(name ?? '')}
          sx={{
            color: notificationType?.includes('phone')
              ? theme.palette.success.main
              : 'rgba(0, 0, 0, 0.10)',
            padding: '5px',
            position: 'relative',
            top: '8px',
            ...(!notificationType?.includes('phone') && {
              pointerEvents: 'none',
            }),
          }}
          aria-label="phone"
        >
          <PhoneIcon />
        </Link>

        <IconButton
          size="small"
          aria-label="email"
          disabled={!notificationType?.includes('email')}
          sx={{
            color: notificationType?.includes('email')
              ? 'rgba(0, 0, 0, 0.60)'
              : 'rgba(0, 0, 0, 0.10)',
          }}
        >
          <MailIcon />
        </IconButton>

        <IconButton
          size="small"
          aria-label="sms"
          disabled={!notificationType?.includes('sms')}
          sx={{
            color: notificationType?.includes('sms')
              ? 'rgba(0, 0, 0, 0.60)'
              : 'rgba(0, 0, 0, 0.10)',
          }}
        >
          <MessageIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
