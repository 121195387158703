import {lazy} from 'react';

import {type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyOrganisations = lazy(async () => import('./pages/Organisations'));
const LazyOrganisationDetails = lazy(
  async () => import('./pages/OrganisationDetails'),
);
const LazyOrganisationSubgroupDetails = lazy(
  async () => import('./pages/OrganisationSubgroupDetails'),
);

export const organisationsRoutes: RouteObject[] = [
  {
    path: routes.organisations.base,
    element: <LazyOrganisations />,
    loader: () => {
      document.title = 'Organisations';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Organisations'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.organisations.details,
      routes.organisations.base,
    ),
    element: <LazyOrganisationDetails />,
    loader: () => {
      document.title = 'Organisation Details';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Organisations', path: routes.organisations.base},
          {label: 'Organisation Details'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.organisations.subGroups,
      routes.organisations.base,
    ),
    element: <LazyOrganisationSubgroupDetails />,
    loader: () => {
      document.title = 'Organisation Subgroup Details';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Organisations', path: routes.organisations.base},
          {label: 'Organisation Subgroup Details'},
        ],
      });
      return null;
    },
  },
];
