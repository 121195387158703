import {webMasterRouteApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import type {UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadMasterRoutes = ({
  getFilter,
  updatePagination,
  loadRequestPagination,
  loadRequestSorting,
}: UseLoadParams) => {
  return useLoadList(
    async (signal) =>
      await webMasterRouteApi.apiWebMasterRouteGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          name: getFilter('name'),
          fromNodeName: getFilter('fromNodeName'),
          toNodeName: getFilter('toNodeName'),
          contractCode: getFilter('contractCode'),
          masterTripPrefix: getFilter('masterTripPrefix'),
        },
        {signal},
      ),
    [getFilter, loadRequestSorting, loadRequestPagination],
    {updatePagination},
  );
};
