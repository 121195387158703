import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {WebNodeListDump} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {DateUtil} from '../../common/utils/DateUtil';
import {useDeleteSite} from '../hooks/useDeleteSite';
import {useDownloadSites} from '../hooks/useDownloadSites';
import {useLoadSites} from '../hooks/useLoadSites';

const tableConfig: VantageTableConfig<WebNodeListDump> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'externalReference',
      label: 'External Ref',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'type',
      label: 'Type',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'latitude',
      label: 'Latitude',
      type: 'number',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'longitude',
      label: 'Longitude',
      type: 'number',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'loadingTimeInMinutes',
      label: 'Loading Time',
      type: 'number',
      enableFilter: true,
      enableSort: true,
      hidden: true,
    },
    {
      name: 'unloadingTimeInMinutes',
      label: 'Unloading Time',
      type: 'number',
      enableFilter: true,
      enableSort: true,
      hidden: true,
    },
    {
      name: 'processingTimeInMinutes',
      label: 'Processing Time',
      type: 'number',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'openTimes',
      label: 'Open Times',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      getValue: ({timeWindows}) =>
        timeWindows?.map((timeWindow, index) => (
          <Chip
            key={index}
            label={`${DateUtil.fromServerTimeToLocalTime(
              timeWindow.openTime,
            )} - ${DateUtil.fromServerTimeToLocalTime(timeWindow.closeTime)}`}
          />
        )),
    },
    {
      name: 'telematicsConfigId',
      label: 'Telematics Config',
      type: 'number',
      hidden: true,
      getValue: ({telematicsConfig}) => telematicsConfig?.id,
    },
    {
      name: 'providerName',
      label: 'Provider Name',
      type: 'string',
      hidden: true,
      getValue: ({telematicsConfig}) => telematicsConfig?.providerName,
    },
    {
      name: 'mixOrganisationGroupName',
      label: 'Group Name',
      type: 'string',
      hidden: true,
      getValue: ({telematicsConfig}) =>
        telematicsConfig?.mixOrganisationGroupName,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>External Reference:</Typography>
        <Typography>{row.externalReference}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnGrouping: true,
  enableColumnResizing: true,
  enableColumnVisibility: true,
  identifier: 'sites',
};

export default function Sites() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {reloadData, data, removeItem} = useLoadSites({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete} = useDeleteSite({removeItem});
  const {onDownload, downloadStatus} = useDownloadSites();

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={downloadStatus === 'downloading'}
        label="Downloading sites..."
      />
      <VantageTable
        rowId="id"
        config={tableConfig}
        data={data}
        toolbar={{onRefresh: reloadData}}
        actions={{
          onAdd: hasPermissions('site:add')
            ? () => {
                navigate(routes.site.add);
              }
            : undefined,
          onDownload,
        }}
        rowActions={{
          onNavigate: ({id}) =>
            id
              ? routes.replaceParams(routes.site.nodeId, {
                  nodeId: id,
                })
              : null,
          onDelete: hasPermissions('site:delete') ? onDelete : undefined,
        }}
        grouping={[]}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
      />
    </VantageTablePaper>
  );
}
