import {useCallback, useEffect, useMemo} from 'react';

import {toast} from 'react-toastify';

import {earlyWarningEventApi} from '../../../api';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {isActiveLytxEvent} from '../utils/eventDistinctions';
import {
  type LytxPreEventData,
  type SurfSightEventData,
  type WebSocketEvents,
} from '../WebsocketTypes';

export const usePreloadVideoEventDetails = ({
  event,
}: {
  event: WebSocketEvents | SurfSightEventData | LytxPreEventData;
}) => {
  const {setInlineEventData, setEventDetailsData} = useEventReviewContext();
  const {groupId, vehicleId, triggerId} = useMemo(() => {
    if (isActiveLytxEvent(event)) {
      const eventData = event?.data?.data;
      return {
        groupId: eventData?.groupId,
        vehicleId: eventData?.vehicleId,
        triggerId: eventData?.eventTriggerId,
      };
    }
    return {};
  }, [event]);

  const fetchVideoEventData = useCallback(async () => {
    try {
      const eventDetails =
        await earlyWarningEventApi.apiEarlyWarningEventDetailsGet({
          eventTriggerId: triggerId,
          vehicleId,
          groupId,
          telematicsProviderName: 'Lytx',
        });

      if (eventDetails?.organisationId != null) {
        setEventDetailsData({group: {id: eventDetails.organisationId}});
      }

      if (eventDetails != null) {
        setInlineEventData(eventDetails);
      }
    } catch {
      toast('Error fetching event details', {type: 'error'});
    }
  }, [groupId, setEventDetailsData, setInlineEventData, triggerId, vehicleId]);

  useEffect(() => {
    if (groupId != null && vehicleId != null && triggerId != null) {
      void fetchVideoEventData();
    }
  }, [fetchVideoEventData, groupId, triggerId, vehicleId]);
};
