import {type RefObject, useCallback} from 'react';

export const useEventReviewWebsocketTimeout = ({
  websocket,
}: {
  websocket: RefObject<WebSocket | null>;
}) => {
  const startReviewTimer = useCallback(() => {
    // need to do a ping here until backend adds an update to keep the connection open
    websocket.current?.send(JSON.stringify({type: 'ping'}));
  }, [websocket]);

  return {startReviewTimer};
};
