import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type VehicleType} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {getTooltipTableColumnValues} from '../../common/helpers/getTooltipTableColumnValues';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteVehicleType} from '../hooks/useDeleteVehicleType';
import {useLoadVehicleTypes} from '../hooks/useLoadVehicleTypes';

const tableConfig: VantageTableConfig<VehicleType> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'string',
      enableGrouping: true,
      enableFilter: true,
      enableSort: true,
    },
    {name: 'externalId', label: 'External Id', type: 'string', hidden: true},
    {
      name: 'externalSiteId',
      label: 'External Site Id',
      type: 'string',
      hidden: true,
    },
    {
      name: 'externalGroupId',
      label: 'External Group Id',
      type: 'string',
      hidden: true,
    },
    {name: 'planningFixedCost', label: 'Fixed Cost', type: 'number'},
    {
      name: 'planningDistanceCost',
      label: 'Distance Cost',
      type: 'number',
      hidden: true,
    },
    {
      name: 'planningTimeCost',
      label: 'Time Cost',
      type: 'number',
      hidden: true,
    },
    {
      name: 'planningServiceCost',
      label: 'Service Cost',
      type: 'number',
      hidden: true,
    },
    {
      name: 'planningWaitCost',
      label: 'Wait Cost',
      type: 'number',
      hidden: true,
    },
    {
      name: 'skills',
      label: 'Skills',
      type: 'string',
      getValue: (row) =>
        getTooltipTableColumnValues(
          row.skills?.map((skill) => skill.name),
          2,
        ),
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Vehicle type name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Vehicle type description:</Typography>
        <Typography>{row.description}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  identifier: 'vehicleTypes',
};

export default function VehicleTypes() {
  const navigate = useNavigate();
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, removeItem, reloadData} = useLoadVehicleTypes({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete} = useDeleteVehicleType({removeItem});

  return (
    <VantageTablePaper>
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        toolbar={{onRefresh: reloadData}}
        actions={
          hasPermissions('vehicleType:add')
            ? {
                onAdd: () => {
                  navigate(routes.vehicleType.add);
                },
              }
            : {}
        }
        rowActions={{
          onNavigate: ({id}) =>
            id
              ? routes.replaceParams(routes.vehicleType.vehicleTypeId, {
                  vehicleTypeId: id,
                })
              : null,
          onDelete: hasPermissions('vehicleType:delete') ? onDelete : undefined,
        }}
      />
    </VantageTablePaper>
  );
}
