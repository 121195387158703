import {useCallback, useEffect, useState, useRef} from 'react';

import type {UpdatePagination} from './usePagination';
import {catchHandler} from '../helpers/catchHandler';
import {isObjectWithKeys} from '../helpers/unknownValueTypeChecks';
import {useLoadListStatusStore} from '../stores/loadingListStatusStore.ts';
import type {LoadStatus} from '../types/LoadStatus';

export type LoadResponse<T> = {
  items?: T[];
  pages?: number;
  total?: number;
};

export type LoadHandler<T> = (signal: AbortSignal) => Promise<LoadResponse<T>>;

export type UseLoadListOptions = {
  enabled?: boolean;
  updatePagination?: UpdatePagination;
};

export const useLoadList = <T>(
  loadHandler: LoadHandler<T>,
  deps: unknown[] = [],
  options: UseLoadListOptions = {},
) => {
  const {enabled = true, updatePagination} = options;
  const [status, setStatus] = useState<LoadStatus>('idle');
  const [data, setData] = useState<T[]>([]);
  const loadDataStatus = useLoadListStatusStore();

  const abortControllerRef = useRef<AbortController | null>(null);
  const timeoutRef = useRef<number | undefined>(undefined);
  const isInitialLoadRef = useRef<boolean>(false);

  const clearStatusTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);

  const cancelPreviousRequest = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
    clearStatusTimeout();
  }, [clearStatusTimeout]);

  const loadData = useCallback(
    async (reason: 'initial' | 'reload') => {
      cancelPreviousRequest();
      clearStatusTimeout();

      const controller = new AbortController();
      abortControllerRef.current = controller;

      const newStatus = reason === 'initial' ? 'loading' : 'reloading';
      setStatus(newStatus);
      useLoadListStatusStore.setState(newStatus);

      try {
        const response = await loadHandler(controller.signal);

        // Only proceed if this request wasn't aborted
        if (controller.signal.aborted) return;

        setData(response.items ?? []);

        if (
          updatePagination &&
          (response.pages !== undefined || response.total !== undefined)
        ) {
          updatePagination({
            pages: response.pages,
            total: response.total,
          });
        }
        setStatus('success');
        useLoadListStatusStore.setState('success');
        abortControllerRef.current = null;
        timeoutRef.current = setTimeout(() => {
          useLoadListStatusStore.setState('idle');
        }, 3000);
      } catch (error) {
        if (error instanceof DOMException && error.name === 'AbortError') {
          return;
        }

        const {type} = catchHandler(error, 'Failed to load data');
        setStatus(type);
        useLoadListStatusStore.setState(type);
        abortControllerRef.current = null;
        timeoutRef.current = setTimeout(() => {
          useLoadListStatusStore.setState('idle');
        }, 3000);
      } finally {
        isInitialLoadRef.current = true;
      }
    },
    [cancelPreviousRequest, clearStatusTimeout, loadHandler, updatePagination],
  );

  // Handle initial load and cleanup
  useEffect(() => {
    if (!enabled) {
      setData([]);
      setStatus('idle');
      useLoadListStatusStore.setState('idle');
      return cancelPreviousRequest;
    }

    void loadData('initial');

    return cancelPreviousRequest;
    // We only want this effect to run when enabled changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled]);

  // Handle dependency-based reloads
  useEffect(() => {
    if (isInitialLoadRef.current && enabled) {
      void loadData('reload');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps]);

  const removeItem = useCallback((id: number | string, key?: string) => {
    setData((prevData) => {
      return prevData.filter((item) => {
        if (key && isObjectWithKeys(item, key)) {
          return item[key] !== id;
        }
        if (isObjectWithKeys(item, 'id')) {
          return item.id !== id;
        }
        return true;
      });
    });
  }, []);

  return {
    data,
    setData,
    status: status,
    loadData,
    loadDataStatus,
    reloadData: () => loadData('reload'),
    removeItem,
  };
};
