import {nodeApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export interface LoadListProps extends UseLoadParams {
  nodeId: number;
}

export const useLoadSitePostSites = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
  nodeId,
}: LoadListProps) => {
  const {data, loadDataStatus, reloadData, setData} = useLoadList(
    async (signal) =>
      await nodeApi.apiNodeMainNodeIdAssociateNodeGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          mainNodeId: nodeId,
          isPostNode: true,
          taskTemplateNodeType: getFilter('taskTemplateNodeType'),
        },
        {signal},
      ),
    [getFilter, loadRequestPagination, loadRequestSorting],
    {updatePagination},
  );

  return {
    data,
    nodeId,
    loadDataStatus,
    reloadData,
    removeItem: (id: number | string) => {
      setData((prev) => prev.filter((item) => item.id !== id));
    },
  };
};
