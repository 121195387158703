import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {type InferType, object, string} from 'yup';

import {TripAddStopsTripDetails} from './TripAddStopsTripDetails';
import {
  listing,
  number,
  requiredListing,
} from '../../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {VantageTextField} from '../../../common/components/form/VantageTextField';
import {useTripAddDetailsContext} from '../../contexts/TripAddDetailsContext';

const tripAddStopsNewOrderValidationSchema = object({
  orderNumber: string().required('Required'),
  quantity: number.required('Required').min(1, 'Required'),
  deliveryNoteNumber: string(),
  purchaseOrder: listing,
  product: requiredListing,
  upliftPointNode: requiredListing,
  offloadPointNode: requiredListing,
  customer: listing.optional(),
});
export type TripAddStopsNewOrderValues = InferType<
  typeof tripAddStopsNewOrderValidationSchema
>;

interface TripAddStopsNewOrderProps {
  onSubmit: (values: TripAddStopsNewOrderValues) => void;
}

export function TripAddStopsNewOrder({onSubmit}: TripAddStopsNewOrderProps) {
  const {onPreviousStep} = useTripAddDetailsContext();
  return (
    <VantageForm<TripAddStopsNewOrderValues>
      initialValues={{
        orderNumber: '',
        quantity: 0,
        deliveryNoteNumber: undefined,
        purchaseOrder: {value: 0},
        product: {value: 0},
        upliftPointNode: {value: 0},
        offloadPointNode: {value: 0},
        customer: undefined,
      }}
      onSubmit={onSubmit}
      validationSchema={tripAddStopsNewOrderValidationSchema}
    >
      <Stack flex={1} alignItems="flex-start" direction="row">
        <VantageFormContent flex={1}>
          <VantageFormFieldsRow>
            <VantageTextField
              name="orderNumber"
              label="Order Number"
              placeholder="Enter the order number"
              formik
            />
            <VantageAutocompleteFieldWithListing
              name="purchaseOrder"
              label="Purchase Order"
              placeholder="Search and select purchase order number"
              models="PurchaseOrder"
              formik
            />
          </VantageFormFieldsRow>
          <VantageFormFieldsRow>
            <VantageAutocompleteFieldWithListing
              models="Node"
              name="upliftPointNode"
              label="Loading Point"
              placeholder="Enter and select the loading point"
              formik
            />
            <VantageAutocompleteFieldWithListing
              models="Node"
              name="offloadPointNode"
              label="Offloading Point"
              placeholder="Enter and select the offloading point"
              formik
            />
          </VantageFormFieldsRow>
          <VantageFormFieldsRow>
            <VantageAutocompleteFieldWithListing
              models="Product"
              name="product"
              label="Product"
              placeholder="Enter and select the product"
              formik
            />
            <VantageTextField
              name="quantity"
              label="Quantity"
              placeholder="Enter the quantity"
              formik
            />
          </VantageFormFieldsRow>
          <VantageFormFieldsRow>
            <VantageAutocompleteFieldWithListing
              name="customer"
              label="Customer"
              models="Customer"
              placeholder="Select a Customer"
              formik
            />
            <VantageTextField
              name="deliveryNoteNumber"
              label="Delivery note number"
              placeholder="Enter the delivery note number"
              formik
            />
          </VantageFormFieldsRow>
        </VantageFormContent>
        <TripAddStopsTripDetails />
      </Stack>
      <VantageFormActions>
        <Button color="inherit" variant="outlined" onClick={onPreviousStep}>
          Back
        </Button>
        <VantageFormSubmit label="Add order" />
      </VantageFormActions>
    </VantageForm>
  );
}
