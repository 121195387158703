import {useCallback, useEffect, useState} from 'react';

import type {NodeType} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import {nodeTypeApi} from '../../../api';
import type {LoadStatus} from '../../common/types/LoadStatus';
import type {UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadNodeTypes = ({
  getFilter,
  loadRequestPagination,
  updatePagination,
}: Pick<
  UseLoadParams,
  'getFilter' | 'loadRequestPagination' | 'updatePagination'
>) => {
  const [loadDataStatus, setLoadDataStatus] = useState<LoadStatus>('idle');
  const [data, setData] = useState<NodeType[]>([]);

  const loadData = useCallback(
    async (reason: 'initial' | 'reload') => {
      setLoadDataStatus(reason === 'reload' ? 'reloading' : 'loading');
      try {
        const response = await nodeTypeApi.apiNodeTypeGet({
          ...loadRequestPagination,
          name: getFilter('name'),
          description: getFilter('description'),
        });
        setData(response.items ?? []);
        setLoadDataStatus('success');

        if (updatePagination != null) {
          updatePagination({
            pages: response.pages,
            total: response.total,
          });
        }
      } catch {
        toast('Error loading site types', {type: 'error'});
        setLoadDataStatus('error');
      }
    },
    [getFilter, loadRequestPagination, updatePagination],
  );

  useEffect(() => {
    void loadData('initial');
    return () => {
      setData([]);
      setLoadDataStatus('idle');
    };
  }, [loadData]);

  return {
    data,
    loadDataStatus,
    reloadData: async () => {
      await loadData('reload');
    },
  };
};
