import {useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {type Node} from '@onroadvantage/onroadvantage-api';
import {type GeoJsonObject} from 'geojson';
import {geoJSON, latLng, type LatLngBounds} from 'leaflet';
import {boolean, date, type InferType, object, string} from 'yup';

import {SiteDetailsFields} from './SiteDetailsFields';
import {SiteMap} from './SiteMap';
import {
  listing,
  multipleListing,
  number,
} from '../../common/components/form/validationSchemas';
import {VantageForm} from '../../common/components/form/VantageForm';
import {VantageFormActions} from '../../common/components/form/VantageFormActions';
import {VantageFormReset} from '../../common/components/form/VantageFormReset';
import {VantageFormSubmit} from '../../common/components/form/VantageFormSubmit';
import {type EditProps} from '../../common/types/EditProps';
import {DateUtil} from '../../common/utils/DateUtil';
import {useEditSiteDetails} from '../hooks/useEditSiteDetails';

const validTimeMinutes = {
  match: /^\d{1,3}$/,
  message: 'Please enter a time in minutes',
};

const siteDetailsFormSchema = object({
  name: string().required('Required'),
  externalReference: string().required('Required'),
  address: string(),
  prohibitAfterHoursProcessing: boolean(),
  latitude: number.required('Required'),
  longitude: number.required('Required'),
  openTime: date().required('Required'),
  closeTime: date().required('Required'),
  loadingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  unloadingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  processingTimeInMinutes: string()
    .required('Required')
    .matches(validTimeMinutes.match, validTimeMinutes.message),
  nodeType: listing.nullable().required('Required'),
  planningSkills: multipleListing,
});

export type SiteDetailsFormValues = InferType<typeof siteDetailsFormSchema> & {
  geofencesGeoJson: unknown | null;
};
export function SiteDetail(props: EditProps<Node>) {
  const {item} = props;
  const {onSubmit} = useEditSiteDetails(props);

  const {bounds, initialValues} = useMemo<{
    bounds: LatLngBounds;
    initialValues: SiteDetailsFormValues;
  }>(() => {
    const siteBounds = latLng(
      item?.latitude ?? 0,
      item?.longitude ?? 0,
    ).toBounds(10);
    const geofence =
      item?.geofencesGeoJson != null
        ? geoJSON(item.geofencesGeoJson as GeoJsonObject)
        : null;
    const geofenceBounds = geofence?.getBounds();
    if (geofenceBounds?.isValid() === true) {
      siteBounds.extend(geofenceBounds);
    }
    return {
      bounds: siteBounds,
      initialValues: {
        geofencesGeoJson: geofence,
        name: item?.name ?? '',
        externalReference: item?.externalReference ?? '',
        prohibitAfterHoursProcessing: item?.prohibitAfterHoursProcessing,
        address: item?.address ?? '',
        latitude: item?.latitude ?? 0,
        longitude: item?.longitude ?? 0,
        openTime:
          DateUtil.fromServerTimeToLocalTimeDate(
            item?.timeWindows?.[0]?.openTime,
          ) ?? new Date(),
        closeTime:
          DateUtil.fromServerTimeToLocalTimeDate(
            item?.timeWindows?.[(item?.timeWindows?.length ?? 1) - 1]
              ?.closeTime,
          ) ?? new Date(),
        loadingTimeInMinutes: item?.loadingTimeInMinutes?.toString() ?? '',
        unloadingTimeInMinutes: item?.unloadingTimeInMinutes?.toString() ?? '',
        processingTimeInMinutes:
          item?.processingTimeInMinutes?.toString() ?? '',
        nodeType: {
          value: item?.nodeType?.id ?? 0,
          label: item?.nodeType?.name ?? '',
        },
        planningSkills:
          item?.skills?.map(({id, name, description}) => ({
            value: id,
            label: `${name} - ${description}`,
          })) ?? [],
      },
    };
  }, [item]);

  return (
    <VantageForm
      permission="site:edit"
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={siteDetailsFormSchema}
    >
      <Stack direction="row">
        <SiteDetailsFields />
        <SiteMap bounds={bounds} />
      </Stack>
      <VantageFormActions>
        <VantageFormSubmit />
        <VantageFormReset />
      </VantageFormActions>
    </VantageForm>
  );
}
