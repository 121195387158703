import {webMasterTripDebriefApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadTripDebriefs = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const isActive = useDateRangeStore((state) => state.isActive);

  return useLoadList(
    async (signal) =>
      await webMasterTripDebriefApi.apiWebMasterTripDebriefGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          ...(isActive
            ? {
                startDate: new Date(startDate),
                endDate: new Date(endDate),
              }
            : {}),
          status: getFilter('status'),
          masterTripDebriefId: getFilter('masterTripDebriefId') as
            | number
            | undefined,
          upliftNodeName: getFilter('upliftNodeName'),
          driverName: getFilter('driverName'),
          tripNumber: getFilter('tripNumber'),
          contractId: getFilter('contractId') as number | undefined,
          vehicleRegistrationNumber: getFilter('vehicleRegistrationNumber'),
          contractCode: getFilter('contractCode'),
          masterTripId: getFilter('masterTripId') as number | undefined,
          debriefed: getFilter('debriefed') as boolean | undefined,
          offloadNodeName: getFilter('offloadNodeName'),
          vehicleFleetNumber: getFilter('vehicleFleetNumber'),
          masterTripDebriefApprovedForBilling: getFilter(
            'masterTripDebriefApprovedForBilling',
          ) as boolean | undefined,
          orderNumber: getFilter('orderNumber'),
        },
        {signal},
      ),
    [
      getFilter,
      loadRequestPagination,
      loadRequestSorting,
      startDate,
      endDate,
      isActive,
    ],
    {updatePagination},
  );
};
