import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import Audiences from './pages/Audiences';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyAudience = lazy(async () => import('./pages/Audience'));

const LazyAudienceAdd = lazy(async () => import('./pages/AudienceAdd'));

export const audienceRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.audience.list)},
  {
    path: routes.getRelativePath(routes.audience.list, routes.audience.base),
    element: <Audiences />,
    loader: () => {
      document.title = 'Audiences';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Audience'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.audience.add, routes.audience.base),
    element: <LazyAudienceAdd />,
    loader: () => {
      document.title = 'Add Audience';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Audience', path: routes.audience.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.audience.contactId,
      routes.audience.base,
    ),
    element: <LazyAudience />,
    loader: () => {
      document.title = 'View Audience';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Audience', path: routes.audience.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
