import {
  type Dispatch,
  type DragEvent,
  type SetStateAction,
  useCallback,
} from 'react';

import {type DataTypeProvider} from '@devexpress/dx-react-grid';
import DragIndicatorIcon from '@mui/icons-material/DragIndicatorRounded';
import WarningIcon from '@mui/icons-material/WarningRounded';
import Tooltip from '@mui/material/Tooltip';

import {TableActionAuxNavigate} from '../components/TableActionAuxNavigate';
import {TableActionButtonDelete} from '../components/TableActionButtonDelete';
import {TableActionDownloadItem} from '../components/TableActionDownloadItem';
import {TableActionExpand} from '../components/TableActionExpand';
import {TableActionInlineEdit} from '../components/TableActionInlineEdit';
import {TableActionNavigate} from '../components/TableActionNavigate';
import {TableActionSelect} from '../components/TableActionSelect';
import {
  type VantageTableProps,
  type VantageTableRowActions,
} from '../VantageTable';

export interface ActionsTypeProviderFormatterProps<TableData extends object>
  extends Pick<VantageTableProps<TableData>, 'rowId' | 'rowDetail' | 'config'> {
  rowActions: VantageTableRowActions<TableData>;
  expandedRowIds: Array<string | number>;
  setExpandedRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  setEditingRowIds: Dispatch<SetStateAction<Array<string | number>>>;
}

export function ActionsTypeProviderFormatter<TableData extends object>({
  row,
  rowDetail,
  rowActions,
  config,
  rowId,
  expandedRowIds,
  setExpandedRowIds,
  setEditingRowIds,
  ...props
}: DataTypeProvider.ValueFormatterProps &
  ActionsTypeProviderFormatterProps<TableData>) {
  const {
    selection,
    onAuxNavigate,
    onNavigate,
    onDelete,
    onDownloadItem,
    onDragStart,
    onWarning,
    // TODO
    // onRightClick,
    onInlineEdit,
  } = rowActions;

  const renderDragAndDrop = useCallback(() => {
    if (onDragStart == null) {
      return null;
    }
    const handleDragStart = (
      event: DragEvent<HTMLTableRowElement> & {target: {id: string | number}},
    ) => {
      if (onDragStart != null) {
        event.dataTransfer.effectAllowed = 'move';
        onDragStart(event, row as TableData);
      }
    };
    return (
      <span style={{cursor: 'move'}} onDragStart={handleDragStart}>
        <DragIndicatorIcon />
      </span>
    );
  }, [onDragStart, row]);

  const renderWarning = useCallback(() => {
    if (onWarning == null) {
      return null;
    }

    const warning = onWarning(row as TableData);

    if (!warning.visible) {
      return null;
    }

    return (
      <Tooltip title={warning.message} arrow>
        <WarningIcon color="warning" />
      </Tooltip>
    );
  }, [onWarning, row]);

  return (
    <>
      {rowActions.selection != null && (
        <TableActionSelect
          {...props}
          row={row}
          rowId={rowId}
          config={config}
          selection={selection}
        />
      )}
      {onNavigate != null && (
        <TableActionNavigate onNavigate={onNavigate} row={row} />
      )}
      {onAuxNavigate != null && (
        <TableActionAuxNavigate onAuxNavigate={onAuxNavigate} row={row} />
      )}
      {rowActions.onDelete != null && (
        <TableActionButtonDelete
          {...props}
          rowId={rowId}
          row={row}
          config={config}
          onDelete={onDelete}
        />
      )}
      {onInlineEdit != null && (
        <TableActionInlineEdit
          row={row}
          rowId={rowId}
          setEditingRowIds={setEditingRowIds}
        />
      )}
      {onDownloadItem != null && (
        <TableActionDownloadItem
          row={row}
          config={config}
          onDownloadItem={onDownloadItem}
        />
      )}
      {rowDetail != null && (
        <TableActionExpand
          expandedRowIds={expandedRowIds}
          setExpandedRowIds={setExpandedRowIds}
          rowId={rowId}
          row={row}
        />
      )}
      {renderDragAndDrop()}
      {renderWarning()}
    </>
  );
}
