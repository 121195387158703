import {type ReactNode, Suspense, useEffect, useState} from 'react';

import MuiDrawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {Outlet} from 'react-router-dom';

import {config} from '../../../../config';
import {useLoadUserMe} from '../../../auth/hooks/useLoadUserMe';
import {CommandPrompt} from '../../../commandPrompt/components/CommandPrompt';
import {EventsPane} from '../../../event/components/eventPane/EventsPane';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';
import {useEventStore} from '../../stores/eventStore';
import {PageLoader} from '../loader/PageLoader';
import {Navigation} from '../navigation/Navigation';
import {TopBar} from '../topBar/TopBar';

export function AppShell({children}: {children?: ReactNode}) {
  useLoadUserMe();
  const eventsDrawerOpen = useEventStore((state) => state.eventsDrawerOpen);
  const [isCommandPromptOpen, setIsCommandPromptOpen] = useState(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        setIsCommandPromptOpen((prev) => !prev);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <>
      <CommandPrompt
        isOpen={isCommandPromptOpen}
        onClose={() => {
          setIsCommandPromptOpen(false);
        }}
      />
      <Navigation />
      <Container open={eventsDrawerOpen}>
        <TopBar />
        <Suspense fallback={<PageLoader />}>
          <Stack overflow="auto" flex={1}>
            {children ?? <Outlet />}
          </Stack>
        </Suspense>
      </Container>
      <Drawer
        sx={{
          pointerEvents: eventsDrawerOpen ? 'all' : 'none',
          width: config.rightDrawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: config.rightDrawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="right"
        open={eventsDrawerOpen}
        id="SidePanelContainer"
        keepMounted={false}
      >
        <EventsPaneContent>
          {eventsDrawerOpen && <EventsPane />}
        </EventsPaneContent>
      </Drawer>
    </>
  );
}

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: addShouldNotForwardProps('keepMounted'),
})({});

const Container = styled('div')<{open: boolean}>(({theme, open}) => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  flex: 1,
  marginRight: `-${config.rightDrawerWidth}px`,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  }),
}));
const EventsPaneContent = styled(Stack)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  overflow: 'hidden',
}));
