import {useEffect} from 'react';

import {useLocation, useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';

import {routes} from '../../../routes';

export const useRedirectToBaseIfNotConnected = ({
  connected,
  connecting,
}: {
  connected: boolean;
  connecting: boolean;
}) => {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (
      !connected &&
      !connecting &&
      pathname !== routes.eventReview.base &&
      !pathname.includes('videoEvents')
    ) {
      if (!connected) {
        navigate(routes.eventReview.base);
        toast('You have to start your session to view this page', {
          type: 'info',
        });
      }
    }
  }, [connected, navigate, pathname, connecting]);
};
