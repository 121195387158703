import {HTMLAttributes, memo} from 'react';

import Today from '@mui/icons-material/TodayRounded';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {CalendarGrid} from './CalendarGrid';
import {CalendarHeader} from './CalendarHeader';
import {UseDateRangeReturnType} from './useDateRange';
import {addShouldNotForwardProps} from '../../helpers/addShouldNotForwardProps';

interface CalendarProps
  extends Pick<
      UseDateRangeReturnType,
      | 'currentMonth'
      | 'handleMonthChange'
      | 'selectionMode'
      | 'tempRange'
      | 'isInRange'
      | 'handleDateClick'
      | 'onHoverChange'
      | 'handleSetToToday'
      | 'handleApply'
      | 'handleCancel'
    >,
    HTMLAttributes<HTMLDivElement> {
  enableToday?: boolean;
  model?: boolean;
}

export const Calendar = memo(function Calendar({
  currentMonth,
  selectionMode,
  handleDateClick,
  handleMonthChange,
  isInRange,
  tempRange,
  onHoverChange,
  handleSetToToday,
  handleApply,
  handleCancel,
  enableToday = false,
  model = false,
  ...props
}: CalendarProps) {
  return (
    <StyledPaper
      {...props}
      elevation={6}
      variant={model ? 'elevation' : 'outlined'}
      isModel={model}
    >
      <CalendarHeader
        currentMonth={currentMonth}
        handleMonthChange={handleMonthChange}
      />

      <Stack flex={1} direction="row" spacing={2} alignItems="center" mb={1}>
        {[
          currentMonth,
          new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1),
        ].map((month, i) => (
          <Stack key={i} flex={1} alignItems="center">
            <CalendarGrid
              month={month}
              selectionMode={selectionMode}
              tempRange={tempRange}
              isInRange={isInRange}
              handleDateClick={handleDateClick}
              onHoverChange={onHoverChange}
            />
          </Stack>
        ))}
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        {enableToday && (
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Today />}
            onClick={handleSetToToday}
          >
            Today
          </Button>
        )}
        <Typography
          flex={1}
          variant="body1"
          color="textSecondary"
          textAlign="center"
        >
          Selecting <strong>{selectionMode.toUpperCase()}</strong> date
        </Typography>
        <Button variant="outlined" color="inherit" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleApply}>
          Apply
        </Button>
      </Stack>
    </StyledPaper>
  );
});

const StyledPaper = styled(Paper, {
  shouldForwardProp: addShouldNotForwardProps('isModel'),
})<{isModel: boolean}>(({theme, isModel}) => ({
  ...(isModel && {
    position: 'absolute',
    right: 0,
    marginTop: '8px',
    zIndex: theme.zIndex.modal,
  }),
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}));
