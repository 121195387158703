import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import TripAdd from './pages/TripAdd';
import Trips from './pages/Trips';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTrip = lazy(async () => await import('./pages/Trip'));

export const tripRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.trip.list)},
  {
    path: routes.getRelativePath(routes.trip.list, routes.trip.base),
    element: <Trips />,
    loader: () => {
      document.title = `Trips`;
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Trips'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.trip.add, routes.trip.base),
    element: <TripAdd />,
    loader: () => {
      document.title = `Add Trip`;
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Trips', path: routes.trip.list}, {label: 'Add'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.trip.masterTripId, routes.trip.base),
    element: <LazyTrip />,
    loader: () => {
      document.title = `View Trip`;
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Trips', path: routes.trip.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
