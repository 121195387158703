import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {useFormikContext} from 'formik';

import {DateRangePicker} from '../../../common/components/dateRangePicker/DateRangePicker';

export function ReportScheduleFormScheduleDateRange() {
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value: startDateValue, error: startDateError} =
    getFieldMeta('startDate');
  const {setValue: setStartDate} = getFieldHelpers('startDate');
  const {value: endDateValue, error: endDateError} = getFieldMeta('endDate');
  const {setValue: setEndDate} = getFieldHelpers('endDate');

  let startDate = new Date();
  let endDate = new Date();

  if (typeof startDateValue === 'string') {
    startDate = new Date(startDateValue);
  } else if (startDateValue instanceof Date) {
    startDate = startDateValue;
  }

  if (typeof endDateValue === 'string') {
    endDate = new Date(endDateValue);
  } else if (endDateValue instanceof Date) {
    endDate = endDateValue;
  }

  let error: string | undefined;
  if (startDateError != null) {
    error = `Start date: ${startDateError}`;
  }

  if (endDateError != null) {
    error =
      error == null
        ? `End date: ${endDateError}`
        : `${error} | End date: ${endDateError}`;
  }

  return (
    <Stack spacing={1}>
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={setStartDate}
        onEndDateChange={setEndDate}
      />
      {error != null && <Typography color="error">{error}</Typography>}
    </Stack>
  );
}
