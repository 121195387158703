import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import type {Driver} from '@onroadvantage/onroadvantage-api';

import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useLoadDrivers} from '../../driver/hooks/useLoadDrivers';

const tableConfig: VantageTableConfig<Driver> = {
  columns: [
    {
      name: 'firstname',
      label: 'Firstname',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'lastname',
      label: 'Lastname',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'externalEmployeeNumber',
      label: 'Employee number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
  ],
};

interface BroadcastVantageMobileDriversProps {
  selectedDrivers: Driver[];
  setSelectedDrivers: (drivers: Driver[]) => void;
  onNextStep: () => void;
}

export function BroadcastVantageMobileDrivers({
  selectedDrivers,
  setSelectedDrivers,
  onNextStep,
}: BroadcastVantageMobileDriversProps) {
  const {data, tablePagination, tableFiltering, tableSorting} = useLoadDrivers({
    hasSmartphone: true,
  });

  return (
    <>
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        rowActions={{
          selection: {
            selection: selectedDrivers,
            onSelectionChange: setSelectedDrivers,
          },
        }}
      />
      <Divider />
      <Stack direction="row" p={1} spacing={2}>
        <Button variant="contained" color="inherit" disabled>
          Back
        </Button>
        <Button
          variant="contained"
          disabled={selectedDrivers.length === 0}
          onClick={onNextStep}
        >
          Next
        </Button>
      </Stack>
    </>
  );
}
