import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Report} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {getTooltipTableColumnValues} from '../../common/helpers/getTooltipTableColumnValues';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteReport} from '../hooks/useDeleteReport';
import {useLoadReports} from '../hooks/useLoadReports';

const tableConfig: VantageTableConfig<Report> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'version',
      label: 'Version',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'reportCategories',
      label: 'Category',
      type: 'string',
      getValue: ({reportCategories}) =>
        getTooltipTableColumnValues(
          reportCategories?.map(({name}) => name ?? '-'),
          2,
        ),
    },
    {name: 'createdAt', label: 'Created At', type: 'datetime', hidden: true},
    {name: 'updatedAt', label: 'Updated At', type: 'datetime', hidden: true},
  ],
  identifier: 'reports',
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Report name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
    </Stack>
  ),
};
export default function Reports() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData, removeItem} = useLoadReports({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete} = useDeleteReport({removeItem});

  return (
    <VantageTablePaper>
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        toolbar={{onRefresh: reloadData}}
        actions={
          hasPermissions('report:add')
            ? {
                onAdd: () => {
                  navigate(routes.report.add);
                },
              }
            : {}
        }
        rowActions={{
          onNavigate: (row) =>
            row.id
              ? routes.replaceParams(routes.report.reportId, {
                  reportId: row.id,
                })
              : null,
          onDelete: hasPermissions('report:delete') ? onDelete : undefined,
        }}
      />
    </VantageTablePaper>
  );
}
