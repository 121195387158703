import {useState, useEffect, useCallback, useRef} from 'react';

import {type EarlyWarningEventPostReviewDetailsResponse} from '@onroadvantage/onroadvantage-api';
import {type AuthContextProps} from 'oidc-react';
import {toast} from 'react-toastify';

import {useAuthStore} from '../../common/stores/authStore';
import {
  type ActiveEventType,
  type LytxPreEventData,
  type SurfSightEventData,
  type WebSocketEvents,
} from '../WebsocketTypes';
import {useEventReviewWebsocketTimeout} from './useEventReviewWebsocketTimeout';
import type {LookupTablesResult} from './useGenerateLookupTables';
import {useRedirectToBaseIfNotConnected} from './useRedirectToBaseIfNotConnected';
import {config} from '../../../config';
import {
  isActiveLytxEvent,
  isActiveLytxPreEvent,
  isActiveSurfSightEvent,
} from '../utils/eventDistinctions';
import {handleWebSocketMessage} from '../utils/eventReviewWebsocketMessageHandler';
import {enhanceFormData} from '../utils/feedbackUtils';
import {
  fetchHindsightData,
  fetchOrganisationGroup,
} from '../utils/fetchEventHindsightAndOrganisationData';

interface Comment {
  text: string;
  commentTime: string;
  [key: symbol]: number;
}

interface QueueItem {
  formValues: Record<string, boolean>;
  event: WebSocketEvents | SurfSightEventData | LytxPreEventData;
  lookupTableData: LookupTablesResult;
  auth: AuthContextProps;
  comments: Comment[];
  reviewEnd: Date;
  isTakingBreak?: boolean;
}

export const useEventReviewWebSocket = () => {
  const [connected, setConnected] = useState<boolean>(false);
  const [connecting, setConnecting] = useState<boolean>(false);
  const [events, setEvents] = useState<
    Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>
  >([]);
  const [queueEmpty, setQueueEmpty] = useState<boolean>(false);
  const [eventCount, setEventCount] = useState<number | null>(null);
  const [activeEventType, setActiveEventType] =
    useState<ActiveEventType | null>(null);
  const [submissionQueue, setSubmissionQueue] = useState<QueueItem[]>([]);
  const processingRef = useRef<boolean>(false);
  const wsRef = useRef<WebSocket | null>(null);
  const ssoToken = useAuthStore((state) => state.ssoToken);
  const {startReviewTimer} = useEventReviewWebsocketTimeout({
    websocket: wsRef,
  });

  useRedirectToBaseIfNotConnected({connected, connecting});

  const generateHindsightData = useCallback(
    async (
      ids?: number[],
    ): Promise<EarlyWarningEventPostReviewDetailsResponse | null> => {
      if (ids == null) return null;

      try {
        const currentEvent = submissionQueue[0]?.event;
        if (!currentEvent) return null;

        if (isActiveLytxPreEvent(currentEvent)) {
          return fetchHindsightData({behaviourIds: ids, preEvent: true});
        }

        const telematicsProviderName = isActiveSurfSightEvent(currentEvent)
          ? 'Surfsight'
          : 'Lytx';
        const detailsResponse = await fetchOrganisationGroup(
          currentEvent,
          telematicsProviderName,
        );

        if (detailsResponse?.organisationId) {
          return fetchHindsightData({
            behaviourIds: ids,
            organisationGroupExternalId: detailsResponse.organisationId,
          });
        }

        return null;
      } catch {
        toast('Error generating HindsightData', {type: 'error'});
        return null;
      }
    },
    [submissionQueue],
  );

  const connect = useCallback(() => {
    setConnecting(true);
    setEvents([]);
    wsRef.current = new WebSocket(config.earlyWarningWebSocketUrl);
    wsRef.current.onopen = () => {
      wsRef.current?.send(JSON.stringify({type: 'auth', token: ssoToken}));
      wsRef.current?.send(JSON.stringify({type: 'event_count'}));
      setConnected(true);
      setConnecting(false);
      startReviewTimer();
    };

    wsRef.current.onmessage = (event: MessageEvent<string>) => {
      const data: unknown = JSON.parse(event.data);
      handleWebSocketMessage({
        data,
        wsRef,
        setEvents,
        setQueueEmpty,
        setEventCount,
        queueEmpty,
        events,
        setActiveEventType,
      });
    };

    wsRef.current.onclose = () => {
      setConnected(false);
    };
  }, [ssoToken, startReviewTimer, queueEmpty, events]);

  const disconnect = useCallback(() => {
    if (wsRef.current != null) {
      wsRef.current.close();
    }
  }, []);

  const queueReview = useCallback(
    (
      formValues: Record<string, boolean>,
      lookupTableData: LookupTablesResult,
      auth: AuthContextProps,
      comments: Comment[],
      reviewEnd: Date,
      isTakingBreak?: boolean,
    ) => {
      if (wsRef.current == null || events.length === 0) {
        return;
      }

      wsRef.current?.send(JSON.stringify({type: 'event_count'}));

      const currentEvent = events[0];

      setSubmissionQueue((queue) => [
        ...queue,
        {
          formValues,
          event: currentEvent,
          lookupTableData,
          auth,
          comments,
          reviewEnd,
          isTakingBreak,
        },
      ]);
      startReviewTimer();

      // Update events immediately
      setEvents((prevEvents) => {
        if (prevEvents.length === 0) {
          return [];
        }
        const pendingEvents = prevEvents.slice(1);

        if (pendingEvents?.length > 0) {
          pendingEvents[0].reviewStartTime = new Date();
          if (isActiveSurfSightEvent(pendingEvents[0])) {
            setActiveEventType('SurfSight');
          } else if (isActiveLytxEvent(pendingEvents[0])) {
            setActiveEventType('Lytx');
          } else {
            setActiveEventType('LytxPreEvent');
          }
          return pendingEvents;
        }
        return [];
      });
    },
    [events, startReviewTimer],
  );

  const processQueue = useCallback(async () => {
    if (
      processingRef.current ||
      submissionQueue.length === 0 ||
      wsRef.current == null
    ) {
      return;
    }

    processingRef.current = true;

    try {
      const item = submissionQueue[0];

      const filteredValues = Object.fromEntries(
        Object.entries(item.formValues).filter(([, value]) => value),
      );

      const {ids} = enhanceFormData(
        filteredValues,
        item.lookupTableData.behaviorLookupTable,
        item.lookupTableData.observationLookupTable,
      );

      const hindsightData = await generateHindsightData(ids);

      if (hindsightData != null) {
        wsRef.current.send(
          JSON.stringify({
            type: 'review',
            accessToken: ssoToken,
            eventId: item.event.id,
            reviewStart: item.event.reviewStartTime,
            reviewEnd: item?.reviewEnd ?? new Date(),
            reviewData: {
              reviewer: item.auth.userData?.profile?.email ?? '',
              behaviourOutcomes: hindsightData?.behaviourOutcomes ?? [],
              comments: item?.comments,
            },
            hindsight: hindsightData?.hindsightStandardConfigs ?? [],
            behaviours: hindsightData?.behaviours ?? [],
          }),
        );

        if (item.isTakingBreak) {
          disconnect();
        }
      }

      // Remove from queue
      setSubmissionQueue((queue) => queue.slice(1));
    } catch {
      toast('Failed to process queue item', {type: 'error'});
    } finally {
      processingRef.current = false;
      if (submissionQueue.length > 1) {
        void processQueue();
      }
    }
  }, [disconnect, generateHindsightData, submissionQueue, ssoToken]);

  useEffect(() => {
    void processQueue();
  }, [submissionQueue, processQueue]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  return {
    connected,
    events,
    setEvents,
    queueReview,
    connect,
    disconnect,
    queueEmpty,
    eventCount,
    activeEventType,
    restartReviewTimer: startReviewTimer,
  };
};
