import Button from '@mui/material/Button';
import {toast} from 'react-toastify';
import {object, date, type InferType} from 'yup';

import {
  listing,
  requiredListing,
} from '../../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {VantageDateTimePickerField} from '../../../common/components/form/VantageDateTimePickerField';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormFieldsRow} from '../../../common/components/form/VantageFormFieldsRow';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';

const tripAddDetailsValidationSchema = object({
  contract: requiredListing,
  tripStart: date().required('Required'),
  driver: listing,
  vehicle: listing,
});
export type TripAddDetailsValues = InferType<
  typeof tripAddDetailsValidationSchema
>;

interface TripAddDetailsProps {
  tripDetails: TripAddDetailsValues;
  onSubmit: (values: TripAddDetailsValues) => void;
}

export function TripAddDetails({tripDetails, onSubmit}: TripAddDetailsProps) {
  const handleSubmit = (values: TripAddDetailsValues) => {
    if (values.tripStart == null) {
      toast.warning('Trip start is required');
      return;
    }
    if (values.contract?.value == null) {
      toast.warning('Contract is required');
      return;
    }
    onSubmit(values);
  };

  return (
    <VantageForm
      permission="trip:add"
      initialValues={
        tripDetails ?? {
          tripStart: new Date(),
          contract: {value: 0},
          vehicle: {value: undefined},
          driver: {value: undefined},
        }
      }
      onSubmit={handleSubmit}
      validationSchema={tripAddDetailsValidationSchema}
    >
      <VantageFormContent>
        <VantageFormFieldsRow>
          <VantageDateTimePickerField
            name="tripStart"
            label="Trip Start"
            formik
          />
          <VantageAutocompleteFieldWithListing
            models="Contract"
            name="contract"
            label="Contract"
            placeholder="Enter and select the trip contract"
            queryOptions={{metaData: 'code'}}
            formik
          />
        </VantageFormFieldsRow>
        <VantageFormFieldsRow>
          <VantageAutocompleteFieldWithListing
            models="Driver"
            name="driver"
            label="Driver"
            placeholder="Enter and select the trip driver"
            formik
          />
          <VantageAutocompleteFieldWithListing
            models="Vehicle"
            name="vehicle"
            label="Vehicle"
            placeholder="Enter and select the trip vehicle"
            formik
          />
        </VantageFormFieldsRow>
      </VantageFormContent>
      <VantageFormActions>
        <Button variant="outlined" color="inherit" disabled>
          Back
        </Button>
        <VantageFormSubmit disableIsDirty label="Next" />
      </VantageFormActions>
    </VantageForm>
  );
}
