import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyAudienceGroups = lazy(
  async () => await import('./pages/AudienceGroups'),
);
const LazyAudienceGroup = lazy(
  async () => await import('./pages/AudienceGroup'),
);
const LazyAudienceGroupAdd = lazy(
  async () => await import('./pages/AudienceGroupAdd'),
);

export const audienceGroupRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.audienceGroup.list)},
  {
    path: routes.getRelativePath(
      routes.audienceGroup.list,
      routes.audienceGroup.base,
    ),
    element: <LazyAudienceGroups />,
    loader: () => {
      document.title = 'Audience Groups';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Audience Groups'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.audienceGroup.add,
      routes.audienceGroup.base,
    ),
    element: <LazyAudienceGroupAdd />,
    loader: () => {
      document.title = 'Add Audience Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Audience Groups', path: routes.audienceGroup.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.audienceGroup.audienceGroupId,
      routes.audienceGroup.base,
    ),
    element: <LazyAudienceGroup />,
    loader: () => {
      document.title = 'View Audience Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Audience Groups', path: routes.audienceGroup.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
