import {useCallback, useEffect, useRef, useState} from 'react';

import {
  type ApiWebEventGetRequest,
  type CriticalEventDump,
  type Listing,
  type OperationalEventDump,
  type WebEvent,
  type WebEventVehicle,
} from '@onroadvantage/onroadvantage-api';
import {type FormikProps} from 'formik/dist/types';
import {DateTime} from 'luxon';

import {webEventApi} from '../../../api';
import {config} from '../../../config';
import {useEventStore} from '../../common/stores/eventStore';
import {type EventModel} from '../../common/types/EventModel';
import {eventTypeModels} from '../../common/types/EventTypeModel';
import type {LoadStatus} from '../../common/types/LoadStatus';
import type {EventsPaneAdvancedSearchFormValues} from '../components/eventPane/EventsPaneAdvancedSearchForm';
import {type EventsPaneSearchFormValues} from '../components/eventPane/EventsPaneSearchForm';
import {type EventsPaneTab} from '../components/eventPane/EventsPaneTabs';
import {mapEventToWebEvent} from '../helpers/mapEventToWebEvent';

class SearchEventsRequestParameters {
  private searchValue: string | null = null;

  private currentPage: number = 1;

  private status: EventsPaneTab = 'Inbox';

  private advancedSearchValues: EventsPaneAdvancedSearchFormValues | null =
    null;

  reset() {
    this.status = 'Inbox';
    this.searchValue = null;
    this.advancedSearchValues = null;
  }

  resetSearchValues() {
    this.searchValue = null;
    this.advancedSearchValues = null;
  }

  setSearchValue(val: string) {
    this.advancedSearchValues = null;
    this.searchValue = val;
    return this.searchValue;
  }

  setCurrentPage(val: number) {
    this.currentPage = val;
    return this.currentPage;
  }

  setAdvancedSearchValues(
    newAdvancedSearchValues: EventsPaneAdvancedSearchFormValues | null,
  ) {
    this.searchValue = null;
    this.advancedSearchValues = newAdvancedSearchValues;
    return this.advancedSearchValues;
  }

  updateAdvancedSearchValues(
    newAdvancedSearchValues: Partial<EventsPaneAdvancedSearchFormValues>,
  ) {
    this.searchValue = null;
    if (this.advancedSearchValues == null) {
      this.advancedSearchValues = {
        rangeUnit: 'Days',
        rangeValue: 1,
        ...newAdvancedSearchValues,
      };
    } else {
      this.advancedSearchValues = {
        ...this.advancedSearchValues,
        ...newAdvancedSearchValues,
      };
    }
    return this.advancedSearchValues;
  }

  setStatus(newTab: EventsPaneTab) {
    this.status = newTab;
    return this.status;
  }

  get getStatus() {
    return this.status;
  }

  get getCurrentPage() {
    return this.currentPage;
  }

  get getSearchValue() {
    return this.searchValue;
  }

  get getAdvancedSearchValues() {
    return this.advancedSearchValues;
  }
}

export const searchEventsRequestParameters =
  new SearchEventsRequestParameters();

const mappedTabStatus: {[key in EventsPaneTab]: string[]} = {
  Inbox: ['Open'],
  History: ['Closed', 'BulkClosed'],
};
export type ExpandedSearchForm = 'none' | 'basic' | 'advanced';

export const useSearchEvents = () => {
  const eventsDrawerOpen = useEventStore((state) => state.eventsDrawerOpen);
  const [loadEventsStatus, setLoadEventsStatus] = useState<
    LoadStatus | 'searching'
  >('idle');

  const [events, setEvents] = useState<WebEvent[]>([]);
  const [activeTab, setActiveTab] = useState<EventsPaneTab>('Inbox');
  const [expandedSearchForm, setExpandedSearchForm] =
    useState<ExpandedSearchForm>('none');
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const advancedFormInnerRef =
    useRef<FormikProps<EventsPaneAdvancedSearchFormValues>>(null);
  const searchFormInnerRef =
    useRef<FormikProps<EventsPaneSearchFormValues>>(null);

  const refreshTimeoutRef = useRef<number>(undefined);
  const progressTimeoutRef = useRef<number>(undefined);
  const [progress, setProgress] = useState(100);

  const decreaseProgress = useCallback(() => {
    setProgress((prevProgress) =>
      prevProgress <= 0
        ? 0
        : prevProgress - 100 / (config.eventsRefreshTimeout / 1000),
    );
    progressTimeoutRef.current = setTimeout(() => {
      decreaseProgress();
    }, 1000);
  }, []);

  const updateEventState = useCallback(
    (event: CriticalEventDump | OperationalEventDump, model: EventModel) => {
      setEvents((prevState) =>
        prevState.map((e) =>
          e.id === event.id ? mapEventToWebEvent(event, model) : e,
        ),
      );
    },
    [],
  );

  const searchEvents = useCallback(
    async (
      reason: 'initial' | 'reload',
      searchParams?: {
        currentPage?: number;
        status?: EventsPaneTab;
        advancedSearchValues?: EventsPaneAdvancedSearchFormValues | null;
        searchValue?: string | null;
      },
    ) => {
      clearTimeout(refreshTimeoutRef.current);
      if (reason === 'initial') {
        setProgress(100);
        clearTimeout(progressTimeoutRef.current);
        setLoadEventsStatus('loading');
      } else {
        setLoadEventsStatus('reloading');
      }
      const tabStatus =
        searchParams?.status ?? searchEventsRequestParameters.getStatus;
      const status = mappedTabStatus[tabStatus];
      const advancedSearchValues =
        searchParams?.advancedSearchValues ??
        searchEventsRequestParameters.getAdvancedSearchValues;
      const searchValue =
        searchParams?.searchValue ??
        searchEventsRequestParameters.getSearchValue;
      const endDateTime = DateTime.now().endOf('day');

      let requestParameters: ApiWebEventGetRequest = {
        page: 1,
        perPage: 25,
        endDate: endDateTime.toJSDate(),
        status,
      };

      if (advancedSearchValues != null) {
        const {
          rangeUnit,
          rangeValue,
          contracts,
          drivers,
          eventTypes,
          vehicleGroups,
          vehicles,
          eventId,
          eventIdEventType,
        } = advancedSearchValues;
        const contractIds = new Set<number>();
        const driverIds = new Set<number>();
        const criticalEventTypeIds = new Set<number>();
        const operationalEventTypeIds = new Set<number>();
        const vehicleGroupIds = new Set<number>();
        const vehicleIds = new Set<number>();

        contracts?.forEach(({value}) => {
          if (value != null) {
            contractIds.add(value);
          }
        });

        drivers?.forEach(({value}) => {
          if (value != null) {
            driverIds.add(value);
          }
        });

        eventTypes?.forEach(({value, metaData}) => {
          if (value != null) {
            if (metaData === eventTypeModels.OperationalEventType) {
              operationalEventTypeIds.add(value);
            } else {
              criticalEventTypeIds.add(value);
            }
          }
        });

        vehicleGroups?.forEach(({value}) => {
          if (value != null) {
            vehicleGroupIds.add(value);
          }
        });

        vehicles?.forEach(({value}) => {
          if (value != null) {
            vehicleIds.add(value);
          }
        });

        if (contractIds.size > 0) {
          requestParameters.contractIds = Array.from(contractIds);
        }
        if (driverIds.size > 0) {
          requestParameters.driverIds = Array.from(driverIds);
        }
        if (vehicleIds.size > 0) {
          requestParameters.vehicleIds = Array.from(vehicleIds);
        }
        if (vehicleGroupIds.size > 0) {
          requestParameters.vehicleGroupIds = Array.from(vehicleGroupIds);
        }
        if (operationalEventTypeIds.size > 0) {
          requestParameters.operationalEventTypeIds = Array.from(
            operationalEventTypeIds,
          );
        }
        if (criticalEventTypeIds.size > 0) {
          requestParameters.criticalEventTypeIds =
            Array.from(criticalEventTypeIds);
        }

        if (eventId != null && eventId > 0) {
          requestParameters = {
            page: 1,
            perPage: 25,
            status,
          };
          requestParameters[
            eventIdEventType === 'Operational Event'
              ? 'operationalEventId'
              : 'criticalEventId'
          ] = eventId;
        }

        requestParameters.startDate = endDateTime
          .minus({
            [rangeUnit.toLowerCase()]: rangeValue,
          })
          .startOf('day')
          .toJSDate();
      } else if (searchValue != null) {
        requestParameters.filter = searchValue;
      }

      requestParameters.page =
        searchParams?.currentPage ??
        searchEventsRequestParameters.getCurrentPage;

      const response = await webEventApi.apiWebEventGet(requestParameters);

      if (response.pages != null && response.page != null) {
        setPagesCount(response.pages);
        setCurrentPage(response.page);
        searchEventsRequestParameters.setCurrentPage(response.page);
      }

      setEvents(response.items ?? []);
      setLoadEventsStatus('success');

      setProgress(100);
      clearTimeout(progressTimeoutRef.current);

      if (tabStatus === 'Inbox') {
        refreshTimeoutRef.current = setTimeout(async () => {
          await searchEvents('reload');
        }, config.eventsRefreshTimeout);
        progressTimeoutRef.current = setTimeout(() => {
          decreaseProgress();
        }, 1000);
      }
    },
    [decreaseProgress],
  );

  const onTabChange = useCallback(
    async (newTab: EventsPaneTab) => {
      searchEventsRequestParameters.setStatus(newTab);
      setActiveTab(newTab);
      if (newTab === 'History') {
        setExpandedSearchForm('advanced');
      }
      await searchEvents('initial', {status: newTab});
    },
    [searchEvents],
  );

  const onCurrentPageChange = useCallback(
    async (newPage: number) => {
      searchEventsRequestParameters.setCurrentPage(newPage);
      setCurrentPage(newPage);

      await searchEvents('initial', {currentPage: newPage});
    },
    [searchEvents],
  );

  const onAdvancedSearch = useCallback(
    async (values: EventsPaneAdvancedSearchFormValues) => {
      searchEventsRequestParameters.setAdvancedSearchValues(values);
      await searchEvents('initial', {advancedSearchValues: values});
    },
    [searchEvents],
  );

  const onSearch = useCallback(
    async (values: EventsPaneSearchFormValues) => {
      searchEventsRequestParameters.setSearchValue(values.search);
      advancedFormInnerRef.current?.resetForm();
      await searchEvents('initial', {searchValue: values.search});
    },
    [searchEvents],
  );
  const onSearchVehicleHistory = useCallback(
    async (vehicle: WebEventVehicle) => {
      searchFormInnerRef.current?.resetForm();

      searchEventsRequestParameters.setStatus('History');
      setActiveTab('History');

      const listingValue: Listing = {
        value: vehicle.id,
        label: vehicle.registrationNumber,
      };
      await advancedFormInnerRef.current?.setFieldValue('vehicles', [
        listingValue,
      ]);
      const advancedSearchValues =
        searchEventsRequestParameters.updateAdvancedSearchValues({
          vehicles: [listingValue],
        });

      await searchEvents('initial', {status: 'History', advancedSearchValues});
    },
    [searchEvents],
  );

  const onClearSearchValues = useCallback(async () => {
    searchEventsRequestParameters.resetSearchValues();
    await searchEvents('initial', {
      searchValue: null,
      advancedSearchValues: null,
    });
  }, [searchEvents]);

  useEffect(() => {
    const advancedFormInner = advancedFormInnerRef.current;
    const searchFormInner = searchFormInnerRef.current;
    if (eventsDrawerOpen) {
      void searchEvents('initial');
    }
    return () => {
      setEvents([]);
      setProgress(100);
      setExpandedSearchForm('none');
      clearTimeout(refreshTimeoutRef.current);
      clearTimeout(progressTimeoutRef.current);
      advancedFormInner?.resetForm();
      searchFormInner?.resetForm();
      searchEventsRequestParameters.reset();
    };
  }, [eventsDrawerOpen, searchEvents]);

  return {
    activeTab,
    currentPage,
    pagesCount,
    events,
    loadEventsStatus,
    updateEventState,
    onAdvancedSearch,
    onCurrentPageChange,
    onSearch,
    onSearchVehicleHistory,
    onClearSearchValues,
    onTabChange,
    expandedSearchForm,
    setExpandedSearchForm,
    progress,
    searchFormInnerRef,
    advancedFormInnerRef,
    reloadEvents: async () => {
      await searchEvents('reload');
    },
  };
};

export type UpdateEventState = ReturnType<
  typeof useSearchEvents
>['updateEventState'];
