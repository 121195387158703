import {useState} from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {type Listing} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {
  VantageTable,
  type VantageTableOnInlineAdd,
} from '../../common/components/table/VantageTable';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useUserStore} from '../../common/stores/userStore';

export interface OrderLineAdd {
  id: number;
  product: Listing;
  quantity: number;
}

const config: VantageTableConfig<OrderLineAdd> = {
  columns: [
    {
      name: 'product',
      label: 'Product Name',
      type: 'autocomplete',
      models: 'Product',
      enableAdd: true,
      getDisplayValue: (row) => row.product?.label,
    },
    {
      name: 'quantity',
      label: 'Planned Quantity',
      type: 'number',
      enableAdd: true,
    },
  ],
};

interface OrderLinesAddProps {
  onSubmitOrderLines: (lines: OrderLineAdd[]) => void;
  onPreviousStep: () => void;
}

export function OrderLinesAdd({
  onSubmitOrderLines,
  onPreviousStep,
}: OrderLinesAddProps) {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const [lines, setLines] = useState<OrderLineAdd[]>([]);
  const onInlineAdd: VantageTableOnInlineAdd = async (values) => {
    try {
      if (
        !isObjectWithKeys(values, 'product') ||
        !isObjectWithKeys(values.product, 'value') ||
        typeof values.product.value !== 'number'
      ) {
        toast.warning('Product is required');
        return 'catch';
      }
      if (
        !isObjectWithKeys(values, 'quantity') ||
        values.quantity == null ||
        typeof values.quantity !== 'string' ||
        Number.isNaN(parseFloat(values.quantity ?? '0'))
      ) {
        toast.warning('Planned quantity is required');
        return 'catch';
      }

      setLines((prev) => [
        ...prev,
        {
          ...(values as OrderLineAdd),
          quantity: parseFloat((values.quantity as string) ?? '0'),
          id: (values.product as Listing)?.value ?? 0,
        } satisfies OrderLineAdd,
      ]);
      return 'success';
    } catch {
      return 'catch';
    }
  };

  const onDelete = async (row: OrderLineAdd) => {
    setLines((prev) => prev.filter((line) => line.id !== row.id));
  };

  const onSubmit = () => {
    onSubmitOrderLines(lines);
  };

  return (
    <>
      <VantageTable
        config={config}
        data={lines ?? []}
        rowId="id"
        actions={hasPermissions('order:add') ? {onInlineAdd} : undefined}
        rowActions={hasPermissions('order:delete') ? {onDelete} : undefined}
      />
      <Divider />
      <Stack direction="row" p={1} spacing={2}>
        <Button
          variant="contained"
          onClick={onPreviousStep}
          color="inherit"
          // disabled={isSubmitting}
        >
          Back
        </Button>

        <Button
          variant="contained"
          disabled={lines.length === 0}
          onClick={onSubmit}
        >
          Next
        </Button>
      </Stack>
    </>
  );
}
