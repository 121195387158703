import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyProductGroups = lazy(
  async () => await import('./pages/ProductGroups'),
);
const LazyProductGroupAdd = lazy(
  async () => await import('./pages/ProductGroupAdd'),
);
const LazyProductGroup = lazy(async () => await import('./pages/ProductGroup'));

export const productGroupRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.productGroup.list)},
  {
    path: routes.getRelativePath(
      routes.productGroup.list,
      routes.productGroup.base,
    ),
    element: <LazyProductGroups />,
    loader: () => {
      document.title = 'Product Groups';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Product Groups'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.productGroup.add,
      routes.productGroup.base,
    ),
    element: <LazyProductGroupAdd />,
    loader: () => {
      document.title = 'Add Product Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Product Groups', path: routes.productGroup.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.productGroup.productGroupId,
      routes.productGroup.base,
    ),
    element: <LazyProductGroup />,
    loader: () => {
      document.title = 'View Product Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Product Groups', path: routes.productGroup.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
