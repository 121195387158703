import {broadcastApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadBroadcasts = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  return useLoadList(
    async (signal) =>
      await broadcastApi.apiBroadcastGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          heading: getFilter('heading'),
          message: getFilter('message'),
          status: getFilter('status'),
        },
        {signal},
      ),
    [getFilter, loadRequestPagination, loadRequestSorting],
    {updatePagination},
  );
};
