import {useCallback, useEffect, useRef, useState} from 'react';

import {useNavigate} from 'react-router-dom';

import {type OnFormSubmit} from '../components/form/VantageForm';
import {catchHandler} from '../helpers/catchHandler';
import type {SubmitStatus} from '../types/SubmitStatus';

interface UseFormUpdateOptions {
  errorMessage?: string;
  redirectRoute?: string | null;
  resetStatus?: boolean;
  resetFormBehaviour?: 'always' | 'onSuccess' | 'onError' | 'never';
}
export const useSubmitForm = <Values extends object>(
  submitHandler: (values: Values) => Promise<void>,
  deps: unknown[] = [],
  options: UseFormUpdateOptions = {
    errorMessage: 'Failed to submit the form',
    resetFormBehaviour: 'onSuccess',
    resetStatus: true,
  },
) => {
  const navigate = useNavigate();
  const [submitStatus, setSubmitStatus] = useState<SubmitStatus>('idle');
  const messageTimeout = useRef<number>(undefined);

  const onSubmit = useCallback<OnFormSubmit<Values>>(
    async (values, formikHelpers) => {
      setSubmitStatus('submitting');
      try {
        await submitHandler(values);
        setSubmitStatus('success');
        if (
          options.resetFormBehaviour === 'onSuccess' ||
          options.resetFormBehaviour ==
            null /** resetting the form on success is the default behaviour */
        ) {
          formikHelpers.resetForm();
        }
        if (options.redirectRoute != null) {
          navigate(options.redirectRoute);
        }
      } catch (error) {
        const {type} = catchHandler(error, options.errorMessage);

        setSubmitStatus(type);

        if (options.resetFormBehaviour === 'onError') {
          formikHelpers.resetForm();
        }
      } finally {
        formikHelpers.setSubmitting(false);
        if (options.resetStatus === true) {
          messageTimeout.current = setTimeout(() => {
            setSubmitStatus('idle');
          }, 3000);
        }
        if (options.resetFormBehaviour === 'always') {
          formikHelpers.resetForm();
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [submitHandler, navigate, options, ...(deps ?? [])],
  );

  useEffect(
    () => () => {
      if (messageTimeout.current != null) {
        clearTimeout(messageTimeout.current);
      }
    },
    [],
  );

  return {
    submitStatus,
    resetStatus: () => {
      setSubmitStatus('idle');
    },
    onSubmit,
  };
};
