import {type AssociateNode} from '@onroadvantage/onroadvantage-api';

import {nodeApi} from '../../../api';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useDelete} from '../../common/hooks/useDelete';
import {useInlineAdd} from '../../common/hooks/useInlineAdd';
import {useInlineEdit} from '../../common/hooks/useInlineEdit';
import {type EditParams} from '../../common/types/EditParams';

interface ListProps extends EditParams<AssociateNode, 'reload'> {
  itemList: number;
}

export const useEditSitePostSite = ({id, reload, itemList}: ListProps) => {
  const {addStatus, onInlineAdd} = useInlineAdd(async (values) => {
    if (
      !isObjectWithKeys(values, 'node') ||
      !isObjectWithKeys(values.node, 'value') ||
      typeof values.node.value !== 'number'
    ) {
      throw new Error('Site is required', {cause: 'warning'});
    }

    if (
      !isObjectWithKeys(values, 'taskTemplateNodeType') ||
      !isObjectWithKeys(values.taskTemplateNodeType, 'label') ||
      typeof values.taskTemplateNodeType.label !== 'string'
    ) {
      throw new Error('Template Node Type is required', {cause: 'warning'});
    }

    await nodeApi.apiNodeMainNodeIdAssociateNodePost({
      mainNodeId: id,
      body: {
        nodeId: values.node.value,
        isPostNode: true,
        sequence:
          isObjectWithKeys(values, 'sequence') &&
          typeof values.sequence === 'number'
            ? values.sequence
            : itemList + 1,
        taskTemplateNodeType: values.taskTemplateNodeType.label,
        serviceType:
          isObjectWithKeys(values, 'serviceType') &&
          typeof values.serviceType === 'string'
            ? values.serviceType
            : '',
      },
    });

    await reload();
  });

  const {updateStatus, onInlineEdit} = useInlineEdit(
    async (rowId, newValues) => {
      await nodeApi.apiAssociateNodeAssociateNodeIdPatch({
        associateNodeId: parseInt(String(rowId), 10),
        body: {
          isPostNode: true,
          sequence:
            isObjectWithKeys(newValues, 'sequence') &&
            !Number.isNaN(parseFloat(newValues.sequence?.toString() ?? '0'))
              ? parseFloat(
                  newValues.sequence?.toString() ?? (itemList + 1).toString(),
                )
              : undefined,
          serviceType:
            isObjectWithKeys(newValues, 'serviceType') &&
            typeof newValues.serviceType === 'string'
              ? newValues.serviceType
              : undefined,
          taskTemplateNodeType:
            isObjectWithKeys(newValues, 'taskTemplateNodeType') &&
            typeof newValues.taskTemplateNodeType === 'string'
              ? newValues.taskTemplateNodeType
              : undefined,
        },
      });

      await reload();
    },
  );

  const {onDelete, deleteStatus} = useDelete<AssociateNode>(async (row) => {
    if (row.id == null) {
      throw new Error('Order Line ID not found', {cause: 'warning'});
    }
    if (row.id !== 0) {
      await nodeApi.apiAssociateNodeAssociateNodeIdDelete({
        associateNodeId: row.id,
      });
    }
    void reload();
  });

  return {
    onInlineAdd,
    addStatus,
    updateStatus,
    onInlineEdit,
    onDelete,
    deleteStatus,
  };
};
