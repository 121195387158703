import {orderApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {useDateRangeStore} from '../../common/stores/dateRangeStore';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadOrders = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const startDate = useDateRangeStore((state) => state.startDate);
  const endDate = useDateRangeStore((state) => state.endDate);
  const isActive = useDateRangeStore((state) => state.isActive);

  return useLoadList(
    async (signal) => {
      const dateParams = isActive
        ? {
            startDate: new Date(startDate),
            endDate: new Date(endDate),
          }
        : {};

      return orderApi.apiOrderGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          ...dateParams,
          contractCode: getFilter('contractCode'),
          transporterName: getFilter('transporterName'),
          offloadPointName: getFilter('offloadPointName'),
          depotDepartureDatetime: getFilter('depotDepartureDatetime'),
          orderNumber: getFilter('orderNumber'),
          externalTripNumber: getFilter('externalTripNumber'),
          getUnassignedOrdersOnly:
            getFilter('getUnassignedOrdersOnly') === 'true',
          status: getFilter('status'),
          primeMover: getFilter('primeMover'),
          upliftPointName: getFilter('upliftPointName'),
        },
        {signal},
      );
    },
    [
      getFilter,
      loadRequestPagination,
      loadRequestSorting,
      startDate,
      endDate,
      isActive,
    ],
    {updatePagination},
  );
};
