import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyUsers = lazy(async () => await import('./pages/Users'));
const LazyUserAdd = lazy(async () => await import('./pages/UserAdd'));
const LazyUser = lazy(async () => await import('./pages/User'));

export const userRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.user.list)},
  {
    path: routes.getRelativePath(routes.user.list, routes.user.base),
    element: <LazyUsers />,
    loader: () => {
      document.title = 'Users';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Users'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.user.add, routes.user.base),
    element: <LazyUserAdd />,
    loader: () => {
      document.title = 'Add User';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Users', path: routes.user.list}, {label: 'Add'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.user.userId, routes.user.base),
    element: <LazyUser />,
    loader: () => {
      document.title = 'View User';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Users', path: routes.user.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
