import {useMemo} from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {styled, css} from '@mui/material/styles';

import {config} from '../../../config';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {ReviewEventChipList} from '../components/ReviewEventChipList';
import {EventReviewVideoTimeline} from '../components/timeline/EventReviewVideoTimeline';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {useReviewEvent} from '../hooks/useReviewEvent';
import {
  isActiveLytxEvent,
  isActiveLytxPreEvent,
  isActiveSurfSightEvent,
} from '../utils/eventDistinctions';

export default function ReviewEvent() {
  const {webSocket} = useEventReviewContext();
  const {
    currentTime,
    duration,
    handleLoadedMetadata,
    handleSliderChange,
    handleTimeUpdate,
    handleVideoEnd,
    playing,
    togglePlay,
    videoRef,
    setPlaying,
    chipListValues,
    handleFullscreen,
  } = useReviewEvent({event: webSocket.events[0]});

  const videoLoading =
    (videoRef.current == null || videoRef.current.readyState < 1) ?? false;
  const videoSrc = useMemo(() => {
    if (
      isActiveLytxEvent(webSocket.events[0]) ||
      isActiveSurfSightEvent(webSocket.events[0])
    ) {
      return webSocket?.events[0]?.data?.media?.accessURI;
    }
    if (isActiveLytxPreEvent(webSocket.events[0])) {
      return webSocket?.events[0]?.data?.data?.signedMp4Uri;
    }
    return null;
  }, [webSocket.events]);

  const nextVideoSrc = useMemo(() => {
    if (webSocket.events.length > 1) {
      if (
        isActiveLytxEvent(webSocket?.events[1]) ||
        isActiveSurfSightEvent(webSocket?.events[1])
      ) {
        return webSocket?.events[1]?.data?.media?.accessURI;
      }
      if (isActiveLytxPreEvent(webSocket.events[1])) {
        return webSocket?.events[1]?.data?.data?.signedMp4Uri;
      }
    }

    return null;
  }, [webSocket.events]);

  return (
    <ReviewContainer
      bgcolor="#EFEFEF"
      m={config.theme.gutterSpacing}
      borderRadius="16px"
      p="36px"
    >
      <OverlayLoader
        loading={webSocket.events.length === 0 && !webSocket.queueEmpty}
        label="Initialising Event Flow"
        glassMorphismOptions={{transparency: 1}}
      />
      <OverlayLoader
        loading={webSocket.queueEmpty && webSocket.events.length === 0}
        label="No Events Left In Queue, Getting More Events..."
        glassMorphismOptions={{transparency: 1}}
      />
      <Stack position="relative">
        <div className="event-container">
          {videoSrc != null && (
            <>
              <OverlayLoader
                loading={videoLoading}
                label="Loading video"
                glassMorphismOptions={{transparency: 1}}
              />
              <ClipDetailChips label={webSocket.activeEventType} />
              <ClipDetailChips label="1.5x" sx={{top: '48px'}} />

              {}
              <StyledVideo
                src={videoSrc}
                className="w-full"
                onLoadedMetadata={handleLoadedMetadata}
                onTimeUpdate={handleTimeUpdate}
                ref={videoRef}
                onPlay={() => {
                  setPlaying(true);
                }}
                onEnded={handleVideoEnd}
                style={{
                  width: '100%',
                  maxHeight: '50dvh',
                  borderRadius: '16px',
                  objectFit:
                    webSocket.activeEventType !== 'Lytx'
                      ? 'initial'
                      : 'contain',
                }}
                controlsList="nodownload"
              >
                Your browser does not support the video tag.
              </StyledVideo>
              {nextVideoSrc != null && (
                <video
                  src={nextVideoSrc}
                  muted
                  autoPlay
                  style={{height: '0', opacity: 0}}
                />
              )}
            </>
          )}
        </div>
        <EventReviewVideoTimeline
          forwardDisabled
          currentTime={currentTime}
          duration={duration}
          handleSliderChange={handleSliderChange}
          playing={playing}
          togglePlay={togglePlay}
          handleFullscreen={handleFullscreen}
        />
      </Stack>
      <ReviewEventChipList chipListValues={chipListValues} />
    </ReviewContainer>
  );
}

const ReviewContainer = styled(Stack)(() => ({
  '& > .event-container': {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },

  '&:hover': {
    '& .MuiChip-root': {
      visibility: 'visible',
      opacity: '1',
      right: '12px',
    },
  },
}));

const ClipDetailChips = styled(Chip)(() => ({
  position: 'absolute',
  color: '#FFFFFF',
  fontWeight: 'bold',
  fontSize: '14px',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  top: '12px',
  visibility: 'hidden',
  opacity: '0',
  right: '-12px',
  transition: `visibility 300ms cubic-bezier(0.2, 1.65, 0.3, 1),
     opacity 300ms cubic-bezier(0.2, 1.65, 0.3, 1),
     right 300ms cubic-bezier(0.2, 1.65, 0.3, 1)`,
  '&:last-of-type': {
    transitionDelay: '100ms',
  },
  '& .MuiChip-label': {
    marginBottom: '2px',
  },
}));

const StyledVideo = styled('video')(
  () => css`
    width: 100%;
    max-height: 50dvh;
    border-radius: 16px;

    &:-webkit-full-screen {
      /*noinspection CssInvalidPseudoSelector*/
      &::-webkit-media-controls-timeline,
      &::-webkit-media-controls-current-time-display,
      &::-webkit-media-controls-time-remaining-display,
      &::-webkit-media-controls-timeline-container,
      &::-webkit-media-controls-volume-slider,
      &::-webkit-media-controls-mute-button {
        display: none;
      }
    }

    &:-moz-full-screen {
      /*noinspection CssInvalidPseudoSelector*/
      &::-moz-media-controls-panel {
        display: none;
      }
    }
  `,
);
