import {useCallback, useState} from 'react';

import {catchHandler} from '../helpers/catchHandler';
import {isObjectWithKeys} from '../helpers/unknownValueTypeChecks.ts';
import {useDeletingIdStore} from '../stores/deletingIdStore.ts';
import type {DeleteStatus} from '../types/DeleteStatus';

export const useDelete = <T>(
  deleteHandler: (item: T) => Promise<void>,
  options?: {rowId?: keyof T},
) => {
  const [deleteStatus, setDeleteStatus] = useState<DeleteStatus>('idle');

  const onDelete = useCallback(
    async (item: T) => {
      setDeleteStatus('deleting');
      const itemId = options?.rowId ?? 'id';
      if (isObjectWithKeys(item, itemId)) {
        useDeletingIdStore.setState(
          item[itemId] as string | number | null | undefined,
        );
      }
      try {
        await deleteHandler(item);
        setDeleteStatus('success');
      } catch (error) {
        const {type} = catchHandler(error, 'Failed to delete item.');

        setDeleteStatus(type);
      } finally {
        useDeletingIdStore.setState(null);
      }
    },
    [deleteHandler, options],
  );

  return {onDelete, deleteStatus};
};
