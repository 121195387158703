import {
  type ElementType,
  memo,
  type ReactNode,
  type SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from 'react';

import MuiAutocomplete, {
  AutocompleteInputChangeReason,
  type AutocompleteProps,
  type AutocompleteRenderInputParams,
  type AutocompleteValue,
} from '@mui/material/Autocomplete';
import {type ChipTypeMap} from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {useTheme} from '@mui/material/styles';
import TextField, {type TextFieldProps} from '@mui/material/TextField';
import {type UseAutocompleteProps} from '@mui/material/useAutocomplete';
import {useFormikContext} from 'formik';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';
import {isObjectWithKeys} from '../../helpers/unknownValueTypeChecks';

export interface AutocompleteOption {
  value?: string | number | undefined;
  label?: string | undefined;
  metaData?: string;
}

export type VantageAutocompleteFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> =
  | ({formik: true} & VantageAutocompleteFieldFormikProps<
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >)
  | ({formik?: false} & VantageAutocompleteFieldBaseProps<
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >);

function VantageAutocompleteFieldComponent<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  formik,
  ...props
}: VantageAutocompleteFieldProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  if (formik === true) {
    return <VantageAutocompleteFieldFormik {...props} />;
  }

  return <VantageAutocompleteFieldBase {...props} />;
}

export const VantageAutocompleteField = memo(
  VantageAutocompleteFieldComponent,
) as typeof VantageAutocompleteFieldComponent;

export type VantageAutocompleteFieldFormikProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = VantageAutocompleteFieldBaseProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>;

function VantageAutocompleteFieldFormik<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  disabled = false,
  onInputChange,
  ...props
}: VantageAutocompleteFieldFormikProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {value} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);
  const [inputValue, setInputValue] = useState<string | undefined>(undefined);

  const handleChange = useCallback<
    VantageAutocompleteFieldOnChange<Multiple, DisableClearable, FreeSolo>
  >(
    async (_event, newValue) => {
      if (props.multiple === true && newValue == null) {
        await setValue([]);
        return;
      }
      if (
        props.multiple !== true &&
        (newValue == null ||
          (isObjectWithKeys(newValue, 'value') && newValue?.value == null))
      ) {
        await setValue(undefined);
        return;
      }

      await setValue(newValue);
    },
    [props.multiple, setValue],
  );

  const handleInputChange = useCallback<VantageAutocompleteFieldOnInputChange>(
    (event, newValue, reason) => {
      setInputValue(newValue);
      if (onInputChange != null) {
        onInputChange(event, newValue, reason);
      }
    },
    [onInputChange],
  );

  const parsedValue = useMemo(() => {
    if (props.multiple === true && value == null) {
      return [] as unknown as AutocompleteValue<
        AutocompleteOption,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
    if (
      props.multiple !== true &&
      (value == null ||
        (isObjectWithKeys(value, 'value') && value?.value == null))
    ) {
      return {value: 0} as unknown as AutocompleteValue<
        AutocompleteOption,
        Multiple,
        DisableClearable,
        FreeSolo
      >;
    }
    return value as AutocompleteValue<
      AutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo
    >;
  }, [props.multiple, value]);

  return (
    <VantageAutocompleteFieldBase
      error={fieldError != null}
      {...props}
      name={name}
      disabled={fieldDisabled}
      onBlur={handleBlur}
      onChange={handleChange}
      onInputChange={handleInputChange}
      value={parsedValue}
      inputValue={inputValue}
      TextFieldProps={{helperText: fieldError, ...props.TextFieldProps}}
    />
  );
}

export type VantageAutocompleteFieldOnChange<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = NonNullable<
  UseAutocompleteProps<
    AutocompleteOption,
    Multiple,
    DisableClearable,
    FreeSolo
  >['onChange']
>;

export type VantageAutocompleteFieldOnInputChange = (
  event: SyntheticEvent,
  value: string,
  reason: AutocompleteInputChangeReason,
) => void;

const isOptionEqualToValue = (
  option: AutocompleteOption,
  val: AutocompleteOption,
) => {
  if (Object.keys(val).length === 0) {
    return false;
  }
  if (val.value === 0 || val.value == null || !('value' in val)) {
    return 'label' in val && option.label === val.label;
  }
  return option.value === val.value;
};

export type VantageAutocompleteFieldBaseProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = Omit<
  AutocompleteProps<
    AutocompleteOption,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  >,
  'renderInput' | 'onChange'
> &
  Pick<TextFieldProps, 'error' | 'label' | 'placeholder'> & {
    name: string;
    selectAll?: boolean;
    slots?: {TextField?: (props: AutocompleteRenderInputParams) => ReactNode};
    enablePastValues?: boolean;
    TextFieldProps?: TextFieldProps;
    onChange?: VantageAutocompleteFieldOnChange<
      Multiple,
      DisableClearable,
      FreeSolo
    >;
  };

const getOptionLabel = (option: unknown) => {
  if (isObjectWithKeys(option, 'label') && typeof option.label === 'string') {
    return option.label;
  }
  if (typeof option === 'string') {
    return option;
  }
  return '';
};

const getOptionKey = (option: unknown) => {
  if (isObjectWithKeys(option, 'value') && typeof option.value === 'number') {
    return option.value.toString() ?? '';
  }
  if (isObjectWithKeys(option, 'label') && typeof option.label === 'string') {
    return option.label;
  }
  if (typeof option === 'string') {
    return option;
  }
  return '';
};

function VantageAutocompleteFieldBase<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>({
  name,
  selectAll,
  placeholder,
  error,
  label,
  TextFieldProps,
  loading = false,
  slots,
  ...props
}: VantageAutocompleteFieldBaseProps<
  Multiple,
  DisableClearable,
  FreeSolo,
  ChipComponent
>) {
  const theme = useTheme();

  const memoizedListing = useMemo(
    () =>
      selectAll === true
        ? [
            {
              value: 0,
              label: 'Select All',
            },
            ...(props.options ?? []),
          ]
        : props.options,
    [props.options, selectAll],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      if (slots?.TextField != null) {
        return slots.TextField(params);
      }
      return (
        <TextField
          label={label}
          suppressContentEditableWarning
          suppressHydrationWarning
          name={name}
          error={error}
          placeholder={placeholder}
          {...params}
          {...TextFieldProps}
          InputProps={{
            ...params.InputProps,
            ...TextFieldProps?.InputProps,
            style: {
              ...TextFieldProps?.InputProps?.style,
              borderRadius:
                props.multiple === true ? theme.shape.borderRadius : undefined,
            },
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={14} />
                ) : null}
                {TextFieldProps?.InputProps?.endAdornment ??
                  params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [
      TextFieldProps,
      error,
      label,
      loading,
      name,
      placeholder,
      props.multiple,
      slots,
      theme.shape.borderRadius,
    ],
  );

  return (
    <MuiAutocomplete<
      AutocompleteOption,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      getOptionKey={getOptionKey}
      renderInput={renderInput}
      id={name}
      loading={loading}
      fullWidth
      {...props}
      onChange={(event, newValue, reason) => {
        if (
          props.multiple === true &&
          Array.isArray(newValue) &&
          selectAll === true
        ) {
          if (
            newValue?.some((opt) => (opt as AutocompleteOption)?.value === 0) &&
            selectAll
          ) {
            props.onChange?.(
              event,
              props.options as AutocompleteValue<
                AutocompleteOption,
                Multiple,
                DisableClearable,
                FreeSolo
              >,
              reason,
            );
            return;
          }
        }
        props.onChange?.(event, newValue, reason);
      }}
      options={memoizedListing}
      onInputChange={(event, newValue, reason) => {
        if (
          isObjectWithKeys(event?.nativeEvent, 'inputType') &&
          event?.nativeEvent?.inputType === 'insertFromPaste' &&
          props.multiple === true
        ) {
          let splitNewValue = newValue
            ?.split(' ')
            ?.map((value) => ({value, label: value}));

          if (splitNewValue == null || splitNewValue.length === 0) {
            splitNewValue = newValue
              ?.split(',')
              ?.map((value) => ({value, label: value}));
          }

          if (splitNewValue == null || splitNewValue.length === 0) {
            splitNewValue = newValue
              ?.split(', ')
              ?.map((value) => ({value, label: value}));
          }

          if (splitNewValue?.length > 1 && props.multiple === true) {
            (
              props.onChange as VantageAutocompleteFieldOnChange<
                true,
                DisableClearable,
                FreeSolo
              >
            )?.(event, splitNewValue, 'selectOption');
          }
        }

        props.onInputChange?.(event, newValue, reason);
      }}
    />
  );
}
