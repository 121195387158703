import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {ProtectedRoute} from '../../router/ProtectedRoute';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyWhatsNew = lazy(async () => await import('./pages/WhatsNew'));
const LazySOPs = lazy(async () => await import('./pages/SOPs'));
const LazyFunctionalDocs = lazy(
  async () => await import('./pages/FunctionalDocs'),
);
const LazyClientApiDocs = lazy(
  async () => await import('./pages/ClientApiDocs'),
);
const LazyDeveloperApiDocs = lazy(
  async () => await import('./pages/DeveloperApiDocs'),
);
const LazyIntegrationApiDocs = lazy(
  async () => await import('./pages/IntegrationApiDocs'),
);
const LazyTrackingApiDocs = lazy(
  async () => await import('./pages/TrackingApiDocs'),
);

export const documentationRoutes: RouteObject[] = [
  {
    path: '',
    loader: () => redirect(routes.documentation.new),
  },
  {
    path: routes.getRelativePath(
      routes.documentation.new,
      routes.documentation.base,
    ),
    element: <LazyWhatsNew />,
    loader: () => {
      document.title = "What's new on Onroad Vantage?";
      useBreadcrumbStore.setState({breadcrumbs: [{label: "What's new?"}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.documentation.sops,
      routes.documentation.base,
    ),
    element: <LazySOPs />,
    loader: () => {
      document.title = 'Onroad Vantage SOPs';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Vantage SOPs'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.documentation.functional,
      routes.documentation.base,
    ),
    element: (
      <ProtectedRoute permissions={['functionalDocs:view']}>
        <LazyFunctionalDocs />
      </ProtectedRoute>
    ),
    loader: () => {
      document.title = 'Functional Docs';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Functional Docs'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.documentation.apiDocs.base,
      routes.documentation.base,
    ),
    children: [
      {
        path: '',
        loader: () => redirect(routes.documentation.apiDocs.developer),
      },
      {
        path: routes.getRelativePath(
          routes.documentation.apiDocs.client,
          routes.documentation.apiDocs.base,
        ),

        element: (
          <ProtectedRoute permissions={['clientAPIDocs:view']}>
            <LazyClientApiDocs />
          </ProtectedRoute>
        ),
        loader: () => {
          document.title = 'Client API Docs';
          useBreadcrumbStore.setState({
            breadcrumbs: [{label: 'Client API Docs'}],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.documentation.apiDocs.developer,
          routes.documentation.apiDocs.base,
        ),
        element: (
          <ProtectedRoute permissions={['developerAPIDocs:view']}>
            <LazyDeveloperApiDocs />
          </ProtectedRoute>
        ),
        loader: () => {
          document.title = 'Developer API Docs';
          useBreadcrumbStore.setState({
            breadcrumbs: [{label: 'Developer API Docs'}],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.documentation.apiDocs.integration,
          routes.documentation.apiDocs.base,
        ),
        element: (
          <ProtectedRoute permissions={['integrationAPIDocs:view']}>
            <LazyIntegrationApiDocs />
          </ProtectedRoute>
        ),
        loader: () => {
          document.title = 'Integration API Docs';
          useBreadcrumbStore.setState({
            breadcrumbs: [{label: 'Integration API Docs'}],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.documentation.apiDocs.tracking,
          routes.documentation.apiDocs.base,
        ),
        element: (
          <ProtectedRoute permissions={['trackingAPIDocs:view']}>
            <LazyTrackingApiDocs />
          </ProtectedRoute>
        ),
        loader: () => {
          document.title = 'Tracking API Docs';
          useBreadcrumbStore.setState({
            breadcrumbs: [{label: 'Tracking API Docs'}],
          });
          return null;
        },
      },
    ],
  },
];
