import Checkbox, {type CheckboxProps} from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Stack, {type StackProps} from '@mui/material/Stack';
import Switch, {type SwitchProps} from '@mui/material/Switch';
import {useFormikContext} from 'formik';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';

type VantageBooleanFieldProps =
  | ({formik: true} & VantageBooleanFieldFormikProps)
  | ({formik?: false} & VantageBooleanFieldBaseProps);

export function VantageBooleanField({
  formik,
  ...props
}: VantageBooleanFieldProps) {
  if (formik === true) {
    return <VantageBooleanFieldFormik {...props} />;
  }

  return <VantageBooleanFieldBase {...props} />;
}

type VantageBooleanFieldFormikProps = Omit<
  VantageBooleanFieldBaseProps,
  'value'
> &
  (
    | ({type?: 'switch' | undefined} & SwitchProps)
    | ({type?: 'checkbox' | undefined} & CheckboxProps)
  );
function VantageBooleanFieldFormik({
  name,
  disabled = false,
  ...props
}: VantageBooleanFieldFormikProps) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {value} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  return (
    <VantageBooleanFieldBase
      helperText={fieldError}
      error={fieldError != null}
      {...props}
      onBlur={handleBlur}
      name={name}
      onChange={async (_event, checked) => {
        await setValue(checked);
      }}
      checked={value === true}
      disabled={fieldDisabled}
    />
  );
}

type VantageBooleanFieldBaseProps = {
  name: string;
  label?: string;
  helperText?: string | null;
  StackProps?: StackProps;
  error?: boolean;
} & (
  | ({type?: 'switch' | undefined} & SwitchProps)
  | ({type?: 'checkbox' | undefined} & CheckboxProps)
);

function VantageBooleanFieldBase({
  label,
  name,
  StackProps,
  slotProps,
  error = false,
  helperText,
  ...props
}: VantageBooleanFieldBaseProps) {
  return (
    <Stack {...StackProps}>
      <FormControlLabel
        sx={{whiteSpace: 'nowrap'}}
        control={
          props.type === 'checkbox' ? (
            <Checkbox id={name} {...props} name={name} />
          ) : (
            <Switch
              id={name}
              {...props}
              size={props.size === 'small' ? 'small' : 'medium'}
              name={name}
            />
          )
        }
        label={label}
      />
      <FormHelperText sx={{whiteSpace: 'nowrap'}} error={error}>
        {helperText}
      </FormHelperText>
    </Stack>
  );
}
