import {type SubNode} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import {nodeApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

interface DeleteProps extends DeleteParams {
  nodeId: number;
}
export const useDeleteSiteSubSite = ({removeItem, nodeId}: DeleteProps) => {
  return useDelete<SubNode>(async ({id}) => {
    if (id == null) {
      toast(' ID not found', {type: 'warning'});
      return;
    }
    await nodeApi.apiNodeNodeIdSubNodeSubNodeIdDelete({
      nodeId,
      subNodeId: id,
    });
    removeItem(id);
  });
};
