import {useCallback, useEffect} from 'react';

import {useSearchParams} from 'react-router-dom';

import {useEventReviewContext} from '../contexts/EventReviewContext';
import {type TableLookup} from '../pages/EventFeedback';

export const useCheckboxSelectOnKey = ({
  lookupTable,
}: {
  lookupTable: TableLookup;
}) => {
  const {
    keyBuffer,
    setKeyBuffer,
    handleLateClip,
    handleNoIssue,
    handleFaultyClip,
  } = useEventReviewContext();
  const [searchParams] = useSearchParams();

  const handleKeyBuffer = useCallback(() => {
    if (searchParams.get('notifications') !== null) return;

    if (keyBuffer == null || keyBuffer === '') return;

    switch (keyBuffer) {
      case 'lc':
        handleLateClip();
        setKeyBuffer('');
        break;
      case 'n':
        handleNoIssue();
        setKeyBuffer('');
        break;
      case 'ce':
        handleFaultyClip();
        setKeyBuffer('');
        break;
      default: {
        const checkbox = lookupTable[keyBuffer];
        if (checkbox != null) {
          const element = document.querySelector<HTMLInputElement>(
            `input[name="__lookupKey: ${checkbox.shortcut}__${checkbox.name}"]`,
          );
          element?.click();
          setKeyBuffer('');
        }
        break;
      }
    }

    if (keyBuffer?.length > 2) {
      if (Number.isNaN(Number(keyBuffer[2]))) {
        setKeyBuffer(keyBuffer.slice(2));
      } else {
        setKeyBuffer('');
      }
    }
  }, [
    searchParams,
    keyBuffer,
    lookupTable,
    handleLateClip,
    setKeyBuffer,
    handleNoIssue,
    handleFaultyClip,
  ]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.code === 'Space') {
        return;
      }
      const key = event.key.toLowerCase();
      setKeyBuffer((prev) => prev + key);
    };

    const bufferTimeout = setTimeout(handleKeyBuffer, 300);

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
      clearTimeout(bufferTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyBuffer, handleKeyBuffer]);
};
