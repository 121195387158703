import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyVehicleGroups = lazy(
  async () => await import('./pages/VehicleGroups'),
);
const LazyVehicleGroupAdd = lazy(
  async () => await import('./pages/VehicleGroupAdd'),
);
const LazyVehicleGroup = lazy(async () => await import('./pages/VehicleGroup'));

export const vehicleGroupRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.vehicleGroup.list)},
  {
    path: routes.getRelativePath(
      routes.vehicleGroup.list,
      routes.vehicleGroup.base,
    ),
    element: <LazyVehicleGroups />,
    loader: () => {
      document.title = 'Vehicle Groups';
      useBreadcrumbStore.setState({breadcrumbs: [{label: 'Vehicle Groups'}]});
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.vehicleGroup.add,
      routes.vehicleGroup.base,
    ),
    element: <LazyVehicleGroupAdd />,
    loader: () => {
      document.title = 'Add Vehicle Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicle Groups', path: routes.vehicleGroup.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.vehicleGroup.vehicleGroupId,
      routes.vehicleGroup.base,
    ),
    element: <LazyVehicleGroup />,
    loader: () => {
      document.title = 'View Vehicle Group';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Vehicle Groups', path: routes.vehicleGroup.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
