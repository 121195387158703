import {darken, lighten, styled, useTheme} from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';

import {config} from './config';

import 'react-toastify/dist/ReactToastify.css';

const StyledToastContainer = styled('div')(({theme}) => ({
  '.Toastify__toast-container': {
    bottom: 0,
  },
  '.Toastify__toast': {
    ...theme.typography.body1,
    backgroundColor:
      theme.palette.mode === 'light'
        ? darken(theme.palette.background.default, 0.04)
        : lighten(theme.palette.background.default, 0.04),
    minHeight: theme.spacing(6),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: theme.shape.borderRadius,
  },
  '.Toastify__toast--success': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.success.main,
    },
    '& .Toastify__progress-bar': {
      backgroundColor: theme.palette.success.main,
    },
  },
  '.Toastify__toast--error': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.error.main,
    },
    '& .Toastify__progress-bar': {
      backgroundColor: theme.palette.error.main,
    },
  },
  '.Toastify__toast--warning': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.warning.main,
    },
    '& .Toastify__progress-bar': {
      backgroundColor: theme.palette.warning.main,
    },
  },
  '.Toastify__toast--info': {
    '& .Toastify__toast-icon > svg': {
      fill: theme.palette.info.main,
    },
    '& .Toastify__progress-bar': {
      backgroundColor: theme.palette.info.main,
    },
  },
}));

export function Toastify() {
  const theme = useTheme();
  return (
    <StyledToastContainer>
      <ToastContainer
        position="bottom-left"
        autoClose={config.toastyAutoHideDuration}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        limit={3}
        closeButton={false}
        theme={theme.palette.mode}
      />
    </StyledToastContainer>
  );
}
