import {useRef, useMemo, type ReactNode, type ReactElement} from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type BehaviourOutcomeCalculate} from '@onroadvantage/onroadvantage-api';

import {Loader} from '../../common/components/loader/Loader';

export function BehaviourOutcomesChipList({
  outcomes,
}: {
  outcomes?: BehaviourOutcomeCalculate[];
}): ReactElement | null {
  const containerRef = useRef<HTMLDivElement>(null);

  const chips = useMemo(() => {
    let hasFatigueCriticalAdded: boolean = false;
    const hasFatigueCritical = outcomes?.some(
      (outcome) => outcome.name === 'Fatigue - Critical',
    );

    return outcomes?.reduce<ReactNode[]>((acc, outcome) => {
      if (outcome?.name == null || outcome.name === '') {
        return acc;
      }

      if (
        hasFatigueCritical === true &&
        outcome.name === 'Fatigue - Possible'
      ) {
        return acc;
      }

      if (outcome.name === 'Fatigue - Critical') {
        if (hasFatigueCriticalAdded) {
          return acc;
        }
        hasFatigueCriticalAdded = true;
      }

      acc.push(
        <Chip
          label={outcome.name}
          key={outcome.id}
          variant="filled"
          sx={{minHeight: '40px'}}
        />,
      );

      return acc;
    }, []);
  }, [outcomes]);

  return (
    <Stack
      display="flex"
      bgcolor="#FFFFFF"
      mt="16px"
      borderRadius="16px"
      flexWrap="wrap"
    >
      <div
        style={{
          width: '100%',
          backgroundColor: '#EFEFEF',
          alignItems: 'center',
          display: 'flex',
          columnGap: '40px',
          justifyContent: 'space-between',
          padding: '8px 20px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <Typography fontSize="16px" fontWeight={600}>
          BEHAVIOUR AND OBSERVATION OUTCOMES
        </Typography>
        {outcomes == null ? (
          <Stack>
            <Loader size={24} />
          </Stack>
        ) : null}
      </div>
      <Stack
        direction="row"
        spacing={1}
        ref={containerRef}
        p="12px"
        flexWrap="wrap"
        rowGap="12px"
        minHeight="64px"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {outcomes != null && outcomes?.length > 0 ? (
          chips
        ) : (
          <Chip
            label=""
            key={0}
            variant="filled"
            sx={{minHeight: '40px', width: '80px'}}
          />
        )}
      </Stack>
    </Stack>
  );
}
