import {useMemo, useState} from 'react';

import {useLocation} from 'react-router-dom';

import {
  type MenuItem,
  menuItems,
} from '../components/navigation/menuItems/menuItems';

const findLabels = (items: MenuItem[], pathname: string): string[] => {
  return items.flatMap((item) => {
    const childMatches =
      item.children != null ? findLabels(item.children, pathname) : [];
    const matches = [];

    if (item.pathname === pathname) {
      matches.push(item.label);
    }

    if (
      childMatches.length > 0 &&
      item.children != null &&
      item.children.length > 0
    ) {
      matches.push(item.label, ...childMatches);
    }

    return matches;
  });
};

const getLabelsFromPathname = (pathname: string) => {
  return findLabels(menuItems, pathname);
};

export const useExpandedMenuItem = () => {
  const {pathname} = useLocation();
  const initialExpandedGroups = useMemo(
    () => getLabelsFromPathname(pathname),
    [pathname],
  );
  const [expandedGroups, setExpandedGroups] = useState<string[]>(
    initialExpandedGroups,
  );
  // const [expandedSubGroups, setExpandedSubGroups] = useState<string[]>([]);
  return {expandedGroups, setExpandedGroups};
};
