import {lazy} from 'react';

import {Outlet, redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {contractEventTypeConfigPromptRoutes} from './contractEventTypeConfigPrompt/contractEventTypeConfigPromptRoutes';
import {splitByCapitals} from '../common/helpers/splitByCapitals';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';
import {eventTypeModels} from '../common/types/EventTypeModel';

const LazyContractEventTypeConfigs = lazy(
  async () => await import('./pages/ContractEventTypeConfigs'),
);

const LazyContractEventTypeConfigAdd = lazy(
  async () => await import('./pages/ContractEventTypeConfigAdd'),
);

const LazyContractEventTypeConfig = lazy(
  async () => await import('./pages/ContractEventTypeConfig'),
);

export const contractEventTypeConfigRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.contractEventTypeConfig.list)},
  {
    path: routes.getRelativePath(
      routes.contractEventTypeConfig.list,
      routes.contractEventTypeConfig.base,
    ),
    element: <LazyContractEventTypeConfigs />,
    loader: ({request}) => {
      const url = new URL(request.url);
      const model =
        url.searchParams.get('model') ?? eventTypeModels.CriticalEventType;
      const splitModel = splitByCapitals(model, {lowercaseAllLetters: true});

      document.title = `Contract ${splitModel} Configs`;
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: `Contract ${splitModel} Configs`}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contractEventTypeConfig.add,
      routes.contractEventTypeConfig.base,
    ),
    element: <LazyContractEventTypeConfigAdd />,
    loader: ({request}) => {
      const url = new URL(request.url);
      const model =
        url.searchParams.get('model') ?? eventTypeModels.CriticalEventType;
      const splitModel = splitByCapitals(model, {lowercaseAllLetters: true});

      document.title = `Add Contract ${splitModel} Config`;
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: `Contract ${splitModel} Configs`,
            path: routes.addQueryParams(routes.contractEventTypeConfig.list, {
              model,
            }),
          },
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.contractEventTypeConfig.contractEventTypeConfigId,
      routes.contractEventTypeConfig.base,
    ),
    children: [
      {
        path: '',
        element: <LazyContractEventTypeConfig />,
        loader: ({request}) => {
          const url = new URL(request.url);
          const model =
            url.searchParams.get('model') ?? eventTypeModels.CriticalEventType;
          const splitModel = splitByCapitals(model, {
            lowercaseAllLetters: true,
          });

          document.title = `View Contract ${splitModel} Config`;
          useBreadcrumbStore.setState({
            breadcrumbs: [
              {
                label: `Contract ${splitModel} Configs`,
                path: routes.addQueryParams(
                  routes.contractEventTypeConfig.list,
                  {
                    model,
                  },
                ),
              },
              {label: 'View'},
            ],
          });
          return null;
        },
      },
      {
        path: routes.getRelativePath(
          routes.contractEventTypeConfig.prompt.base,
          routes.contractEventTypeConfig.contractEventTypeConfigId,
        ),
        element: <Outlet />,
        children: contractEventTypeConfigPromptRoutes,
      },
    ],
  },
];
