import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTelematicsConfigs = lazy(
  async () => await import('./pages/TelematicsConfigs'),
);
const LazyTelematicsConfig = lazy(
  async () => await import('./pages/TelematicsConfig'),
);

export const telematicsConfigRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.telematicsConfig.list)},
  {
    path: routes.getRelativePath(
      routes.telematicsConfig.list,
      routes.telematicsConfig.base,
    ),
    element: <LazyTelematicsConfigs />,
    loader: () => {
      document.title = 'Telematics Configs';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Telematics Configs'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.telematicsConfig.telematicsConfigId,
      routes.telematicsConfig.base,
    ),
    element: <LazyTelematicsConfig />,
    loader: () => {
      document.title = 'View Telematics Config';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Telematics Configs', path: routes.telematicsConfig.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
