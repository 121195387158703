import {useMemo} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  Contract,
  Customer,
  PurchaseOrderUpdate,
  type PurchaseOrderOptimisedList,
} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {purchaseOrderApi} from '../../../api';
import {routes} from '../../../routes';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {
  MemoizedVantageTable,
  type VantageTableRowActions,
} from '../../common/components/table/VantageTable';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useFiltering} from '../../common/hooks/useFiltering';
import {useInlineEdit} from '../../common/hooks/useInlineEdit';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {PurchaseOrdersDetail} from '../components/PurchaseOrdersDetail';
import {
  calculateOrdersCompletedPercentage,
  calculateQuantityCompletedPercentage,
} from '../helper/getPercentages';
import {useDeletePurchaseOrder} from '../hooks/useDeletePurchaseOrder';
import {useDownloadPurchaseOrders} from '../hooks/useDownloadPurchaseOrders';
import {useLoadPurchaseOrders} from '../hooks/useLoadPurchaseOrders';

export interface ExtendedPurchaseOrderOptimisedList
  extends Omit<PurchaseOrderOptimisedList, 'contract' | 'customer'> {
  contract: string | Contract | undefined;
  customer: string | Customer | undefined;
}

const tableConfig: VantageTableConfig<ExtendedPurchaseOrderOptimisedList> = {
  columns: [
    {
      name: 'purchaseOrderNumber',
      label: 'Purchase Order Number',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'purchaseOrderType',
      label: 'Purchase Order Type',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'orders',
      label: 'Orders',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      models: 'Contract',
      name: 'contract',
      label: 'Contract',
      type: 'autocomplete',
      enableAdd: true,
      enableFilter: true,
      enableSort: true,
      enableEdit: true,
      enableGrouping: true,
    },
    {
      models: 'Customer',
      name: 'customer',
      label: 'Customer',
      type: 'autocomplete',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
      enableEdit: true,
    },
    {
      name: 'referenceNumber',
      label: 'Reference Number',
      type: 'string',
      enableFilter: true,
      enableEdit: true,
    },
    {
      name: 'totalQuantity',
      label: 'Purchase Quantity',
      type: 'string',
    },
    {
      name: 'actualQuantityCompleted',
      label: 'Quantity Delivered',
      type: 'string',
    },
    {
      name: 'ordersCompleted',
      label: '%Orders Completed',
      type: 'string',

      getValue: (row) => calculateOrdersCompletedPercentage(row),
    },
    {
      name: 'quantityCompleted',
      label: '%Quantity Completed',
      type: 'string',
      getValue: (row) => calculateQuantityCompletedPercentage(row),
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Purchase Order:</Typography>
        <Typography>{row.purchaseOrderNumber}</Typography>
      </Stack>
    </Stack>
  ),
  enableColumnVisibility: true,
  enableColumnGrouping: true,
  enableColumnResizing: true,
  identifier: 'purchaseOrders',
};

export default function PurchaseOrders() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, loadDataStatus, removeItem, reloadData} = useLoadPurchaseOrders({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete, deleteStatus} = useDeletePurchaseOrder({removeItem});
  const {downloadStatus, onDownload} = useDownloadPurchaseOrders();

  const {onInlineEdit, updateStatus} = useInlineEdit(async (rowId, values) => {
    const body: PurchaseOrderUpdate = {};

    if (
      values.contract &&
      typeof values.contract !== 'string' &&
      isObjectWithKeys(values, 'contract') &&
      isObjectWithKeys(values.contract, 'value')
    ) {
      body.contractId = Number(values.contract?.value);
    }

    if (
      values.customer &&
      typeof values.customer !== 'string' &&
      isObjectWithKeys(values, 'customer') &&
      isObjectWithKeys(values.customer, 'value')
    ) {
      body.customerId = Number(values.customer?.value);
    }

    if (
      values.referenceNumber !== undefined &&
      typeof values.referenceNumber === 'string' &&
      isObjectWithKeys(values, 'referenceNumber')
    ) {
      body.referenceNumber = values.referenceNumber;
    }

    if (
      body.contractId !== undefined ||
      body.customerId !== undefined ||
      body.referenceNumber !== undefined
    ) {
      await purchaseOrderApi.apiPurchaseOrderPurchaseOrderIdPatch({
        purchaseOrderId: Number(rowId),
        body,
      });
      await reloadData();
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <VantageTablePaper>
        <OverlayLoader
          loading={loadDataStatus === 'loading'}
          label="Loading purchase orders..."
        />
        <OverlayLoader
          loading={loadDataStatus === 'reloading'}
          label="Reloading purchase orders..."
        />
        <OverlayLoader
          loading={updateStatus === 'submitting'}
          label="Updating purchase order..."
        />
        <OverlayLoader
          loading={deleteStatus === 'deleting'}
          label="Deleting purchase order..."
        />
        <OverlayLoader
          loading={downloadStatus === 'downloading'}
          label="Downloading purchase orders..."
        />
        <MemoizedVantageTable
          config={tableConfig}
          data={data as ExtendedPurchaseOrderOptimisedList[]}
          rowId="id"
          sorting={tableSorting}
          pagination={tablePagination}
          filtering={tableFiltering}
          grouping={[{columnName: 'customers'}]}
          actions={useMemo(
            () => ({
              onDownload,
              onAdd: hasPermissions('purchaseOrder:add')
                ? () => {
                    navigate(routes.purchaseOrder.add);
                  }
                : undefined,
            }),
            [hasPermissions, navigate, onDownload],
          )}
          rowActions={useMemo<
            VantageTableRowActions<ExtendedPurchaseOrderOptimisedList>
          >(
            () => ({
              onNavigate: ({id}) =>
                id != null
                  ? routes.replaceParams(routes.purchaseOrder.purchaseOrderId, {
                      purchaseOrderId: id,
                    })
                  : null,
              onDelete: hasPermissions('purchaseOrder:delete')
                ? onDelete
                : undefined,
              onInlineEdit,
            }),
            [hasPermissions, onDelete, onInlineEdit],
          )}
          rowDetail={PurchaseOrdersDetail}
        />
      </VantageTablePaper>
    </div>
  );
}
