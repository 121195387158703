import {lazy, Suspense} from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/ErrorRounded';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {useNavigate} from 'react-router-dom';

import {AuthCard} from './AuthCard';
import {AuthenticationLogo} from './AuthenticationLogo';
import {routes} from '../../../routes';
import {LoadingText} from '../../common/components/loader/LoadingText';

const LazyAppShellAnimatedBackground = lazy(
  async () =>
    await import('../../common/components/appShell/AppShellAnimatedBackground'),
);

interface AuthLoaderProps {
  message: string;
  status: 'loading' | 'success' | 'error';
}

export function AuthStateMessage({message, status}: AuthLoaderProps) {
  const navigate = useNavigate();
  let color: string;
  switch (status) {
    case 'success':
      color = 'success';
      break;
    case 'error':
      color = 'error';
      break;
    case 'loading':
    default:
      color = 'text.secondary';
  }
  return (
    <Stack justifyContent="center" alignItems="center" flex={1}>
      <Suspense fallback={null}>
        <LazyAppShellAnimatedBackground />
      </Suspense>
      <AuthCard>
        <AuthenticationLogo />
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={1}
          mt={1}
          mb={1.5}
        >
          <LoadingText
            variant="h6"
            color={color}
            textAlign="center"
            fontWeight={600}
            loading={status === 'loading'}
          >
            {message}
          </LoadingText>
          {status === 'error' && <ErrorIcon fontSize="large" color="error" />}
          {status === 'success' && (
            <CheckCircleIcon fontSize="large" color="success" />
          )}
        </Stack>
        {status === 'error' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(routes.auth.login);
            }}
          >
            Go to Login
          </Button>
        )}
        {status === 'success' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              navigate(routes.home);
            }}
          >
            Go Home
          </Button>
        )}
      </AuthCard>
    </Stack>
  );
}
