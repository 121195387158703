import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {type InferType, object} from 'yup';

import {TripAddStopsExistingOrdersAutocomplete} from './TripAddStopsExistingOrdersAutocomplete';
import {TripAddStopsTripDetails} from './TripAddStopsTripDetails';
import {requiredMultipleListing} from '../../../common/components/form/validationSchemas';
import {VantageForm} from '../../../common/components/form/VantageForm';
import {VantageFormActions} from '../../../common/components/form/VantageFormActions';
import {VantageFormContent} from '../../../common/components/form/VantageFormContent';
import {VantageFormSubmit} from '../../../common/components/form/VantageFormSubmit';
import {useTripAddDetailsContext} from '../../contexts/TripAddDetailsContext';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tripAddStopsExistingOrdersValidationSchema = object({
  existingOrders: requiredMultipleListing.required('Required'),
});
export type TripAddStopsExistingOrdersValues = InferType<
  typeof tripAddStopsExistingOrdersValidationSchema
>;

interface TripAddStopsExistingOrdersProps {
  onSubmit: (values: TripAddStopsExistingOrdersValues) => void;
}

export function TripAddStopsExistingOrders({
  onSubmit,
}: TripAddStopsExistingOrdersProps) {
  const {onPreviousStep} = useTripAddDetailsContext();

  return (
    <VantageForm
      permission="trip:add"
      initialValues={{existingOrders: []}}
      onSubmit={onSubmit}
    >
      <Stack flex={1} alignItems="flex-start" direction="row">
        <VantageFormContent flex={1}>
          <TripAddStopsExistingOrdersAutocomplete />
        </VantageFormContent>
        <TripAddStopsTripDetails />
      </Stack>
      <VantageFormActions>
        <Button variant="outlined" color="inherit" onClick={onPreviousStep}>
          Back
        </Button>
        <VantageFormSubmit label="Add order(s)" />
      </VantageFormActions>
    </VantageForm>
  );
}
