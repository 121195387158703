import {type Dispatch, type RefObject, type SetStateAction} from 'react';

import {toast} from 'react-toastify';

import {
  type WebSocketEvents,
  type WebSocketEventsTop,
  type SurfSightEvent,
  type ActiveEventType,
  type SurfSightEventData,
  type LytxPreEventData,
} from '../WebsocketTypes';
import {
  isLytxEventData,
  isLytxPreEventData,
  isSurfSightEventData,
  isValidEventData,
} from './eventDistinctions';

interface MessageHandlerParams {
  data: unknown;
  wsRef: RefObject<WebSocket | null>;
  setEvents: Dispatch<
    SetStateAction<
      Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>
    >
  >;
  setQueueEmpty: Dispatch<SetStateAction<boolean>>;
  setEventCount: Dispatch<SetStateAction<number | null>>;
  setActiveEventType: Dispatch<SetStateAction<ActiveEventType | null>>;
  queueEmpty: boolean;
  events: Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>;
}

export const handleWebSocketMessage = ({
  data,
  wsRef,
  setEvents,
  setQueueEmpty,
  setEventCount,
  setActiveEventType,
  queueEmpty,
  events,
}: MessageHandlerParams) => {
  if (isValidEventData(data)) {
    handleDefaultEventMessage({
      data,
      wsRef,
      setEvents,
      setQueueEmpty,
      setEventCount,
      setActiveEventType,
      queueEmpty,
      events,
    });
  }
};

const handleDefaultEventMessage = ({
  data,
  wsRef,
  setEvents,
  setQueueEmpty,
  setEventCount,
  setActiveEventType,
  queueEmpty,
  events,
}: {
  data: WebSocketEventsTop | SurfSightEvent;
  wsRef: RefObject<WebSocket | null>;
  setEvents: Dispatch<
    SetStateAction<
      Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>
    >
  >;
  setQueueEmpty: Dispatch<SetStateAction<boolean>>;
  setEventCount: Dispatch<SetStateAction<number | null>>;
  setActiveEventType: Dispatch<SetStateAction<ActiveEventType | null>>;
  queueEmpty: boolean;
  events: Array<WebSocketEvents | SurfSightEventData | LytxPreEventData>;
}) => {
  switch (data.type) {
    case 'event':
      setEvents((prevEvents) => {
        if (prevEvents.length === 0) {
          if (isLytxEventData(data)) {
            setActiveEventType('Lytx');
          } else if (isSurfSightEventData(data)) {
            setActiveEventType('SurfSight');
          } else if (isLytxPreEventData(data)) {
            setActiveEventType('LytxPreEvent');
          }
        }
        const newEvent =
          prevEvents.length === 0
            ? {...data.data, reviewStartTime: new Date()}
            : data.data;
        return [...prevEvents, newEvent];
      });
      if (queueEmpty) {
        setQueueEmpty(false);
      }
      break;

    case 'no_more_events':
      if (events.length === 0) {
        setTimeout(() => {
          setQueueEmpty(true);
          wsRef.current?.send(JSON.stringify({type: 'event'}));
        }, 5000);
      }
      break;

    case 'event_count':
      setEventCount(Number(data.data));
      break;

    case 'timeout_warning':
      toast(`Session will expire soon if no action is taken`, {
        type: 'warning',
      });
      break;

    case 'disconnect':
      toast(`Session expired`, {
        type: 'warning',
      });
      break;

    case 'error':
      toast(`Error from server: ${data.message}`, {type: 'error'});
      break;

    default:
      toast(`Unknown data type: ${String(data.data) as string}`, {
        type: 'error',
      });
      break;
  }
};
