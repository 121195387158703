import {type WebContactListItem} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import {contactApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteAudience = ({removeItem}: DeleteParams) => {
  return useDelete<WebContactListItem>(async ({id: contactId}) => {
    if (contactId == null) {
      toast('Audience ID not found', {type: 'warning'});
      return;
    }
    await contactApi.apiContactContactIdDelete({contactId});
    removeItem(contactId);
  });
};
