import {type ReactNode, useEffect} from 'react';

import {useAuth} from 'oidc-react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';

import {AppShellContainer} from '../modules/common/components/appShell/AppShellContainer';
import {PermissionsLoader} from '../modules/common/components/loader/PermissionsLoader';
import {NotAuthorized} from '../modules/common/components/NotAuthorized';
import {useAuthStore} from '../modules/common/stores/authStore';
import {useUserStore} from '../modules/common/stores/userStore';
import {type Permission} from '../permissions';
import {routes} from '../routes';

interface ProtectedRouteProps {
  permissions: Permission[];
  matchMethod?: 'one-of' | 'all';
  unauthorisedMessage?: string;
  children?: ReactNode;
}

export function ProtectedRoute({
  permissions,
  matchMethod = 'all',
  unauthorisedMessage,
  children,
}: ProtectedRouteProps) {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const {isLoading} = useAuth();
  const authStatus = useAuthStore((state) => state.authStatus);
  const setToken = useAuthStore((state) => state.setToken);
  const setSsoToken = useAuthStore((state) => state.setSsoToken);
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const hasOneOfPermissions = useUserStore(
    (state) => state.hasOneOfPermissions,
  );

  useEffect(() => {
    if (authStatus === 'unauthenticated') {
      setToken(null);
      setSsoToken(null);
      if (pathname !== routes.auth.base) {
        navigate(
          routes.addQueryParams(routes.auth.login, {redirect: pathname}),
          {replace: true},
        );
      }
    }
  }, [authStatus, navigate, pathname, setSsoToken, setToken]);

  if (authStatus === 'authenticating' || isLoading) {
    return <PermissionsLoader />;
  }

  if (
    matchMethod === 'one-of'
      ? hasOneOfPermissions(...permissions)
      : hasPermissions(...permissions)
  ) {
    return children ?? <Outlet />;
  }

  return (
    <AppShellContainer>
      <NotAuthorized message={unauthorisedMessage} />
    </AppShellContainer>
  );
}
