import {type Dispatch, type SetStateAction} from 'react';

import {type OrderLine} from '@onroadvantage/onroadvantage-api';

import {orderLineApi} from '../../../api';
import {isObjectWithKeys} from '../../common/helpers/unknownValueTypeChecks';
import {useDelete} from '../../common/hooks/useDelete';
import {useInlineAdd} from '../../common/hooks/useInlineAdd';
import {useInlineEdit} from '../../common/hooks/useInlineEdit';

export const useEditOrderLines = ({
  id: orderId,
  reload,
  updateState,
}: {
  id: number;
  reload?: () => Promise<void>;
  updateState?: Dispatch<SetStateAction<OrderLine[]>>;
}) => {
  const {addStatus, onInlineAdd} = useInlineAdd(async (values) => {
    if (
      !isObjectWithKeys(values, 'product') ||
      !isObjectWithKeys(values.product, 'value') ||
      typeof values.product.value !== 'number'
    ) {
      throw new Error('Product is required', {cause: 'warning'});
    }

    if (
      !isObjectWithKeys(values, 'product') ||
      !isObjectWithKeys(values.product, 'label') ||
      typeof values.product.label !== 'string'
    ) {
      throw new Error('Product Name is required', {cause: 'warning'});
    }

    if (
      !isObjectWithKeys(values, 'quantity') ||
      Number.isNaN(parseFloat(values.quantity?.toString() ?? '0'))
    ) {
      throw new Error('quantity is required', {cause: 'warning'});
    }

    const response = await orderLineApi.apiOrderLinePost({
      body: {
        orderId,
        productId: values.product.value,
        productName: values.product.label,
        quantity: parseFloat(String(values.quantity)),
        actualQuantity:
          isObjectWithKeys(values, 'actualQuantity') &&
          !Number.isNaN(parseFloat(values.actualQuantity?.toString() ?? '0'))
            ? parseFloat(values.actualQuantity?.toString() ?? '0')
            : undefined,
        // actualLoadedQuantity: parseFloat(String(values.actualLoadedQuantity)), add actualLoadedQuantity to  OrderLinePost
      },
    });
    if (updateState != null) {
      updateState((prevState) => [...prevState, response]);
    }
    await reload?.();
  });

  const {updateStatus, onInlineEdit} = useInlineEdit(
    async (rowId, newValues) => {
      const response = await orderLineApi.apiOrderLineOrderLineIdPatch({
        orderLineId: parseInt(String(rowId), 10),
        body: {
          orderId,
          productId:
            isObjectWithKeys(newValues, 'product') &&
            typeof newValues.product === 'number'
              ? newValues.product
              : undefined,
          productName:
            isObjectWithKeys(newValues, 'productName') &&
            typeof newValues.productName === 'string'
              ? newValues.productName
              : undefined,
          quantity:
            isObjectWithKeys(newValues, 'quantity') &&
            !Number.isNaN(parseFloat(newValues.quantity?.toString() ?? '0'))
              ? parseFloat(newValues.quantity?.toString() ?? '0')
              : undefined,
          actualQuantity:
            isObjectWithKeys(newValues, 'actualQuantity') &&
            !Number.isNaN(
              parseFloat(newValues.actualQuantity?.toString() ?? '0'),
            )
              ? parseFloat(newValues.actualQuantity?.toString() ?? '0')
              : undefined,
          actualLoadedQuantity:
            isObjectWithKeys(newValues, 'actualLoadedQuantity') &&
            !Number.isNaN(
              parseFloat(newValues.actualLoadedQuantity?.toString() ?? '0'),
            )
              ? parseFloat(newValues.actualLoadedQuantity?.toString() ?? '0')
              : undefined,
        },
      });

      if (updateState != null) {
        updateState((prevState) =>
          prevState.map((item) => (item.id === response.id ? response : item)),
        );
      }
      await reload?.();
    },
  );

  const {onDelete, deleteStatus} = useDelete<OrderLine>(
    async ({id: orderLineId}) => {
      if (orderLineId == null) {
        throw new Error('Order Line ID not found', {cause: 'warning'});
      }
      if (orderLineId !== 0) {
        await orderLineApi.apiOrderLineOrderLineIdDelete({
          orderLineId,
        });
      }
      if (updateState != null) {
        updateState((prevState) =>
          prevState.filter((item) => item.id !== orderLineId),
        );
      }
      await reload?.();
    },
  );

  return {
    onInlineAdd,
    addStatus,
    updateStatus,
    onInlineEdit,
    onDelete,
    deleteStatus,
  };
};
