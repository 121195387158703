import {useCallback, useEffect, useMemo} from 'react';

import {useLocation} from 'react-router-dom';

export const usePreventNavigation = () => {
  const {pathname} = useLocation();

  const isBlocked = useMemo(
    () => pathname.includes('eventReview') && pathname !== '/eventReview',
    [pathname],
  );

  const preventRefresh = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
  }, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (
      event.key === 'F5' ||
      (event.ctrlKey && event.key === 'r') ||
      (event.metaKey && event.key === 'r')
    ) {
      event.preventDefault();
    }
  }, []);

  const handlePopState = useCallback((event: PopStateEvent) => {
    event.preventDefault();
    window.history.pushState(null, '', window.location.pathname);
  }, []);

  useEffect(() => {
    if (!isBlocked) return;

    window.history.pushState(null, '', window.location.pathname);

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('beforeunload', preventRefresh);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('beforeunload', preventRefresh);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBlocked]);
};
