import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyTasks = lazy(async () => await import('./pages/Tasks'));
const LazyTask = lazy(async () => await import('./pages/Task'));

export const taskRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.task.list)},
  {
    path: routes.getRelativePath(routes.task.list, routes.task.base),
    element: <LazyTasks />,
    loader: () => {
      document.title = 'Tasks';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Tasks'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.task.taskId, routes.task.base),
    element: <LazyTask />,
    loader: () => {
      document.title = 'View Task';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Tasks', path: routes.task.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
