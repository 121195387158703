import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyDocumentTemplates = lazy(
  async () => await import('./pages/DocumentTemplates'),
);
const LazyDocumentTemplateAdd = lazy(
  async () => await import('./pages/DocumentTemplateAdd'),
);
const LazyDocumentTemplate = lazy(
  async () => await import('./pages/DocumentTemplate'),
);

export const documentTemplateRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.documentTemplate.list)},
  {
    path: routes.getRelativePath(
      routes.documentTemplate.list,
      routes.documentTemplate.base,
    ),
    element: <LazyDocumentTemplates />,
    loader: () => {
      document.title = 'Document Templates';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Document Templates'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.documentTemplate.add,
      routes.documentTemplate.base,
    ),
    element: <LazyDocumentTemplateAdd />,
    loader: () => {
      document.title = 'Add Document Template';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Document Templates',
            path: routes.documentTemplate.list,
          },
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.documentTemplate.documentTemplateId,
      routes.documentTemplate.base,
    ),
    element: <LazyDocumentTemplate />,
    loader: () => {
      document.title = 'View Document Template';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Document Templates',
            path: routes.documentTemplate.list,
          },
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
