import {type RefObject} from 'react';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import IconButton from '@mui/material/IconButton';
import {keyframes, styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import {type FormikProps} from 'formik/dist/types';

import {config} from '../../../config';

export function NextEvent({
  feedbackFormRef,
  nextEventAvailable,
  nextDisabled,
}: {
  feedbackFormRef: RefObject<FormikProps<Record<string, boolean>> | null>;
  nextEventAvailable: boolean;
  nextDisabled: boolean;
}) {
  return (
    <Tooltip
      title={
        <Typography>
          {nextEventAvailable
            ? 'Next Event Available'
            : 'Next Event Not Yet Available'}
        </Typography>
      }
    >
      <NextAvailable
        show={nextEventAvailable}
        onClick={async () => {
          await feedbackFormRef?.current?.submitForm();
        }}
        disabled={nextDisabled}
      >
        <ArrowForwardIcon
          sx={{
            color: 'white',
            height: '24px',
            width: '24px',
          }}
        />
      </NextAvailable>
    </Tooltip>
  );
}

const EventAvailablePulse = keyframes`
0% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 103, 34, 0.6);
    box-shadow: 0 0 0 0 rgba(242, 103, 34, 0.6);
}
70% {
    -moz-box-shadow: 0 0 0 10px rgba(242, 103, 34, 0);
    box-shadow: 0 0 0 10px rgba(242, 103, 34, 0);
}
100% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 103, 34, 0);
    box-shadow: 0 0 0 0 rgba(242, 103, 34, 0);
}
`;

const NextAvailable = styled(IconButton)(({show}: {show: boolean}) => ({
  backgroundColor: show ? config.theme.primaryDefault : '#4c4c4c',
  ':hover': {
    backgroundColor: show ? config.theme.primaryDefault : '#4c4c4c',
  },
  '&.Mui-disabled': {
    backgroundColor: '#4c4c4c',
    opacity: 0.3,
  },
  transition: 'background-color 150ms ease-in-out',
  width: '32px',
  height: '32px',
  borderRadius: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  animation: show ? `${EventAvailablePulse} 1.5s ease-in-out 3` : 'none',
}));
