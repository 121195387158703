import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type NodeType} from '@onroadvantage/onroadvantage-api';
import {useNavigate} from 'react-router-dom';

import {routes} from '../../../routes';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useUserStore} from '../../common/stores/userStore';
import {useDeleteSiteType} from '../hooks/useDeleteSiteType';
import {useLoadNodeTypes} from '../hooks/useLoadNodeTypes';

const tableConfig: VantageTableConfig<NodeType> = {
  columns: [
    {
      name: 'name',
      label: 'Name',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
    {
      name: 'description',
      label: 'Description',
      type: 'string',
      enableFilter: true,
      enableSort: true,
    },
  ],
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Name:</Typography>
        <Typography>{row.name}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Description:</Typography>
        <Typography>{row.description}</Typography>
      </Stack>
    </Stack>
  ),
};

export default function SiteTypes() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData} = useLoadNodeTypes({
    getFilter,
    loadRequestPagination,
    updatePagination,
  });
  const {deleteNodeType} = useDeleteSiteType({reloadData});

  return (
    <VantageTablePaper>
      <VantageTable
        rowId="id"
        config={tableConfig}
        data={data}
        toolbar={{onRefresh: reloadData}}
        actions={{
          onAdd: hasPermissions('siteType:add')
            ? () => {
                navigate(routes.siteType.add);
              }
            : undefined,
        }}
        rowActions={{
          onNavigate: ({id}) =>
            id
              ? routes.replaceParams(routes.siteType.siteTypeId, {
                  siteTypeId: id,
                })
              : null,
          onDelete: hasPermissions('siteType:delete')
            ? deleteNodeType
            : undefined,
        }}
        pagination={tablePagination}
        filtering={tableFiltering}
      />
    </VantageTablePaper>
  );
}
