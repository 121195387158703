import {type Dispatch, type SetStateAction, useCallback} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import CloseIcon from '@mui/icons-material/CloseRounded';
import SaveIcon from '@mui/icons-material/SaveRounded';
import {toast} from 'react-toastify';

import {isObjectWithKeys} from '../../../helpers/unknownValueTypeChecks';
import {TableActionButtonBase} from '../components/TableActionButtonBase';
import type {
  RowChangesType,
  VantageTableActions,
  VantageTableProps,
  VantageTableRowActions,
} from '../VantageTable';

export interface ActionsTypeProviderEditorRowProps<TableData extends object>
  extends Pick<VantageTableProps<TableData>, 'rowId'>,
    Pick<VantageTableActions, 'onInlineAdd'>,
    Pick<VantageTableRowActions, 'onInlineEdit'> {
  rowChanges: RowChangesType<TableData> | undefined;
  addedRows: Array<Record<string, unknown>>;
  setAddedRows: Dispatch<SetStateAction<Array<Record<string, unknown>>>>;
  setEditingRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  setRowChanges: Dispatch<
    SetStateAction<RowChangesType<TableData> | undefined>
  >;
}

export function ActionsTypeProviderEditorRow<TableData extends object>({
  row,
  rowId,
  addedRows,
  setAddedRows,
  setRowChanges,
  setEditingRowIds,
  rowChanges,
  onInlineEdit,
  onInlineAdd,
}: DataTypeProvider.ValueEditorProps &
  ActionsTypeProviderEditorRowProps<TableData>) {
  const handleSave = useCallback(async () => {
    if (
      onInlineEdit != null &&
      isObjectWithKeys(row, rowId) &&
      ((typeof row[rowId] === 'number' && row[rowId] > 0) ||
        typeof row[rowId] === 'string')
    ) {
      const id = row[rowId] as string;
      const currentRowChanges = rowChanges?.[row[rowId]];
      if (currentRowChanges != null) {
        const response = await onInlineEdit(
          id as string | number,
          currentRowChanges as Record<string, unknown>,
        );
        if (response !== 'catch') {
          setEditingRowIds((prev) => prev.filter((r) => r !== row[rowId]));
        }
      } else {
        toast('No changes to save', {type: 'info'});
      }

      setRowChanges((prev) =>
        prev == null
          ? undefined
          : Object.fromEntries(
              Object.entries(prev).filter(([key]) => key !== row[rowId]),
            ),
      );
    } else if (onInlineAdd != null && addedRows.length > 0) {
      // Currently only cater for 1 row at a time
      const response = await onInlineAdd(addedRows[0]);
      if (response !== 'catch') {
        setAddedRows((prev) =>
          prev.filter((r) => r[rowId as string] !== row[rowId]),
        );
      }
    }
  }, [
    addedRows,
    onInlineAdd,
    onInlineEdit,
    row,
    rowChanges,
    rowId,
    setAddedRows,
    setEditingRowIds,
    setRowChanges,
  ]);

  const handleCancel = useCallback(() => {
    if (
      isObjectWithKeys(row, rowId) &&
      ((typeof row[rowId] === 'number' && row[rowId] > 0) ||
        typeof row[rowId] === 'string')
    ) {
      setEditingRowIds((prev) => prev.filter((r) => r !== row[rowId]));
      setRowChanges((prev) =>
        prev == null
          ? undefined
          : Object.fromEntries(
              Object.entries(prev).filter(([key]) => key !== row[rowId]),
            ),
      );
    } else {
      setAddedRows((prev) =>
        prev.filter((r) => r[rowId as string] !== row[rowId]),
      );
    }
  }, [row, rowId, setAddedRows, setEditingRowIds, setRowChanges]);

  return (
    <>
      <TableActionButtonBase
        data-cy="TableActionsSave"
        onClick={handleSave}
        loading={false}
      >
        <SaveIcon />
      </TableActionButtonBase>
      <TableActionButtonBase
        data-cy="TableActionsCancel"
        onClick={handleCancel}
        loading={false}
      >
        <CloseIcon />
      </TableActionButtonBase>
    </>
  );
}
