import {useCallback, useState} from 'react';

import type {DataTypeProvider} from '@devexpress/dx-react-grid';
import Checkbox from '@mui/material/Checkbox';

import {type VantageTableColumn} from '../hooks/useVantageTableConfig';

interface ValueEditorTextFieldFilterProps<TableData extends object> {
  columnConfig: VantageTableColumn<TableData>;
}

export function ValueEditorBooleanFilter<TableData extends object>({
  value,
  columnConfig,
  onValueChange,
  onBlur,
  onFocus,
  autoFocus,
  disabled,
}: Omit<DataTypeProvider.ValueEditorProps, 'column'> &
  ValueEditorTextFieldFilterProps<TableData>) {
  const [checked, setChecked] = useState<boolean | undefined>(value);

  const handleChange = useCallback(() => {
    let newValue: boolean | undefined;

    switch (checked) {
      case true:
        newValue = false;
        break;
      case false:
        newValue = undefined;
        break;
      case undefined:
        newValue = true;
        break;
      default:
        newValue = undefined;
    }

    onValueChange(newValue);
    setChecked(newValue);
  }, [checked, onValueChange]);

  return (
    <Checkbox
      disabled={columnConfig.enableFilter !== true || disabled}
      checked={checked ?? false}
      indeterminate={checked === undefined}
      onChange={handleChange}
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      autoFocus={autoFocus}
    />
  );
}
