import {toast} from 'react-toastify';

import {purchaseOrderApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';
import {ExtendedPurchaseOrderOptimisedList} from '../pages/PurchaseOrders';

export const useDeletePurchaseOrder = ({removeItem}: DeleteParams) => {
  return useDelete<ExtendedPurchaseOrderOptimisedList>(async ({id}) => {
    if (id == null) {
      toast('Purchase Order Id  not found', {type: 'warning'});
      return;
    }
    await purchaseOrderApi.apiPurchaseOrderPurchaseOrderIdDelete({
      purchaseOrderId: id,
    });
    removeItem(id);
  });
};
