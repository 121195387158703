import {useCallback, useEffect, useRef, useState} from 'react';

import {capitalizeFirstLetter} from '../helpers/capitalizeFirstLetter';
import {catchHandler} from '../helpers/catchHandler';
import {splitByCapitals} from '../helpers/splitByCapitals';
import {type DownloadStatus} from '../types/DownloadStatus';

export const useTableDownload = (
  name: string,
  downloadHandler: () => Promise<
    Array<Record<string, string | number | undefined | null>>
  >,
  deps: unknown[] = [],
) => {
  const [downloadStatus, setDownloadStatus] = useState<DownloadStatus>('idle');
  const timeoutRef = useRef<number>(undefined);

  const onDownload = useCallback(async () => {
    setDownloadStatus('downloading');
    try {
      const data = await downloadHandler();

      const titleKeys = Object.keys(data[0]).map((key) =>
        splitByCapitals(capitalizeFirstLetter(key)),
      );

      const refinedData = [];
      refinedData.push(titleKeys);

      data.forEach((item) => {
        refinedData.push(Object.values(item));
      });

      let csvContent = '';

      refinedData.forEach((row) => {
        csvContent += `${row.join(',')}\n`;
      });

      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8,'});
      const objUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', objUrl);
      link.setAttribute('download', `${name}.csv`);
      link.click();

      setDownloadStatus('success');
    } catch (error) {
      catchHandler(error, 'Failed to download list');
    } finally {
      timeoutRef.current = setTimeout(() => {
        setDownloadStatus('idle');
      }, 4000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadHandler, name, ...deps]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return {downloadStatus, onDownload};
};
