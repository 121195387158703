import {useMemo} from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type Behaviour} from '@onroadvantage/onroadvantage-api';

import {VantageBooleanField} from '../../../common/components/form/VantageBooleanField';
import {type CategoryWithData} from '../../pages/EventFeedback';

interface ExtendedObservation extends Behaviour {
  shortcut?: string;
}

export function Observations({
  observations,
  addFormValueToChecklist,
}: {
  observations: Array<CategoryWithData<ExtendedObservation>>;
  addFormValueToChecklist?: (shortcutValue?: string) => void;
}) {
  const formattedContent = useMemo(() => {
    const {length} = observations;

    if (length === 0) {
      return null;
    }

    if (length === 1) {
      const [category, observationData] = observations[0];
      return (
        <Grid item xs={6}>
          <Typography fontSize={16} fontWeight={700} my={0.5}>
            {category}
          </Typography>
          {observationData.map((observation) => {
            return (
              <VantageBooleanField
                key={observation.id}
                name={
                  observation.name != null
                    ? `__lookupKey: ${observation.shortcut}__${
                        observation.name
                      }`
                    : ''
                }
                label={`${observation.name}`}
                type="checkbox"
                onClick={() => {
                  addFormValueToChecklist?.(observation.shortcut);
                }}
                formik
              />
            );
          })}
        </Grid>
      );
    }

    return (
      <>
        <>
          {observations
            .slice(1)
            .reverse()
            .filter((_, index) => index !== 1)
            .map(([category, observationData]) => {
              return (
                <Grid item xs={6} key={category} order="3">
                  <Typography fontSize={16} fontWeight={700} my={0.5}>
                    {category}
                  </Typography>
                  {observationData.map((observation) => {
                    return (
                      <VantageBooleanField
                        key={observation.id}
                        name={
                          observation.name != null
                            ? `__lookupKey: ${observation.shortcut}__${
                                observation.name
                              }`
                            : ''
                        }
                        label={`${observation.name}`}
                        type="checkbox"
                        onClick={() => {
                          addFormValueToChecklist?.(observation.shortcut);
                        }}
                        formik
                      />
                    );
                  })}
                </Grid>
              );
            })}
        </>
        <Grid item xs={6}>
          {observations
            .filter((_, index) => index !== 2)
            .map(([category, observationData]) => {
              return (
                <>
                  <Typography fontSize={16} fontWeight={700} my={0.5}>
                    {category}
                  </Typography>
                  {observationData.map((observation) => {
                    return (
                      <VantageBooleanField
                        key={observation.id}
                        name={
                          observation.name != null
                            ? `__lookupKey: ${observation.shortcut}__${
                                observation.name
                              }`
                            : ''
                        }
                        label={`${observation.name}`}
                        type="checkbox"
                        onClick={() => {
                          addFormValueToChecklist?.(observation.shortcut);
                        }}
                        formik
                      />
                    );
                  })}
                </>
              );
            })}
        </Grid>
      </>
    );
  }, [addFormValueToChecklist, observations]);

  return formattedContent != null ? (
    <Grid item xs={6}>
      <Stack bgcolor="#EFEFEF" borderRadius="16px" p="12px" height="100%">
        <Typography fontSize={16} fontWeight={700} mb="12px">
          OBSERVATIONS
        </Typography>
        <Stack direction="row">{formattedContent}</Stack>
      </Stack>
    </Grid>
  ) : null;
}
