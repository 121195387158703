import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export function ReviewEventChipList({
  chipListValues,
}: {
  chipListValues: Array<string | undefined>;
}) {
  return chipListValues.length > 0 ? (
    <Stack
      display="flex"
      bgcolor="#FFFFFF"
      mt="16px"
      borderRadius="16px"
      flexWrap="wrap"
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        p="12px"
        flexWrap="wrap"
        rowGap="12px"
        minHeight="64px"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {chipListValues.map((val) => (
          <Chip label={val} key={val} />
        ))}
      </Stack>
    </Stack>
  ) : null;
}
