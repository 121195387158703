import {memo} from 'react';

import Button, {ButtonProps} from '@mui/material/Button';
import {styled} from '@mui/material/styles';

import {UseDateRangeReturnType} from './useDateRange';
import {formatDate} from './utils';

interface DateButtonProps
  extends Pick<
    UseDateRangeReturnType,
    'optimisticRange' | 'isOpen' | 'selectionMode' | 'openPicker'
  > {
  type: 'start' | 'end';
}

export const DateButton = memo(function DateButton({
  type,
  optimisticRange,
  isOpen,
  selectionMode,
  openPicker,
}: DateButtonProps) {
  return (
    <DatePickerButton
      onClick={() => openPicker(type)}
      isActive={isOpen && selectionMode === type}
    >
      {formatDate(optimisticRange[`${type}Date`]) ||
        `${type === 'start' ? 'Start' : 'End'} date`}
    </DatePickerButton>
  );
});

const DatePickerButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ButtonProps & {isActive?: boolean}>(({isActive, theme}) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'flex-start',
  gap: theme.spacing(1),
  padding: theme.spacing(0.3, 1),
  border: `2px solid ${isActive ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    borderColor: isActive
      ? theme.palette.primary.main
      : theme.palette.action.hover,
  },
}));
