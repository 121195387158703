import {type RefObject, useMemo, useRef} from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type {Broadcast} from '@onroadvantage/onroadvantage-api';

import type {VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {VantageTooltip} from '../../common/components/VantageTooltip';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {BroadcastsPlay} from '../components/BroadcastsPlay';
import {BroadcastsRowDetail} from '../components/BroadcastsRowDetail';
import {useDeleteBroadcast} from '../hooks/useDeleteBroadcast';
import {useLoadBroadcasts} from '../hooks/useLoadBroadcasts';

const config = (
  audioRef: RefObject<HTMLAudioElement | undefined>,
): VantageTableConfig<Broadcast> => ({
  columns: [
    {
      name: 'heading',
      label: 'Heading',
      type: 'string',
      enableFilter: true,
      enableGrouping: true,
      enableSort: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      enableFilter: true,
      enableGrouping: true,
      enableSort: true,
    },
    {
      name: 'scheduledDateTime',
      label: 'ScheduledDateTime',
      type: 'datetime',
      enableGrouping: true,
    },
    {
      name: 'message',
      label: 'Message',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      getValue: ({message}) =>
        message != null ? (
          <VantageTooltip title={message}>
            <Typography variant="body2">{message}</Typography>
          </VantageTooltip>
        ) : (
          '-'
        ),
    },
    {
      name: 'messageUploadIdentifier',
      label: 'Play',
      type: 'custom',
      getCellValue: (row) => <BroadcastsPlay row={row} audioRef={audioRef} />,
    },
  ],
  identifier: 'broadcasts',
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  deleteMessage: (row) => (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Broadcast heading:</Typography>
        <Typography>{row.heading}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontWeight={700}>Broadcast status:</Typography>
        <Typography>{row.status}</Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography>{row.message}</Typography>
      </Stack>
    </Stack>
  ),
});

export default function Broadcasts() {
  const audioRef = useRef<HTMLAudioElement>(undefined);

  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData, removeItem} = useLoadBroadcasts({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const {onDelete} = useDeleteBroadcast({removeItem});

  const memoizedConfig = useMemo(() => config(audioRef), []);

  return (
    <VantageTablePaper>
      <VantageTable
        config={memoizedConfig}
        data={data}
        rowId="id"
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        toolbar={{onRefresh: reloadData}}
        rowActions={hasPermissions('broadcast:delete') ? {onDelete} : undefined}
        rowDetail={BroadcastsRowDetail}
      />
    </VantageTablePaper>
  );
}
