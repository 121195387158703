import {type ReactNode, useState, useEffect, useRef} from 'react';

import {keyframes, styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const bounceAnimation = keyframes`
    0% { transform: translateX(-50%) translateY(50%); }
    20% { transform: translateX(-50%) translateY(5px); }
    40% { transform: translateX(-50%) translateY(0); }
    60% { transform: translateX(-50%) translateY(5px); }
    80% { transform: translateX(-50%) translateY(0); }
    100% { transform: translateX(-50%) translateY(50%); }
`;

const isValidNetworkInformation = (
  connection: unknown,
): connection is NetworkInformation => {
  return (
    typeof connection === 'object' &&
    connection != null &&
    'effectiveType' in connection
  );
};

const SLOW_NETWORKS: EffectiveConnectionType[] = ['slow-2g', '2g'];

export function NetworkStatusWrapper({children}: {children: ReactNode}) {
  const [isSlow, setIsSlow] = useState(false);
  const [forceVisible, setForceVisible] = useState(false);
  const timeoutRef = useRef<number | null>(null);

  useEffect(() => {
    const connection = navigator.connection as unknown;
    if (!connection || !isValidNetworkInformation(connection)) return () => {};
    const updateConnection = () => {
      const slowEffective = SLOW_NETWORKS.includes(
        connection.effectiveType ?? '4g',
      );
      if (slowEffective) {
        setIsSlow(true);
        setForceVisible(true);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
          setForceVisible(false);
        }, 5000);
      } else {
        setIsSlow(false);
        setForceVisible(false);
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      }
    };
    updateConnection();
    connection.addEventListener('change', updateConnection);
    return () => {
      connection.removeEventListener('change', updateConnection);
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <>
      {children}
      <IndicatorContainer $isSlow={isSlow} $forceVisible={forceVisible}>
        <Typography variant="h5">Slow network detected</Typography>
      </IndicatorContainer>
    </>
  );
}

const IndicatorContainer = styled('div')<{
  $isSlow: boolean;
  $forceVisible: boolean;
}>(({theme, $isSlow, $forceVisible}) => ({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: '300px',
  backgroundColor: theme.palette.warning.main,
  color: theme.palette.warning.contrastText,
  borderRadius: `${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0 0`,
  padding: theme.spacing(1, 2, 1.5),
  textAlign: 'center',
  lineHeight: '80px',
  transition: 'transform 0.3s ease-in-out',
  transform: !$isSlow
    ? 'translateX(-50%) translateY(100%)'
    : 'translateX(-50%) translateY(calc(100% - 15px))',
  animation: $forceVisible
    ? `${bounceAnimation} 1s ease-out forwards infinite`
    : 'none',
  zIndex: theme.zIndex.snackbar,
  '&:hover': {
    transform: 'translateX(-50%) translateY(0)',
  },
}));
