import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyVideoEventsList = lazy(
  async () => await import('./pages/VideoEventList'),
);
const LazyVideoEventDetails = lazy(
  async () => await import('./pages/VideoEventDetails'),
);
const LazyVideoEventVideo = lazy(
  async () => await import('./pages/VideoEventVideoDetail'),
);

export const videoEventRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.videoEvents.list)},
  {
    path: routes.getRelativePath(
      routes.videoEvents.list,
      routes.videoEvents.base,
    ),
    element: <LazyVideoEventsList />,
    loader: () => {
      document.title = 'Video Events';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Video Events'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.videoEvents.eventId,
      routes.videoEvents.base,
    ),
    element: <LazyVideoEventDetails />,
    loader: () => {
      document.title = 'Video Events';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Video Events', path: routes.videoEvents.list}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.videoEvents.video,
      routes.videoEvents.base,
    ),
    element: <LazyVideoEventVideo />,
    loader: () => {
      document.title = 'Video Events';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Video Events', path: routes.videoEvents.list}],
      });
      return null;
    },
  },
];
