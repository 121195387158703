import {DateTime} from 'luxon';

export class DateTimeFormatService {
  // isDate = (d: Date | string): boolean => {
  //   return d instanceof Date;
  // };

  toDateTime = (
    date: Date | DateTime | string | unknown,
  ): DateTime | undefined => {
    if (date instanceof DateTime) {
      return date;
    }
    if (date instanceof Date) {
      return DateTime.fromJSDate(date);
    }
    if (typeof date === 'string') {
      try {
        return DateTime.fromISO(date);
      } catch {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  formatDateTimeToLocale = (
    date: Date | DateTime | string,
    withSeconds = false,
  ): string => {
    const dt = this.toDateTime(date);

    if (dt == null) {
      return '-';
    }

    if (withSeconds) {
      return dt.toLocaleString({...DateTime.DATETIME_SHORT_WITH_SECONDS});
    }
    return dt.toLocaleString({...DateTime.DATETIME_SHORT});
  };

  formatDateToLocale = (date: Date | DateTime | string): string => {
    const dt = this.toDateTime(date);

    if (dt == null) {
      return '-';
    }

    return dt.toLocaleString({...DateTime.DATE_SHORT});
  };

  formatDateTimeToDefault = (
    date: Date | DateTime | string,
    withSeconds = false,
  ): string => {
    const dt = this.toDateTime(date);
    if (dt == null) {
      return '-';
    }
    if (withSeconds) {
      return dt.toFormat('yyyy-MM-dd HH:mm:ss');
    }
    return dt.toFormat('yyyy-MM-dd HH:mm');
  };

  formatDateTime = (
    date: Date | string | null | undefined,
    withSeconds = false,
  ): string => {
    if (date == null) {
      return '-';
    }
    // const dateFormat = SettingService.getSetting(DATE_FORMAT_NAME, GLOBAL_NAME);
    // switch (dateFormat) {
    //   case 'locale':
    //     return this.formatDateTimeToLocale(date, withSeconds);
    //   case 'default':
    //   default:
    return this.formatDateTimeToDefault(date, withSeconds);
    // }
  };

  formatDate = (date: Date | DateTime | string): string => {
    // const dateFormat = SettingService.getSetting(DATE_FORMAT_NAME, GLOBAL_NAME);
    // switch (dateFormat) {
    //   case 'locale':
    //     return this.formatDateToLocale(date);
    //   case 'default':
    //   default:
    return this.formatDateTimeToDefault(date);
    // }
  };
}
