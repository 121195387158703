import {useMemo} from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {useEventReviewContext} from '../contexts/EventReviewContext';

export function ReviewStatistics() {
  const {lookupTableData} = useEventReviewContext();

  const allCategories: [string, {shortcut: string; name: string}[]][] = useMemo(
    () =>
      [
        ...lookupTableData.categorizedBehaviorsWithShortcuts,
        ...lookupTableData.categorizedObservationsWithShortcuts,
        [
          'Quick Actions',
          [
            {shortcut: 'ce', name: 'Clip Error'},
            {shortcut: 'lc', name: 'Late Clip'},
            {shortcut: 'n', name: 'No Issue'},
          ],
        ],
      ].filter(
        (entry): entry is [string, {shortcut: string; name: string}[]] =>
          Array.isArray(entry) &&
          typeof entry[0] === 'string' &&
          Array.isArray(entry[1]),
      ),
    [
      lookupTableData.categorizedBehaviorsWithShortcuts,
      lookupTableData.categorizedObservationsWithShortcuts,
    ],
  );

  return (
    <Box sx={{bgcolor: 'background.paper', p: 3, borderRadius: 2}}>
      <Grid container spacing={2}>
        {allCategories.map(([category, items]) =>
          category?.toLowerCase() !== 'hidden' ? (
            <Grid
              item
              xs={12}
              sm={6}
              md={items.length > 6 ? 8 : 4}
              key={category}
            >
              <StyledCard>
                <CardContent>
                  <CategoryTitle>{category}</CategoryTitle>
                  <Grid container spacing={1}>
                    {items.map((item) => (
                      <Grid
                        item
                        xs={items.length > 6 ? 4 : 12}
                        key={item.shortcut}
                      >
                        <ShortcutItem>
                          <ShortcutKey>{item.shortcut}</ShortcutKey>
                          <Typography fontSize={'16px'}>{item.name}</Typography>
                        </ShortcutItem>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </StyledCard>
            </Grid>
          ) : null,
        )}
      </Grid>
    </Box>
  );
}

const StyledCard = styled(Card)(({theme}) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
}));

const CategoryTitle = styled(Typography)(({theme}) => ({
  fontSize: '1.2rem',
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

const ShortcutItem = styled(Box)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(0.5),
}));

const ShortcutKey = styled(Typography)(({theme}) => ({
  fontWeight: 'bold',
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.divider}`,
  marginRight: theme.spacing(1),
  minWidth: '2.5rem',
  textAlign: 'center',
}));
