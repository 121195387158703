import {lazy, Suspense} from 'react';

import Stack from '@mui/material/Stack';
import {type Theme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import LogoSvg from '../../../assets/onroadVantage.svg';

const LazyWhiteLabelLogo = lazy(
  async () => await import('../../common/components/WhiteLabelLogo'),
);

export function AuthenticationLogo() {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Stack direction="row" justifyContent="center">
      <Suspense
        fallback={
          <img
            src={LogoSvg}
            alt="Onroad Vantage"
            style={{
              height: !isDesktop ? 60 : 75,
              width: !isDesktop ? 250 : 300,
            }}
          />
        }
      >
        <LazyWhiteLabelLogo
          height={!isDesktop ? 60 : 75}
          width={!isDesktop ? 250 : 300}
        />
      </Suspense>
    </Stack>
  );
}
