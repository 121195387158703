import {
  EarlyWarningEventDetailsResponse,
  type EarlyWarningEventPostReviewDetailsResponse,
} from '@onroadvantage/onroadvantage-api';

import {earlyWarningEventApi} from '../../../api';
import {SurfSightEventData, WebSocketEvents} from '../WebsocketTypes.ts';
import {isActiveLytxEvent} from './eventDistinctions.ts';

export interface HindsightParams {
  behaviourIds: number[];
  organisationGroupExternalId?: number;
  preEvent?: boolean;
}

export const fetchHindsightData = async (
  params: HindsightParams,
): Promise<EarlyWarningEventPostReviewDetailsResponse> => {
  const response =
    await earlyWarningEventApi.apiEarlyWarningEventPostReviewDetailsGet(params);

  if (response == null) {
    throw new Error('Empty hindsight data response');
  }

  return response;
};

export const fetchOrganisationGroup = async (
  event?: WebSocketEvents | SurfSightEventData,
  telematicsProviderName: 'Lytx' | 'Surfsight' = 'Lytx',
): Promise<EarlyWarningEventDetailsResponse | undefined> => {
  let eventTriggerId: number | undefined;
  let vehicleId: string | undefined;
  let groupId: string | undefined;

  if (event) {
    if (isActiveLytxEvent(event)) {
      eventTriggerId = event.data.data.eventTriggerId;
      vehicleId = event.data.data.vehicleId;
      groupId = event.data.data.groupId;
    }
  } else {
    return undefined;
  }

  const params: Record<string, unknown> = {telematicsProviderName};
  if (eventTriggerId) params.eventTriggerId = eventTriggerId;
  if (vehicleId) params.vehicleId = vehicleId;
  if (groupId) params.groupId = groupId;

  return earlyWarningEventApi.apiEarlyWarningEventDetailsGet(params);
};
