import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazySpeedTables = lazy(async () => await import('./pages/SpeedTables'));
const LazySpeedTableAdd = lazy(
  async () => await import('./pages/SpeedTableAdd'),
);
const LazySpeedTable = lazy(async () => await import('./pages/SpeedTable'));

export const speedTableRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.speedTable.list)},
  {
    path: routes.getRelativePath(
      routes.speedTable.list,
      routes.speedTable.base,
    ),
    element: <LazySpeedTables />,
    loader: () => {
      document.title = 'Speed Tables';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Speed Tables'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.speedTable.add, routes.speedTable.base),
    element: <LazySpeedTableAdd />,
    loader: () => {
      document.title = 'Add Speed Table';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Speed Tables', path: routes.speedTable.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.speedTable.speedTableId,
      routes.speedTable.base,
    ),
    element: <LazySpeedTable />,
    loader: () => {
      document.title = 'View Speed Table';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Speed Tables', path: routes.speedTable.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
