import {type Dispatch, type SetStateAction, useCallback} from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import {toast} from 'react-toastify';

import {TableActionButtonBase} from '../components/TableActionButtonBase';
import {
  type RowChangesType,
  type VantageTableOnInlineEditAll,
} from '../VantageTable';

interface ActionsTypeProviderEditorHeaderSaveProps<TableData = object> {
  rowChanges: RowChangesType<TableData> | undefined;
  onInlineEdit: VantageTableOnInlineEditAll<TableData>;
  setEditingRowIds: Dispatch<SetStateAction<Array<string | number>>>;
  setRowChanges: Dispatch<
    SetStateAction<RowChangesType<TableData> | undefined>
  >;
}

export function ActionsTypeProviderEditorSaveAll<TableData = object>({
  rowChanges,
  onInlineEdit,
  setEditingRowIds,
  setRowChanges,
}: ActionsTypeProviderEditorHeaderSaveProps<TableData>) {
  const handleSave = useCallback(async () => {
    if (rowChanges != null) {
      const response = await onInlineEdit(rowChanges);

      if (response !== 'catch') {
        setEditingRowIds([]);
        setRowChanges(undefined);
      }
    } else {
      toast('No changes to save', {type: 'info'});
    }
  }, [onInlineEdit, rowChanges, setEditingRowIds, setRowChanges]);

  const handleCancel = useCallback(async () => {
    setEditingRowIds([]);
    setRowChanges(undefined);
  }, [setEditingRowIds, setRowChanges]);

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        Save
      </Button>
      <TableActionButtonBase
        data-cy="TableActionsCancel"
        onClick={handleCancel}
        loading={false}
      >
        <CloseIcon />
      </TableActionButtonBase>
    </>
  );
}
