import {memo, useMemo} from 'react';

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {useLocation} from 'react-router-dom';

import {TopBarBreadcrumbs} from './TopBarBreadcrumbs';
import {TopBarEvents} from './TopBarEvents';
import {getGlassBackground} from '../../../../theme/getGlassBackground';
import {useDateRangeStore} from '../../stores/dateRangeStore';
import {DateRangePicker} from '../dateRangePicker/DateRangePicker';

export const TopBar = memo(function TopBar() {
  const {pathname} = useLocation();
  const {startDate, endDate, setStartDate, setEndDate, setToToday} =
    useDateRangeStore();

  const onDuplicateTab = () => {
    window.open(pathname);
  };

  const disableTopBarForControllers = useMemo(
    () => pathname.includes('eventReview') && pathname !== '/eventReview',
    [pathname],
  );

  return (
    <TopAppBar
      color="inherit"
      position="sticky"
      sx={{pointerEvents: disableTopBarForControllers ? 'none' : 'normal'}}
    >
      <Stack direction="row" spacing={1} px={1.5} py={0.5} alignItems="center">
        <Stack direction="row" alignItems="center" spacing={1} flex={1}>
          <TopBarBreadcrumbs />
          <IconButton size="small" onClick={onDuplicateTab}>
            <ContentCopyRoundedIcon fontSize="inherit" />
          </IconButton>
        </Stack>
        <DateRangePicker
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onChangeToToday={setToToday}
          model
        />
        <TopBarEvents />
      </Stack>
    </TopAppBar>
  );
});

const TopAppBar = styled(AppBar)(({theme}) => ({
  zIndex: 502,
  ...getGlassBackground(theme),
}));
