import {
  type Dispatch,
  type RefObject,
  type SetStateAction,
  useCallback,
} from 'react';

import type {FormikProps} from 'formik';
import {DateTime} from 'luxon';
import {useAuth} from 'oidc-react';
import {useNavigate} from 'react-router-dom';

import type {LookupTablesResult} from './useGenerateLookupTables';
import {routes} from '../../../routes';
import {extractKeyWithSplit} from '../utils/feedbackUtils';
import type {Comment, WebSocketHook} from '../WebsocketTypes';

interface ShortcutSubmissionsProps {
  webSocket: WebSocketHook;
  notificationComments: Comment[];
  setNotificationComments: Dispatch<SetStateAction<Comment[]>>;
  formRef: RefObject<FormikProps<Record<string, boolean>> | null>;
  onEventSelectedShortCuts: string[];
  lookupTableData: LookupTablesResult;
  resetContext: () => void;
}

export const useShortcutSubmissions = ({
  webSocket,
  notificationComments,
  setNotificationComments,
  formRef,
  onEventSelectedShortCuts,
  lookupTableData,
  resetContext,
}: ShortcutSubmissionsProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleNoIssue = useCallback(async () => {
    let values = {};

    for await (const item of onEventSelectedShortCuts) {
      const key = lookupTableData.combinedLookup[item];

      if (key != null) {
        const keyName = `__lookupKey: ${key.shortcut}__${key.name}`;

        values = {
          ...values,
          [keyName]: true,
        };
        await formRef?.current?.setValues({
          ...formRef?.current?.values,
          ...values,
        });
      }
    }
    webSocket.queueReview(
      {...values, '__lookupKey: n__No Issue': true},
      lookupTableData,
      auth,
      notificationComments,
      new Date(),
    );

    navigate(routes.eventReview.review);
    resetContext();
  }, [
    auth,
    formRef,
    lookupTableData,
    navigate,
    notificationComments,
    onEventSelectedShortCuts,
    resetContext,
    webSocket,
  ]);

  const handleFaultyClip = useCallback(async () => {
    const extractedLookupKey = extractKeyWithSplit(
      '__lookupKey: ce__Clip Error',
    );

    if (extractedLookupKey == null) {
      return;
    }

    const behaviorData =
      lookupTableData.behaviorLookupTable[extractedLookupKey];

    if (behaviorData == null) {
      return;
    }

    webSocket.queueReview(
      {'__lookupKey: ce__Clip Error': true},
      lookupTableData,
      auth,
      notificationComments,
      new Date(),
    );
    navigate(routes.eventReview.review);
    resetContext();
  }, [
    auth,
    lookupTableData,
    navigate,
    notificationComments,
    resetContext,
    webSocket,
  ]);

  const handleLateClip = useCallback(() => {
    const commentContainingLateClip = notificationComments.find(
      (comment) => comment.text === 'Could not notify, Late Clip',
    );
    if (commentContainingLateClip != null) {
      setNotificationComments((prev) =>
        prev.filter(
          (prevComment) => prevComment.text !== 'Could not notify, Late Clip',
        ),
      );
    } else {
      setNotificationComments((prev) => [
        {
          text: 'Could not notify, Late Clip',
          commentTime: DateTime.fromJSDate(new Date())
            .setZone('local')
            .toFormat('dd/MM/yyyy HH:mm'),
        },
        ...prev,
      ]);
    }
  }, [notificationComments, setNotificationComments]);

  return {handleNoIssue, handleFaultyClip, handleLateClip};
};
