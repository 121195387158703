import {lazy} from 'react';

import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import SiteTypes from './pages/SiteTypes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazySiteTypeAdd = lazy(async () => await import('./pages/SiteTypeAdd'));
const LazySiteType = lazy(async () => await import('./pages/SiteType'));

export const siteTypeRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.siteType.list)},
  {
    path: routes.getRelativePath(routes.siteType.list, routes.siteType.base),
    element: <SiteTypes />,
    loader: () => {
      document.title = 'Site Types';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Site Types'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(routes.siteType.add, routes.siteType.base),
    element: <LazySiteTypeAdd />,
    loader: () => {
      document.title = 'Add Site Type';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Site Types', path: routes.siteType.list},
          {label: 'Add'},
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.siteType.siteTypeId,
      routes.siteType.base,
    ),
    element: <LazySiteType />,
    loader: () => {
      document.title = 'View Site Type';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {label: 'Site Types', path: routes.siteType.list},
          {label: 'View'},
        ],
      });
      return null;
    },
  },
];
