import {type RefObject, useMemo} from 'react';

import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import {type TextFieldProps} from '@mui/material/TextField';
import {type Listing} from '@onroadvantage/onroadvantage-api';
import {FormikConsumer, type FormikContextType, type FormikProps} from 'formik';
import {mixed, object} from 'yup';

import {ClearEventsPaneSearch} from './ClearEventsPaneSearch';
import {SubmitEventsPaneSearch} from './SubmitEventsPaneSearch';
import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {number} from '../../../common/components/form/validationSchemas';
import {VantageAutocompleteFieldWithListing} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {
  type OnFormSubmit,
  VantageForm,
} from '../../../common/components/form/VantageForm';
import {VantageSelectField} from '../../../common/components/form/VantageSelectField';
import {VantageTextField} from '../../../common/components/form/VantageTextField';

export interface EventsPaneAdvancedSearchFormValues {
  eventTypes?: Listing[] | null | undefined;
  vehicles?: Listing[] | null | undefined;
  vehicleGroups?: Listing[] | null | undefined;
  drivers?: Listing[] | null | undefined;
  contracts?: Listing[] | null | undefined;
  eventId?: number;
  eventIdEventType?: 'Critical Event' | 'Operational Event';
  rangeValue: number;
  rangeUnit: 'Days' | 'Hours';
}

export interface EventsPaneAdvancedSearchFormProps {
  onAdvancedSearch: OnFormSubmit<EventsPaneAdvancedSearchFormValues>;
  onClearSearch: () => void;
  advancedFormInnerRef: RefObject<FormikProps<EventsPaneAdvancedSearchFormValues> | null>;
}

const validationSchema = object({
  rangeValue: number
    .required('Required')
    .min(0, 'Min value 0.')
    .when('rangeUnit', {
      is: 'Days',
      then: (schema) => schema.max(7, '7 days is the max.'),
      otherwise: (schema) => schema.max(24, '24 hours is the max.'),
    }),
  rangeUnit: mixed<EventsPaneAdvancedSearchFormValues['rangeUnit']>()
    .required('Required')
    .oneOf(['Days', 'Hours']),
});

const limitTag = 2;
export function EventsPaneAdvancedSearchForm({
  onAdvancedSearch,
  onClearSearch,
  advancedFormInnerRef,
}: EventsPaneAdvancedSearchFormProps) {
  const theme = useTheme();
  const textFieldProps = useMemo<TextFieldProps>(
    () => ({
      InputProps: {style: {borderRadius: getNestedBorderRadius(theme, 1)}},
    }),
    [theme],
  );

  return (
    <VantageForm<EventsPaneAdvancedSearchFormValues>
      initialValues={{rangeValue: 1, rangeUnit: 'Days'}}
      onSubmit={onAdvancedSearch}
      validationSchema={validationSchema}
      formInnerRef={advancedFormInnerRef}
    >
      <Stack spacing={1.5}>
        <VantageAutocompleteFieldWithListing
          name="eventTypes"
          models="WebEventType"
          label="Event types"
          placeholder="Search and select event types"
          size="small"
          TextFieldProps={textFieldProps}
          limitTags={limitTag}
          multiple
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="vehicles"
          models="Vehicle"
          label="Vehicles"
          placeholder="Search and select vehicles"
          size="small"
          TextFieldProps={textFieldProps}
          limitTags={limitTag}
          multiple
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="vehicleGroups"
          models="VehicleGroup"
          label="Vehicle Groups"
          placeholder="Search and select vehicle groups"
          size="small"
          TextFieldProps={textFieldProps}
          limitTags={limitTag}
          multiple
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="drivers"
          models="Driver"
          label="Drivers"
          placeholder="Search and select drivers"
          size="small"
          TextFieldProps={textFieldProps}
          limitTags={limitTag}
          multiple
          formik
        />
        <VantageAutocompleteFieldWithListing
          name="contracts"
          models="Contract"
          label="Contracts"
          placeholder="Search and select contracts"
          size="small"
          TextFieldProps={textFieldProps}
          limitTags={limitTag}
          multiple
          formik
        />
        <Stack direction="row" spacing={1}>
          <VantageSelectField
            name="eventIdEventType"
            label="Event ID Type"
            options={['Critical Event', 'Operational Event']}
            size="small"
            style={{flex: 1, borderRadius: 1}}
            sx={textFieldProps.InputProps?.style}
            fullWidth={false}
            formik
          />
          <FormikConsumer>
            {({
              values,
            }: FormikContextType<EventsPaneAdvancedSearchFormValues>) => (
              <VantageTextField
                name="eventId"
                label="Event ID"
                size="small"
                type="number"
                sx={{flex: 0.5}}
                InputProps={textFieldProps.InputProps}
                fullWidth={false}
                disabled={values.eventIdEventType == null}
                formik
              />
            )}
          </FormikConsumer>
        </Stack>
        <Stack direction="row" spacing={1}>
          <VantageTextField
            name="rangeValue"
            label="Date range"
            size="small"
            type="number"
            sx={{flex: 1}}
            InputProps={textFieldProps.InputProps}
            fullWidth={false}
            formik
          />
          <VantageSelectField
            name="rangeUnit"
            label="Unit"
            options={['Days', 'Hours']}
            size="small"
            style={{flex: 0.5, borderRadius: 1}}
            sx={textFieldProps.InputProps?.style}
            fullWidth={false}
            formik
          />
        </Stack>
        <Stack direction="row" spacing={1}>
          <SubmitEventsPaneSearch label="Search" />
          <ClearEventsPaneSearch
            variant="outlined"
            color="inherit"
            onReset={onClearSearch}
            label="Clear Search"
          />
        </Stack>
      </Stack>
    </VantageForm>
  );
}
