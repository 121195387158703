import {useState} from 'react';

import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import {type WebMasterTripDebriefListMasterTrip} from '@onroadvantage/onroadvantage-api';

import {routes} from '../../../routes';
import {VantageDialogButton} from '../../common/components/dialog/VantageDialogButton';
import {OverlayLoader} from '../../common/components/loader/OverlayLoader';
import {type VantageTableConfig} from '../../common/components/table/hooks/useVantageTableConfig';
import {VantageTablePaper} from '../../common/components/table/styledComponents/VantageTablePaper';
import {VantageTable} from '../../common/components/table/VantageTable';
import {useFiltering} from '../../common/hooks/useFiltering';
import {usePagination} from '../../common/hooks/usePagination';
import {useSorting} from '../../common/hooks/useSorting';
import {useUserStore} from '../../common/stores/userStore';
import {useApproveTripDebriefs} from '../hooks/useApproveTripDebriefs';
import {useBulkDeleteTrips} from '../hooks/useBulkDeleteTrips';
import {useDownloadTripDebriefs} from '../hooks/useDownloadTripDebriefs';
import {useLoadTripDebriefs} from '../hooks/useLoadTripDebriefs';

const tableConfig: VantageTableConfig<WebMasterTripDebriefListMasterTrip> = {
  columns: [
    {
      name: 'tripNumber',
      label: 'Trip Number',
      type: 'string',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'contractCode',
      label: 'Contract',
      type: 'string',
      enableFilter: true,
      enableSort: true,
      enableGrouping: true,
    },
    {
      name: 'vehicleRegistrationNumber',
      label: 'Vehicle Registration Number',
      type: 'string',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'driverName',
      label: 'Driver Name',
      type: 'string',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'orderNumber',
      label: 'Order Number',
      type: 'string',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'status',
      label: 'Status',
      type: 'string',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'debriefed',
      label: 'Viewed',
      type: 'boolean',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'masterTripDebriefApprovedForBilling',
      label: 'Debriefed',
      type: 'boolean',
      enableSort: true,
      enableFilter: true,
      enableGrouping: true,
    },
    {
      name: 'tripDistance',
      label: 'Planned Kilometers',
      type: 'string',
      getValue: ({tripDistance}) =>
        tripDistance != null ? Math.round(tripDistance * 10) / 10 : 'N/A',
      enableSort: false,
      enableFilter: false,
    },
    {
      name: 'debrief',
      label: 'Debriefed Kilometers',
      type: 'number',
      getValue: ({
        masterTripDebriefManualEndOdometer,
        masterTripDebriefManualStartOdometer,
      }) => {
        if (
          masterTripDebriefManualStartOdometer != null &&
          masterTripDebriefManualEndOdometer != null
        ) {
          return Math.round(
            masterTripDebriefManualEndOdometer -
              masterTripDebriefManualStartOdometer,
          );
        }
        return 0;
      },
      enableSort: false,
      enableFilter: false,
    },
    {
      name: 'masterTripId',
      label: 'Master Trip ID',
      type: 'number',
      hidden: true,
      enableSort: true,
      enableFilter: true,
    },
    {
      name: 'vehicleFleetNumber',
      label: 'Vehicle Fleet Number',
      type: 'string',
      hidden: true,
      enableSort: true,
      enableFilter: true,
    },
    {
      name: 'contractId',
      label: 'Contract ID',
      type: 'number',
      hidden: true,
      enableSort: true,
      enableFilter: true,
    },
    {
      name: 'upliftNodeName',
      label: 'Uplift Site',
      type: 'string',
      hidden: true,
      enableSort: true,
      enableFilter: true,
    },
    {
      name: 'offloadNodeName',
      label: 'Offload Site',
      type: 'string',
      hidden: true,
      enableSort: true,
      enableFilter: true,
    },
    {
      name: 'masterTripDebriefId',
      label: 'Master Trip Debrief ID',
      type: 'number',
      enableSort: true,
      enableFilter: true,
      hidden: true,
    },
  ],
  identifier: 'tripDebriefs',
  enableColumnResizing: true,
  enableColumnGrouping: true,
  enableColumnVisibility: true,
  enableDateRangeToggle: true,
  selection: {
    getSelectableRow: ({
      driverName,
      vehicleRegistrationNumber,
      orderNumber,
    }) => {
      return (
        driverName != null &&
        vehicleRegistrationNumber != null &&
        orderNumber !== '' &&
        orderNumber !== undefined
      );
    },
  },
};

export default function TripDebriefs() {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const {
    setCurrentPage,
    tablePagination,
    loadRequestPagination,
    updatePagination,
  } = usePagination();
  const {tableSorting, loadRequestSorting} = useSorting({setCurrentPage});
  const {tableFiltering, getFilter} = useFiltering({setCurrentPage});
  const {data, reloadData} = useLoadTripDebriefs({
    getFilter,
    loadRequestPagination,
    loadRequestSorting,
    updatePagination,
  });
  const [selectedTrips, setSelectedTrips] = useState<
    WebMasterTripDebriefListMasterTrip[]
  >([]);
  const {approveStatus, onApprove} = useApproveTripDebriefs({
    selectedTrips,
    setSelectedTrips,
    reloadData,
  });
  const {onBulkDelete} = useBulkDeleteTrips({
    selectedTrips,
    setSelectedTrips,
    reloadData,
  });
  const {downloadStatus, onDownload} = useDownloadTripDebriefs();

  return (
    <VantageTablePaper>
      <OverlayLoader
        loading={approveStatus === 'approving'}
        label="Approving trips..."
      />
      <OverlayLoader
        loading={downloadStatus === 'downloading'}
        label="Downloading trip debriefs..."
      />
      <VantageTable
        config={tableConfig}
        data={data}
        rowId="id"
        toolbar={{
          onRefresh: reloadData,
          toolbarCustomAction: (
            <Stack direction="row" spacing={1} pr={1}>
              <VantageDialogButton
                title="Approve"
                size="small"
                variant="contained"
                color="primary"
                dialogTitle="Are you sure you want to approve the selected trips?"
                onAccept={onApprove}
                onDecline={() => {
                  setSelectedTrips([]);
                }}
                disabled={selectedTrips.length === 0}
              />
              {hasPermissions('bulkDeleteTrips:delete') && (
                <VantageDialogButton
                  title="Bulk Delete"
                  size="small"
                  variant="contained"
                  color="error"
                  dialogTitle="Are you sure you want to delete the selected trips?"
                  onAccept={onBulkDelete}
                  acceptProps={{color: 'error'}}
                  onDecline={() => {
                    setSelectedTrips([]);
                  }}
                  disabled={selectedTrips.length === 0}
                />
              )}
              <Button
                size="small"
                variant="outlined"
                color="inherit"
                disabled={selectedTrips.length === 0}
                onClick={() => {
                  setSelectedTrips([]);
                }}
              >
                Cancel
              </Button>
              <Divider orientation="vertical" flexItem />
            </Stack>
          ),
        }}
        pagination={tablePagination}
        sorting={tableSorting}
        filtering={tableFiltering}
        grouping={[{columnName: 'contractCode'}]}
        actions={{
          onDownload,
          selection: {
            selection: selectedTrips,
            onSelectionChange: setSelectedTrips,
          },
        }}
        rowActions={{
          onNavigate: (row) =>
            row.id
              ? routes.replaceParams(routes.tripDebrief.masterTripId, {
                  masterTripId: row.id,
                })
              : null,
          selection: {
            selection: selectedTrips,
            onSelectionChange: setSelectedTrips,
          },
        }}
      />
    </VantageTablePaper>
  );
}
