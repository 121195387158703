import {type Broadcast} from '@onroadvantage/onroadvantage-api';
import {toast} from 'react-toastify';

import {broadcastApi} from '../../../api';
import {useDelete} from '../../common/hooks/useDelete';
import {type DeleteParams} from '../../common/types/DeleteParams';

export const useDeleteBroadcast = ({removeItem}: DeleteParams) => {
  return useDelete<Broadcast>(async ({id}) => {
    if (id == null) {
      toast('Broadcast ID not found', {type: 'warning'});
      return;
    }
    await broadcastApi.apiBroadcastBroadcastIdDelete({
      broadcastId: id,
    });
    removeItem(id);
  });
};
