import LogoutIcon from '@mui/icons-material/Logout';
import Typography from '@mui/material/Typography';
import {useAuth} from 'oidc-react';
import {useNavigate} from 'react-router-dom';

import {MenuItemButton} from './MenuItemButton';
import {routes} from '../../../../routes';
import {useAuthStore} from '../../stores/authStore';
import {useEventStore} from '../../stores/eventStore';
import {useUserStore} from '../../stores/userStore';

export function NavigationLogout() {
  const navigate = useNavigate();
  const {userManager, isLoading} = useAuth();
  const authStatus = useAuthStore((state) => state.authStatus);
  const ssoToken = useAuthStore((state) => state.ssoToken);
  const resetAuthStore = useAuthStore((state) => state.reset);
  const resetUserStore = useUserStore((state) => state.reset);
  const eventsCountTimeoutRef = useEventStore(
    (state) => state.eventsCountTimeoutRef,
  );

  const handleLogout = async () => {
    navigate(routes.auth.login);
    resetAuthStore();
    resetUserStore();
    if (eventsCountTimeoutRef != null) {
      clearTimeout(eventsCountTimeoutRef);
    }
    if (ssoToken != null) {
      await userManager.signoutSilent();
    }
  };
  return (
    <MenuItemButton
      $level={1}
      $isActive={false}
      onClick={handleLogout}
      disabled={authStatus === 'authenticating' || isLoading}
    >
      <LogoutIcon />
      <Typography fontWeight="bold" color="action" flex={1}>
        Logout
      </Typography>
    </MenuItemButton>
  );
}
