import {useEffect, useMemo} from 'react';

import Typography from '@mui/material/Typography';

import {VantageAutocompleteField} from '../../../common/components/form/VantageAutocompleteField';
import {type VantageAutocompleteFieldWithListingProps} from '../../../common/components/form/VantageAutocompleteFieldWithListing';
import {splitByCapitals} from '../../../common/helpers/splitByCapitals';
import {useLoadListing} from '../../../common/hooks/useLoadListing';

export function ReportParameterAutocompleteWithListing({
  models,
  queryOptions,
  disabled,
  ...props
}: VantageAutocompleteFieldWithListingProps<true, undefined, undefined>) {
  const {listing, loadListing, loadListingStatus, onDebounceSearch} =
    useLoadListing(models, queryOptions);

  useEffect(() => {
    if (disabled !== true) {
      void loadListing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabled]);

  const memoizedListing = useMemo(
    () => [
      {
        value: 0,
        label:
          typeof models === 'string'
            ? `All ${models === 'Node' ? 'Site' : models}s`
            : 'All items',
      },
      ...(listing ?? []),
    ],
    [listing, models],
  );

  return (
    <VantageAutocompleteField
      options={memoizedListing}
      onInputChange={onDebounceSearch}
      disabled={disabled}
      limitTags={10}
      {...props}
      loading={loadListingStatus === 'loading'}
      loadingText={
        <Typography color="text.secondary">
          Loading{' '}
          <strong>
            {models instanceof Set
              ? Array.from(models)
                  .map((model) => splitByCapitals(model))
                  .join(', ')
              : splitByCapitals(models)}
          </strong>{' '}
          listing...
        </Typography>
      }
      multiple
      formik
    />
  );
}
