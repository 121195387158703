import {useState} from 'react';

import CloseIcon from '@mui/icons-material/CloseRounded';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {type Theme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

export function LoginAcknowledgement() {
  const classes = useStyles();
  const [isCookiePolicyOpen, setIsCookiePolicyOpen] = useState(false);

  return (
    <>
      <Typography component="span" variant="body2" color="text.secondary">
        By logging in you agree to Vantage&apos;s{' '}
        <strong
          onClick={() => {
            setIsCookiePolicyOpen(true);
          }}
          className={classes.link}
        >
          Cookie Policy
        </strong>{' '}
        and{' '}
        <a
          href="https://uploads-ssl.webflow.com/5eafbd1ee37f5e48a37b095e/6225eab9f6bfa558cf0f2a73_Viewmetrics Privacy Policy.pdf"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <strong>Privacy Policy</strong>
        </a>
      </Typography>
      <Dialog
        open={isCookiePolicyOpen}
        onClose={() => {
          setIsCookiePolicyOpen(false);
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            setIsCookiePolicyOpen(false);
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography variant="h3" textAlign="center" fontWeight={700}>
            VANTAGE
            <br />
            Cookie Policy
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" component="div" gutterBottom>
            What are cookies and how does Vantage and Viewmetrics use cookies on
            their sites?
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            We may use cookies to track your preferences and activities on a
            Viewmetrics Affiliate&apos;s website.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            Cookies are small data files transferred to your computer&apos;s
            hard-drive by a website. They keep a record of your preferences
            making subsequent visits to the site more efficient. Cookies may
            store a variety of information, including, the number of times that
            you access a site, registration information and the number of times
            that you view a particular page or other item on the site.
            <br />
            The use of cookies is a common practice adopted by most major sites
            to better serve their clients. Most browsers are designed to accept
            cookies, but they can be easily modified to block cookies; see your
            browser&apos;s help files for details on how to block cookies, how
            to know when you have received cookies and not to disable cookies
            completely.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
const useStyles = makeStyles((theme: Theme) => ({
  link: {
    cursor: 'pointer',
    color: theme.palette.text.secondary,
    textDecoration: 'none',
    '&:hover': {opacity: 0.65},
  },
}));
