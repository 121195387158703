import {
  useState,
  useCallback,
  type MouseEvent,
  type KeyboardEvent,
  type ChangeEvent,
  type RefObject,
} from 'react';

import {useNavigate} from 'react-router-dom';

import {listingApi} from '../../../api';
import {
  type MenuItem,
  menuItems,
} from '../../common/components/navigation/menuItems/menuItems';
import {useUserStore} from '../../common/stores/userStore';
import modelOptions from '../config/commandPromptModelOptions';

interface MenuOption {
  type: 'menu';
  key: string | number;
  path: string;
  label: string;
}

interface ModelOption {
  type: 'model';
  key: string | number;
  model: string;
  pathname: string;
  label: string;
}
interface ListingOption {
  type: 'listing';
  key: string | number;
  value: number;
  label: string;
}

type Option = MenuOption | ModelOption | ListingOption;

interface UseCommandPromptProps {
  onClose: () => void;
  inputRef: RefObject<HTMLInputElement | null>;
}

export const useCommandPrompt = ({
  onClose,
  inputRef,
}: UseCommandPromptProps) => {
  const hasPermissions = useUserStore((state) => state.hasPermissions);
  const navigate = useNavigate();
  const [input, setInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Option[]>([]);
  const [selectedModelOption, setSelectedModelOption] =
    useState<ModelOption | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [focusedSuggestion, setFocusedSuggestion] = useState<number>(0);
  const [loadingListing, setLoadingListing] = useState(false);

  const permissionFilterMenuItem = useCallback(
    (items: MenuItem[]) =>
      items.reduce((acc: MenuItem[], item) => {
        if (
          (item.children == null || item.children.length === 0) &&
          ((item.permission != null && hasPermissions(item.permission)) ||
            item.permission == null)
        ) {
          acc.push({...item});
        } else if (item.children != null && item.children.length > 0) {
          const filteredChildren = permissionFilterMenuItem(item.children);
          if (filteredChildren.length > 0) {
            acc.push({...item, children: filteredChildren});
          }
        }
        return acc;
      }, []),
    [hasPermissions],
  );
  const filterMenuItems = useCallback(
    (items: MenuItem[], newFilter: string) =>
      items.reduce((acc: MenuItem[], item) => {
        const matchesFilter = item.label
          .toLowerCase()
          .includes(newFilter.toLowerCase());
        if (matchesFilter && item.pathname != null) {
          // Push the item without children to the top level
          acc.push({...item, children: undefined});
        }
        if (item.children != null) {
          // Flatten the children into the top level
          const filteredChildren = filterMenuItems(item.children, newFilter);
          acc.push(...filteredChildren);
        }
        return acc;
      }, []),
    [],
  );
  const handleInputChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      const {value} = event.target;
      if (selectedModelOption != null) {
        setSearchTerm(value);
        setLoadingListing(true);
        try {
          const response = await listingApi.apiListingGet({
            perPage: 5,
            query: value,
            model: selectedModelOption.model,
          });
          setSuggestions(
            response.items?.map((item) => ({
              label: item.label ?? '-',
              value: item.value ?? 0,
              key: item.value ?? item.label ?? 0,
              type: 'listing',
            })) ?? [],
          );
        } finally {
          setLoadingListing(false);
        }
      } else {
        setInput(value);
        if (value.startsWith('#')) {
          setSuggestions(
            modelOptions
              .filter((option) =>
                option.label
                  .toLowerCase()
                  .startsWith(value.slice(1).toLowerCase()),
              )
              .map((option) => ({
                ...option,
                key: option.model,
                type: 'model',
              }))
              .slice(0, 5) as Option[],
          );
          setFocusedSuggestion(0);
        } else {
          const filteredByPermissions = permissionFilterMenuItem([
            {label: 'Home', pathname: '/', level: 1},
            ...menuItems,
          ]);
          setSuggestions(
            filterMenuItems(filteredByPermissions, value)
              .map((i) => ({
                label: i.label,
                path: i.pathname ?? i.label,
                key: i.pathname ?? i.label,
                type: 'menu',
              }))
              .slice(0, 5) as Option[],
          );
        }
      }
    },
    [filterMenuItems, permissionFilterMenuItem, selectedModelOption],
  );

  const handleOptionSelect = useCallback(
    (
      event:
        | KeyboardEvent<HTMLInputElement | HTMLLIElement>
        | MouseEvent<HTMLLIElement>,
      option: Option,
    ) => {
      switch (option.type) {
        case 'model': {
          setSelectedModelOption(option);
          inputRef.current?.focus();
          break;
        }
        case 'listing': {
          if (selectedModelOption != null) {
            if (event.metaKey || event.ctrlKey) {
              window.open(
                `${selectedModelOption.pathname}/${option.value}`,
                '_blank',
                'noopener,noreferrer',
              );
            } else {
              navigate(`${selectedModelOption.pathname}/${option.value}`);
            }
            onClose();
          }
          break;
        }
        case 'menu':
        default: {
          if (event.metaKey || event.ctrlKey) {
            window.open(option.path, '_blank', 'noopener,noreferrer');
          } else {
            navigate(option.path);
          }
          onClose();
          break;
        }
      }
      setInput('');
      setSearchTerm('');
      setSuggestions([]);
      setFocusedSuggestion(0);
    },
    [inputRef, navigate, onClose, selectedModelOption],
  );

  const handleRemoveOption = useCallback(() => {
    setSelectedModelOption(null);
    setInput('');
    setSearchTerm('');
  }, []);

  const handleKeyNavigation = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (
        e.key === 'Backspace' &&
        selectedModelOption != null &&
        (searchTerm == null || searchTerm === '')
      ) {
        e.preventDefault();
        handleRemoveOption();
        return;
      }
      if (suggestions.length === 0) {
        return;
      }
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setFocusedSuggestion((prev) =>
            prev < suggestions.length - 1 ? prev + 1 : 0,
          );
          break;
        case 'ArrowUp':
          e.preventDefault();
          setFocusedSuggestion((prev) =>
            prev > 0 ? prev - 1 : suggestions.length - 1,
          );
          break;
        case 'Enter':
          e.preventDefault();
          handleOptionSelect(e, suggestions[focusedSuggestion]);
          break;
        default:
          break;
      }
    },
    [
      suggestions,
      focusedSuggestion,
      handleOptionSelect,
      selectedModelOption,
      searchTerm,
      handleRemoveOption,
    ],
  );

  return {
    input,
    suggestions,
    selectedModelOption,
    searchTerm,
    focusedSuggestion,
    handleInputChange,
    handleOptionSelect,
    handleRemoveOption,
    handleKeyNavigation,
    loadingListing,
  };
};
