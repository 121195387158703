import {memo} from 'react';

import ArrowBackIosNewRounded from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRounded from '@mui/icons-material/ArrowForwardIosRounded';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {UseDateRangeReturnType} from './useDateRange';

export const CalendarHeader = memo(function CalendarHeader({
  currentMonth,
  handleMonthChange,
}: Pick<UseDateRangeReturnType, 'currentMonth' | 'handleMonthChange'>) {
  const nextMonth = new Date(
    currentMonth.getFullYear(),
    currentMonth.getMonth() + 1,
  );

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <IconButton onClick={() => handleMonthChange(-1)}>
        <ArrowBackIosNewRounded />
      </IconButton>
      <Stack flex={1} direction="row" spacing={2}>
        {[currentMonth, nextMonth].map((month, i) => (
          <Typography key={i} variant="h6" flex={1} textAlign="center">
            {month.toLocaleDateString('en-US', {
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        ))}
      </Stack>
      <IconButton onClick={() => handleMonthChange(1)}>
        <ArrowForwardIosRounded />
      </IconButton>
    </Stack>
  );
});
