import {
  type PaletteColorOptions,
  type ThemeOptions,
} from '@mui/material/styles';
import {type ProfileTheme} from '@onroadvantage/onroadvantage-api';

import {config} from '../config';
import {vantageThemePaletteDark} from './vantageThemePaletteDark';
import {vantageThemePaletteLight} from './vantageThemePaletteLight';

export const getTheme = (
  mode: 'dark' | 'light',
  whiteLabelledThemeOptions: ProfileTheme | null | undefined,
): ThemeOptions => {
  const primary: PaletteColorOptions = {main: config.theme.primaryDefault};
  const secondary: PaletteColorOptions = {main: config.theme.secondaryDefault};

  if (whiteLabelledThemeOptions?.primaryColour != null) {
    primary.main = whiteLabelledThemeOptions.primaryColour;
  }
  if (whiteLabelledThemeOptions?.primaryContrastColour != null) {
    primary.contrastText = whiteLabelledThemeOptions.primaryContrastColour;
  }
  if (whiteLabelledThemeOptions?.secondaryColour != null) {
    secondary.main = whiteLabelledThemeOptions.secondaryColour;
  }
  if (whiteLabelledThemeOptions?.secondaryContrastColour != null) {
    secondary.contrastText = whiteLabelledThemeOptions.secondaryContrastColour;
  }

  return {
    spacing: 10,
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            whiteSpace: 'nowrap',
            borderRadius: 100,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {borderRadius: 100},
        },
      },
    },
    typography: {
      fontFamily:
        'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
      fontSize: 13,
    },
    shape: {
      borderRadius: 16,
    },
    palette: {
      contrastThreshold: 3.1,
      warning: {main: '#FF9000', contrastText: '#fff'},
      success: {main: '#00944F'},
      error: {main: '#E9291C'},
      info: {main: '#064C55'},
      common: {white: '#E7E7E7', black: '#121212'},
      mode,
      primary,
      secondary,
      ...(mode === 'dark' ? vantageThemePaletteDark : vantageThemePaletteLight),
    },
  };
};
