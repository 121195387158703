import {type ComponentType} from 'react';

import {TableRowDetail} from '@devexpress/dx-react-grid-material-ui';

import {VantageTableRowDetailCellComponent} from '../styledComponents/VantageTableRowDetailCellComponent';

interface TableRowDetailProps {
  rowDetail: ComponentType<TableRowDetail.ContentProps>;
}

export function VantageTableRowDetail({rowDetail}: TableRowDetailProps) {
  return (
    <TableRowDetail
      toggleColumnWidth={0}
      contentComponent={rowDetail}
      cellComponent={VantageTableRowDetailCellComponent}
      /** To remove the unwanted dropdown arrow in the actions column return empty <td /> element  */

      toggleCellComponent={() => <td />}
    />
  );
}
