import moment from 'moment';
import {toast} from 'react-toastify';

import {isNumber} from '../helpers/unknownValueTypeChecks';

export const ResponseService = {
  checkStatus: async (response: Response, parse = true) => {
    if (response.ok) {
      // appNotificationStore.enqueueNotification('success', 'Api Call Succeeded');
      if (!parse) {
        return {
          success: true,
        };
      }
      return {
        data: await response.json(),
      };
    }
    let errorBody;
    let errorString;
    try {
      errorBody = await response.json();
      if (errorBody.message != null) {
        errorString = errorBody.message;
      } else {
        errorString = JSON.stringify(errorBody);
      }
    } catch (e) {
      errorString = JSON.stringify(e);
      errorBody = {error: errorString};
    }

    if (response.status === 400) {
      toast(`Bad Request 400 Occurred: ${errorString} `, {type: 'error'});
      return {error: '400 Occurred'};
    }
    if (response.status === 401) {
      toast(`Unauthenticated 401 Occurred: ${errorString}`, {type: 'error'});
      return {error: '401 Occurred'};
    }
    if (response.status === 403) {
      toast(`Unauthorized 403 Occurred: ${errorString}`, {type: 'error'});
      return {error: '403 Occurred'};
    }
    if (response.status === 404) {
      toast(`NotFound 404 Occurred: ${errorString} `, {type: 'error'});
      return {error: '404 Occurred'};
    }
    if (response.status === 405) {
      toast(`MethodNotAllowed 405 Occurred: ${errorString} `, {type: 'error'});
      return {error: '405 Occurred'};
    }
    if (response.status === 422) {
      toast(`UnprocessableEntityError 422 Occurred: ${errorString}`, {
        type: 'error',
      });
      return {error: '422 Occurred'};
    }
    if (response.status === 429) {
      const retryAfter = response.headers.get('Retry-After');
      const headerReset = response.headers.get('X-RateLimit-Reset');
      const resetDate = moment.unix(isNumber(headerReset) ? headerReset : 0);
      toast(
        `TooManyRequests 429 Occurred: Need to wait ${retryAfter} seconds`,
        {type: 'error'},
      );
      return {error: '429 Occurred', retry: {retryAfter, resetDate}};
    }
    if (response.status === 500) {
      toast(`InternalServerError 500 Occurred: ${errorString} `, {
        type: 'error',
      });
      return {error: '500 Occurred'};
    }
    toast(`OtherError ${response.status} Occurred: ${errorString}`, {
      type: 'error',
    });
    return {error: 'Other Error Occurred', data: errorBody};
  },
};
