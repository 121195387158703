import {webNodeApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';
import {type UseLoadParams} from '../../common/types/UseLoadParams';

export const useLoadSites = ({
  getFilter,
  loadRequestPagination,
  loadRequestSorting,
  updatePagination,
}: UseLoadParams) => {
  const {data, loadDataStatus, reloadData, setData} = useLoadList(
    async (signal) =>
      await webNodeApi.apiWebNodeGet(
        {
          ...loadRequestPagination,
          ...loadRequestSorting,
          telematicsConfigId: getFilter('telematicsConfigId') as
            | number
            | undefined,
          externalId: getFilter('externalId'),
          type: getFilter('type'),
          name: getFilter('name'),
          externalReference: getFilter('externalReference'),
          nodeType: getFilter('nodeType'),
        },
        {signal},
      ),
    [getFilter, loadRequestPagination, loadRequestSorting],
    {updatePagination},
  );

  return {
    data,
    loadDataStatus,
    reloadData,
    removeItem: (id: number | string) => {
      setData((prev) => prev.filter((item) => item.id !== id));
    },
  };
};
